import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import RatePlanCard from "@components/elements/Card/RatePlanCard"
import MetricsGrid from "@components/elements/Card/RatePlanCard/MetricsGrid"
import ImageHeader from "@components/elements/Card/RatePlanCard/Header/ImageHeader"
import UtilityAccountStore from "@store/auth/utility-account.store"
import { renderRate } from "@components/prototypes/dashboard/utils"
import { UserContract } from "@model/types/user"
import { LabelProps } from "@components/elements/Card/RatePlanCard/Label"

export const oldPlanData = (contract?: UserContract): LabelProps[] => [
	{
		label: "Rate",
		color: "success.main",
		children: (
			<>
				{renderRate(contract?.previous?.rate)}
				<sub>/kWh</sub>
			</>
		),
	},
]

export const OldRatePlan = observer(() => {
	const { service } = useInstance(UtilityAccountStore)
	const contract = service?.contract
	if (!contract) throw new Error("Contract is now available")

	const oldPlanSupplier = contract?.previous?.supplier ?? "-"

	const headerNode = <ImageHeader />

	return (
		<RatePlanCard supplier={oldPlanSupplier} header={headerNode}>
			<MetricsGrid cellData={oldPlanData(contract)} />
		</RatePlanCard>
	)
})

export default OldRatePlan
