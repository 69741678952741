import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Box from "@components/fondation/Box/Box"

export interface ContainerProps {
	variant?: "default" | "card"
	color?: "default" | "secondary" | "primary"
}

export const ContainerBox = styled(Box)<ContainerProps>(({ theme, variant, color }) => {
	const isCard = variant === "card"
	const background =
		color === "primary" ? theme.palette.common.white : color === "secondary" ? theme.palette.grey[50] : "transparent"
	return {
		background,
		padding: r(10),
		paddingInline: isCard ? r(10) : 0,
		borderRadius: isCard ? theme.shape.borderRadius : 0,
	}
})

export default ContainerBox
