import { AppError, catchException, createError } from "@model/utils/error"
import AuthService from "@services/firebase/auth.service"
import { User } from "firebase/auth"
import { autorun, makeAutoObservable, when } from "mobx"
import { inject } from "react-ioc"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

class AuthStore {
	authService = inject(this, AuthService)

	authUser: User | null = null

	isAuthChecked = false

	isLoading = false

	error: AppError | null = null

	// constructor

	constructor() {
		makeAutoObservable(this)

		autorun(() => {
			this.authService.auth.onAuthStateChanged(
				(user) => {
					this.setAuthUser(user)
					if (IS_DEV_MODE) console.log("AUTH USER", user)
				},
				(error) => {
					catchException(error)
				},
			) // TODO
		})
	}

	// computed

	get userId(): string | undefined {
		return this.authUser?.uid
	}

	get isAuthorized(): boolean {
		return !!this.userId
	}

	// actions

	protected setAuthUser = (user: User | null) => {
		this.authUser = user
		this.isAuthChecked = true
	}

	loginWithLink = async (email: string, link: string) => {
		try {
			this.isLoading = true
			this.error = null
			const { user } = await this.authService.loginWithEmailLink(email, link)
			this.setAuthUser(user)
		} catch (error) {
			catchException(error)
			this.error = createError("UNKNOWN", "Oops!", "Unexpected error while login")
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	login = async (login: string, pass: string) => {
		this.isLoading = true
		await this.authService.login(login, pass)
		this.isLoading = false
	}

	logout = async () => {
		try {
			this.isLoading = true
			sessionStorage.removeItem("sessionId")
			await this.authService.logout()
			await when(() => !this.isAuthorized)
		} catch (error) {
			catchException(error)
			this.error = createError()
		} finally {
			this.isLoading = false
		}
		return this.error
	}
}

export default AuthStore
