import React from "react"
import { useInstances } from "react-ioc"
import { observer } from "mobx-react-lite"

import MainHeader from "@components/prototypes/Header/MainHeader"
import UserStore from "@store/auth/user.store"
import AuthStore from "@store/auth/auth.store"
import { useLocation, useMatch, useNavigate } from "react-router-dom"
import Stack from "@components/fondation/Stack/Stack"
import ButtonBox from "@components/prototypes/utilities/UtilityMeta/ButtonBox"
import UtilityAccountStatus from "@components/prototypes/Header/UtilityAccountStatus"
import UserAvatar from "@components/prototypes/Header/UserAvatar"
import IconButton from "@components/elements/Button/IconButton"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import Typography from "@components/fondation/Typography/Typography"
import Divider from "@mui/material/Divider"
import Toolbar from "@components/elements/HeaderBar/Toolbar"
import { renderFullAddress } from "@model/utils/address"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import { r } from "@theme/utils"
import { FinalToolbar } from "../../elements/HeaderBar/FinalToolbar"

interface IDashboardHeaderProps {
	showBackButton?: boolean
}

export const DashboardHeaderDesktop: React.FC<IDashboardHeaderProps> = observer(({ showBackButton }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const [auth, userStore] = useInstances(AuthStore, UserStore)
	const { user } = userStore

	const isAccount = useMatch("/account/*")

	const handleAddressClick = () => navigate("/account/address")
	const handleUtilityClick = () => navigate("/account/utility")
	const handleAccountClick = () => navigate("/account", { state: { backPath: location.pathname } })

	const fullAddress = (user != null && renderFullAddress(user)) || ""
	const shiftForBackButton = useMediaQuery("(max-width:1100px)") && showBackButton

	const beforeNode = userStore.user?.address ? (
		<ButtonBox sx={{ ml: shiftForBackButton ? r(90) : 0 }} onClick={handleAddressClick}>
			<Stack direction="row" gap={2}>
				<MapOutlinedIcon />
				<Typography variant="h6">{fullAddress}</Typography>
			</Stack>
		</ButtonBox>
	) : null

	const afterNode = auth.isAuthorized && (
		<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={5}>
			<ButtonBox onClick={handleUtilityClick}>
				<UtilityAccountStatus />
			</ButtonBox>
			<UserAvatar onClick={handleAccountClick} active={isAccount != null} />
		</Stack>
	)

	return (
		<Toolbar variant="regular" color="primary">
			<MainHeader before={beforeNode} after={afterNode} />
		</Toolbar>
	)
})

DashboardHeaderDesktop.defaultProps = {
	showBackButton: false,
}

export const DashboardHeaderMobile: React.FC = observer(() => {
	const location = useLocation()
	const navigate = useNavigate()

	const [auth, userStore] = useInstances(AuthStore, UserStore)

	const isAccount = useMatch("/account/*")
	const { user } = userStore

	const handleAddressClick = () => navigate("/account/address")
	const handleUtilityClick = () => navigate("/account/utility")
	const handleAccountClick = () => navigate("/account", { state: { backPath: location.pathname } })
	const handleBackClick = () => navigate(location.state?.backPath ?? "/dashboard")

	const fullAddress = (user != null && renderFullAddress(user)) || ""

	const beforeNode = userStore.user?.address ? (
		<ButtonBox onClick={handleAddressClick}>
			<Stack direction="row" gap={2}>
				<MapOutlinedIcon />
				<Typography variant="h6">{fullAddress}</Typography>
			</Stack>
		</ButtonBox>
	) : null

	const afterNode = auth.isAuthorized && (
		<IconButton onClick={isAccount ? handleBackClick : handleAccountClick}>
			<MenuOutlinedIcon />
		</IconButton>
	)

	return (
		<>
			<Toolbar variant="dense" color="primary">
				<MainHeader before={beforeNode} after={afterNode} />
			</Toolbar>
			<Divider />
			<FinalToolbar onClick={handleUtilityClick}>
				<UtilityAccountStatus />
			</FinalToolbar>
		</>
	)
})

export default { DashboardHeaderDesktop, DashboardHeaderMobile }
