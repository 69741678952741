import React, { useEffect } from "react"
import Stack from "@components/fondation/Stack/Stack"
import CarouselPoints from "@components/elements/Carousel/CarouselPoints"
import Carousel from "@components/elements/Carousel/Carousel"
import Elevation from "@components/elements/Card/CardElevation"

interface Props {
	children: React.ReactNode
}

export const MobileRateContractContainer: React.FC<Props> = ({ children }) => {
	const [active, setActive] = React.useState(0)
	const childrenArray = React.Children.toArray(children)

	useEffect(() => {
		setActive(0)
	}, [childrenArray.length])

	return (
		<Stack spacing={3}>
			<Carousel index={active} onChangeIndex={setActive}>
				{childrenArray.map((item, idx) => (
					// eslint-disable-next-line react/no-array-index-key
					<Elevation key={idx}>{item}</Elevation>
				))}
			</Carousel>
			<CarouselPoints index={active} slides={childrenArray.length} onChangeIndex={setActive} />
		</Stack>
	)
}

export default MobileRateContractContainer
