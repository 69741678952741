import { emailValidator, phoneWithCodeValidator, zipCodeValidator } from "@components/modules/forms/common/validations"
import { Preselect } from "@model/types/session"
import * as yup from "yup"

export interface PreselectQueryParams {
	zip: string
	zipcode: string
	phone: string
	email: string
}

const preselectValidator = yup
	.object({
		postalCode: zipCodeValidator.clone(),
		phone: phoneWithCodeValidator.clone(),
		email: emailValidator.clone().transform(decodeURIComponent),
	})
	.required()

export const parsePreselect = (params: Partial<PreselectQueryParams>): Preselect | undefined => {
	const preselect = preselectValidator.cast({
		postalCode: params.zip ?? params.zipcode,
		phone: params.phone,
		email: params.email,
	})

	if (preselectValidator.isValidSync(preselect)) return preselect
	return undefined
}
