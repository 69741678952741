import React from "react"
import Avatar from "@mui/material/Avatar"
import LinkCard, { LinkCardProps } from "@components/elements/LinkCard/LinkCard"
import { getUtilityLogoSrc } from "@model/utils/utilities"
import { UserUtility } from "@model/types/user"
import { r } from "@theme/utils"

export interface UtilityCardProps extends LinkCardProps {
	data: UserUtility
}

export const UtilityCard: React.FC<UtilityCardProps> = React.memo(({ data, ...rest }) => {
	const logo = getUtilityLogoSrc(data.id)
	return (
		<LinkCard start={<Avatar src={logo} sx={{ height: r(44), width: r(44) }} variant="rounded" />} {...rest}>
			{data.name}
		</LinkCard>
	)
})

export default UtilityCard
