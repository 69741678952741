import { v4 as uuid } from "uuid"
import { Unsubscribe } from "firebase/auth"

import { AppError, catchException, createError } from "@model/utils/error"
import { TaskState, UploadTask, UploadTaskSnapshot } from "firebase/storage"
import { makeAutoObservable, reaction, toJS } from "mobx"
import { FileRejection } from "react-dropzone"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

export type UploadStatus = TaskState | "none"

class UploadableBill {
	// injections

	// constructors

	constructor(file: File | FileRejection) {
		makeAutoObservable(this)

		this.id = uuid()

		if (file instanceof File) {
			this.file = file
		} else {
			const fileError = file.errors[0]
			this.file = file.file
			this.error = fileError
				? createError(fileError.code, fileError.message)
				: createError("NOT_ACCEPTABLE", "File format is not acceptable")
		}

		if (IS_DEV_MODE) {
			reaction(
				() => this.file,
				(user) => console.log("USER", toJS(user)),
			)
		}
	}

	// attributes

	id: string

	readonly file: File

	error: AppError | null = null

	status: UploadStatus = "none"

	progress = 0

	protected unsubscribe: Unsubscribe | null = null

	protected task: UploadTask | null = null

	// computed

	get inProgress(): boolean {
		return this.status === "running"
	}

	get isDeletable(): boolean {
		// TODO confirm statuses
		return this.status === "success" || this.status === "canceled" || this.status === "error"
	}

	// actions

	runUploadTask = (task: UploadTask) => {
		try {
			this.unsubscribe = task.on("state_changed", this.onUploadUpdate, this.onUploadError, this.onUploadDone)
			this.task = task
			this.status = "running"
		} catch (error) {
			catchException(error)
		}
	}

	cancel = () => {
		try {
			return this.task?.cancel()
		} catch (error) {
			catchException(error)
			this.error = createError()
		}
		return false
	}

	protected onUploadUpdate = (snapshot: UploadTaskSnapshot) => {
		this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
		this.status = snapshot.state
	}

	protected onUploadError = () => {
		this.status = "error"
		this.error = createError()
		this.unsubscribe?.()
		this.unsubscribe = null
	}

	protected onUploadDone = () => {
		this.status = "success"
		this.progress = 100
		this.unsubscribe?.()
		this.unsubscribe = null
	}
}

export default UploadableBill
