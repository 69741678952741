import React from "react"

import Avatar from "@mui/material/Avatar"
import ListItemAvatar from "@components/elements/List/ListItemAvatar"
import Skeleton from "@mui/material/Skeleton"
import ListItem from "@components/elements/List/ListItem"
import { ListItemProps } from "@mui/material/ListItem"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Stack from "@components/fondation/Stack/Stack"
import Typography from "@components/fondation/Typography/Typography"
import { utilityInitials } from "./utils"

export interface UtilityMetaCardProps extends ListItemProps {
	name?: string
	logoSrc?: string
	socketActions?: React.ReactNode
}

export const Container = styled(ListItem)({
	".MuiListItemAvatar-root": {
		textAlign: "center",
		minWidth: r(40),
		marginRight: r(12),
		marginBlock: 0,
	},

	".MuiListItemText-root": {
		marginBlock: 0,
	},

	".MuiAvatar-img": {
		objectFit: "contain",
		backgroundColor: "white",
	},
})

export const UtilityMeta: React.FC<UtilityMetaCardProps> = React.memo(
	({ name, logoSrc: logo, socketActions, ...rest }) => (
		<Container alignItems="flex-start" disableGutters disablePadding {...rest}>
			<ListItemAvatar>
				{logo || name ? (
					<Avatar src={logo} variant="rounded">
						{utilityInitials(name)}
					</Avatar>
				) : (
					<Skeleton variant="rounded" sx={{ margin: 0 }} width={40} height={40} />
				)}
			</ListItemAvatar>
			<Stack flex="1 1 100%" justifyContent="center" alignSelf="stretch">
				<Typography variant="h6" width="100%">
					{name ?? <Skeleton width="50%" />}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="center" alignSelf="stretch">
				{socketActions}
			</Stack>
		</Container>
	),
)

export default UtilityMeta
