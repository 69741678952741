import React from "react"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import CardBox from "../CardBox"

export interface CollapsibleCardProps {
	image: string
	title: string
	children: React.ReactNode
}

const Title = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[900],
	marginTop: r(14),
	marginBottom: r(4),
}))

const Content = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[800],
}))

const BenefitsCard: React.FC<CollapsibleCardProps> = React.memo((props) => {
	const { children, title, image, ...rest } = props

	return (
		<CardBox height="100%" {...rest}>
			<Image src={image} height={54} width={69} style={{ objectFit: "contain" }} />

			<Title variant="h5">{title}</Title>

			<Content variant="body2">{children}</Content>
		</CardBox>
	)
})

export default BenefitsCard
