import { inject } from "react-ioc"
import { makeAutoObservable, when } from "mobx"

import { UtilityLink, UtilityLinkErrorMessage, UserService as IUserService, ServiceUtility } from "@model/types/user"
import { AppError } from "@model/utils/error"
import UserAPIService from "@services/firebase/user.service"
import { parseUtilityLinkError } from "@model/utils/utilities"
import UserStorageService from "@services/firebase/user.service/user.storage.service"
import { EnterBillFormData } from "@components/modules/forms/connect-utility/EnterBillForm/utils"
import { UtilityStatus } from "@components/prototypes/utilities/UtilityMeta/type"
import CountDown from "../utils/CountDown"
import UserStore from "./user.store"
import { extractUtilityStatus } from "./utils"

class LinkUtilityStore {
	// injections

	userService = inject(this, UserAPIService)

	userStore = inject(this, UserStore)

	userStorageService = inject(this, UserStorageService)

	// constructors

	constructor() {
		makeAutoObservable(this)
	}

	// attributes

	linkingCountDown = new CountDown(45)

	linkingAttempts = 0

	// computed

	get service(): IUserService | undefined {
		return this.userStore.user?.services?.electric
	}

	get currentUtility(): ServiceUtility | undefined {
		return this.service?.utility
	}

	get currentUtilityId(): string | undefined {
		return this.currentUtility?.id
	}

	get isVerifying(): boolean {
		return this.linkingCountDown.isRunning || this.currentUtility?.link?.status === "verifying"
	}

	get isLoading(): boolean {
		return this.userStore.isLoading
	}

	get isRetryError(): boolean {
		return this.error?.code === "LOGIN_FAILURE" || this.error?.code === "NO_ACCOUNTS_FOUND"
	}

	get navigateToSuccess(): boolean {
		return this.currentUtility?.link?.status === "ok"
	}

	get navigateToLink(): boolean {
		return !!this.error && (!this.isRetryError || this.linkingAttempts >= 3)
	}

	get userError(): AppError | null {
		return this.userStore.error
	}

	get error(): AppError<UtilityLinkErrorMessage> | null {
		const link = this.currentUtility?.link
		if (!this.currentUtility || !link || link?.status !== "error") return null
		return parseUtilityLinkError(this.currentUtility)
	}

	get linkingStatus(): UtilityStatus {
		return extractUtilityStatus(this.service)
	}

	// actions

	waitForCountDown = async () => {
		this.linkingCountDown.start()
		await when(() => this.linkingCountDown.current < 1 || !!this.error || this.currentUtility?.link?.status === "ok")
		this.linkingCountDown.stop()
		return this.linkingCountDown.current < 1
	}

	skipLinking = async () => {
		this.userStore.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "set-state", { serviceType: "electric", skipLinking: true })
			},
		})
	}

	linkUtility = async (form: UtilityLink) =>
		this.userStore.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "link-utility", {
					serviceType: "electric",
					terms: true,
					link: form,
				})
				await when(() => this.currentUtility?.link?.status === "verifying")
				if (this.linkingAttempts >= 1) this.linkingCountDown.reset()
				this.linkingAttempts += 1
			},
		})

	uploadBills = (updateId: number, folderName: string, fileCount: number) =>
		this.userStore.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "upload-bill", {
					id: this.userStorageService.getBillFolderPath(userId, folderName),
					termsAccepted: true,
					serviceType: "electric", // temp
					fileCount,
					updateId,
				})
			},
		})

	enterBill = (form: EnterBillFormData) =>
		this.userStore.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "connect-utility", {
					termsAccepted: true,
					serviceType: "electric",
					...form,
				})
			},
		})
}

export default LinkUtilityStore
