/* eslint-disable react/no-unescaped-entities */
import React from "react"

import Typography from "@components/fondation/Typography/Typography"

const TermsOfService: React.FC = () => (
	<Typography variant="body2" sx={{ wordWrap: "break-word" }}>
		<h2>Terms of Service</h2>
		<p>
			Arbor Energy Holdings Inc. (“Arbor”) is not an energy supplier. Arbor is a technology company with a mission to
			make it easy for anyone to save money on electricity.
		</p>
		<h4>
			<strong>1. Agreement; Introduction to Website &amp; Services</strong>
		</h4>
		<p>
			These terms of service (“Terms”) form a binding, enforceable contract between you and Arbor and govern your use of{" "}
			<a href="https://www.joinarbor.com/">www.joinarbor.com</a> and the Services offered and provided through the
			Website and other of our websites, mobile and tablet applications, phone, mail, and social media sites, if any. By
			browsing, registering for, accessing, or using any of the Services, you acknowledge that you have read,
			understood, and agree to be bound and abide by these Terms, including any additional guidelines referenced in
			these Terms such as our Privacy Policy. If at any time you do not agree to these Terms, you do not have permission
			to use the Website and you must immediately terminate your use of the Services. Arbor reserves the right to change
			the Terms or modify any features of the Services at any time at its sole discretion. Arbor will notify you of any
			significant changes to our terms and conditions, however, you are expected to check this page frequently so you
			are aware of any changes. Any changes to the Terms will be effective immediately. The most current version of the
			Terms (along with its effective date) will be linked from the homepage on the Website. Your continued use of the
			Services following any updates to the Terms will be deemed acceptance of the updated Terms.
		</p>
		<h4>
			<strong>2. Definitions</strong>
		</h4>
		<p>
			“Account” or “Arbor Account” means an account that a User creates on the Website or through other means in order
			to register for use of certain Services.
		</p>
		<p>“Arbor,” “we,” “us” or “our” means Arbor and its subsidiaries or assigns. </p>
		<p>“Business Day” is every Monday through Friday, excluding statutory holidays in the USA. </p>
		<p>
			“Current Rate” means either (A) the Default Utility Rate; (B) if there is no Default Utility Rate in your area,
			then the average competitive rate available to you in Arbor’s database; or (C) if provided, an actual current rate
			as indicated by you or Utility Account Information shared by you.
		</p>
		<p>
			“Default Utility Rate” means the electric service rate that a Utility offers to its customers who have not chosen
			a Supplier, in certain markets.
		</p>
		<p>
			“Force Majeure” means circumstances beyond Arbor’s reasonable control, including without limitation, acts of God,
			acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems or
			Internet service provider failures or delays.
		</p>
		<p>“Linked Sites” means a third-party website available through our Website. </p>
		<p>
			“Personal Information” means any personally identifiable information concerning a User, including but not limited
			to the following: name, email address, phone number, billing/shipping address, and Utility service address.
		</p>
		<p>
			“Services” means all of our products and services and any other features, technologies and/or functionalities
			offered by us on the Website or through any other means.
		</p>
		<p>
			“Supplier” means any entity other than the Utility that offers electric power or Utility bill credits, or
			combinations thereof, for sale to one or more Users in states where available.
		</p>
		<p>
			“Supplier Account Notices” means any communications sent to the User from his or her Supplier that includes, but
			is not limited to, notices regarding agreements between the Supplier and User, disconnection, outages, past due
			bills, tariff or rate changes, commercial offers, or other information the Supplier wishes to communicate to the
			User.
		</p>
		<p>
			“Supplier Rate” or “Rate” means an electricity service offering and associated agreement(s) between you and a
			Supplier.
		</p>
		<p>
			“Supply Brokerage Platforms” means any entity that aggregates Suppliers and Supplier Rates and with which Arbor
			works to make such Rates available through the Services. Arbor may work with Brokerage Platforms to increase the
			number of Rates and Suppliers available through the Services.
		</p>
		<p>“User,” “you” or “your” means you and any other person or entity using the Service. </p>
		<p>
			“Utility” means a regulated electric distribution company from which the User receives delivery of electricity, or
			a regulated electric generation, transmission, and distribution company from which a User receives electricity.
		</p>
		<p>“Utility Account” means a User’s account with his or her Utility. </p>
		<p>
			“Utility Account Credentials” means User’s Utility Account username, password, and/or account number, and/or any
			other necessary credentials to access User’s Utility Account or that may be necessary to provide our Services.
		</p>
		<p>
			“Utility Account Information” means a User’s personal customer information, Utility usage information, Utility
			bill amounts due, service address and any other information from User’s Utility that may be necessary to provide
			the Services.
		</p>
		<p>
			“Utility or Supplier Statement” means the bill or statement for your electricity service, Utility bill credits,
			and any other related utility good or service.
		</p>
		<p>
			“Website” means <a href="https://www.joinarbor.com/">www.joinarbor.com</a>, including all subpages and successor
			pages.
		</p>
		<h4>
			<strong>3. Arbor Services</strong>
		</h4>
		<p>
			<strong>Autopilot; Supplier Brokerage and Agency Agreement</strong>
		</p>
		<p>
			Our Autopilot Service is designed to help you switch to a better energy plan via automatic enrollment. Arbor
			maintains a database of Default Utility Rates and Supplier Rates in competitive electricity markets. Our goal is
			to match you with a cheaper and/or cleaner energy supply option.
		</p>
		<p>
			If you indicate that you want to lower your energy bill, there is a Rate in our database that is lower than your
			Current Rate, and you have activated Autopilot, then we will attempt to enroll you in the lowest cost Rate in our
			database. If you indicate that you want to switch to 100% renewable energy, there is a Rate in our database that
			is 100% renewable, and you have activated Autopilot, then we will attempt to enroll you in the lowest cost 100%
			renewable energy Rate in our database. Alternatively, you may manually request to enroll in a new rate without the
			use of Autopilot. In all cases, we will notify you when we initiate a request for a new Rate and will give you an
			opportunity to opt out. If you do not opt out, you will be enrolled in the Supplier Rate.
		</p>
		<p>
			Upon the expiration of the initial term of a Rate in which Arbor enrolls you, Arbor will seek to enroll you in the
			lowest Supplier Rate available through the Arbor platform that satisfies your preferences. You will be notified
			via the email address on file before Arbor enrolls you in any new Rate, and you will have an opportunity to opt
			out. If you do not opt out, Arbor will enroll you in the new Rate.
		</p>
		<p>
			There is no direct cost to you for this service, and you may cancel at any time by emailing{" "}
			<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<p>
			Our Supplier Brokerage Services may not be available to every User, in every jurisdiction, or at all times, and we
			reserve the right to offer this Service at our sole discretion.
		</p>
		<p>
			You authorize Arbor to solicit electric supply prices or Utility bill credits from Suppliers on your behalf as
			your agent, whether directly or through a Supply Brokerage Platform. You further authorize Arbor to act as your
			agent with the power to express interest in a Supplier's Rate, and negotiate with such Supplier. You grant Arbor
			your explicit, informed, and affirmative consent to initiate service and enroll you in a Rate with a Supplier
			pursuant to the terms above, whether directly or through a Supply Brokerage Platform, and you authorize Arbor to
			grant your explicit, informed, and affirmative consent to a Supplier on your behalf. You understand and agree
			that, where Autopilot is activated and so long as specified conditions are satisfied, any such enrollment and
			initiation of service may occur automatically without your additional consent, unless you opt out.
		</p>
		<p>
			By enrolling in our Supplier Brokerage Service, you expressly authorize Arbor to sign Supplier contracts on your
			behalf. You agree that Arbor may sign such contracts or agreements on your behalf with the signature “[USER FIRST
			NAME LAST NAME] BY Arbor Energy Holdings Inc. (LOA)” or similar language. You authorize Arbor to act as your agent
			in signing such contracts or agreements, and you agree that such contracts or agreements will have the same legal
			effect as if you had signed them yourself. You also authorize Arbor to receive all Supplier Account Notices and
			expressly assign your rights to any such notices to Arbor.
		</p>
		<p>
			You agree that Arbor may share your Utility Account Information and Utility Account Credentials with Suppliers and
			Supply Brokerage Platforms or others as needed, and in conformity with our Privacy Policy, to accomplish contract
			solicitation, execution, enrollment, and services post-execution. You further agree that your Utility or a
			Supplier may share such information it has about you to Arbor to accomplish contract solicitation, execution,
			enrollment, and services post-execution. Arbor may show this Agreement to Utilities, Suppliers, or other
			authorized parties as required or authorized by law.
		</p>
		<p>
			You will receive any notifications about potential changes to your Supplier to the email address Arbor has on file
			for your Account. You will also be able to view the status and history of your Supplier enrollments by viewing
			information in your Utility Account or as otherwise presented to you by Arbor.
		</p>
		<p>
			Satisfaction Guarantee: If you are not satisfied with your new Rate, for any reason, Arbor will reimburse you for
			any early termination fee you incur as a result of terminating the Supplier contract associated with your Rate if
			you cancel your service within (90) days of the actual effective date of your Supplier Rate. Upon such termination
			we will enroll you in your Default Utility Rate, or similar service that matches what your Current Rate was at the
			time you signed up for Arbor.
		</p>
		<p>
			If you join Arbor while on an existing Supplier contract, you may be liable for an early termination fee from your
			current Supplier if we switch you to another Supplier Rate consistent with this Agreement. If you are assessed an
			early termination fee by the Supplier, about which you notify us and for which you furnish proof of payment, Arbor
			will reimburse you either by paying the Supplier directly or by paying you an amount equal to the fee, not to
			exceed $50.
		</p>
		<p>
			Arbor may receive compensation from Suppliers for Services it provides to you. You consent to this material
			benefit from a third-party. Arbor does not have access to every Supplier Rate and does not guarantee that the
			Rates available through Arbor are the lowest available at any given time.
		</p>
		<p>
			You understand that any Supplier contract Arbor enters into on your behalf is for your Supply charges or Utility
			bill credits only and that other Utility rates will still apply.
		</p>
		<h4>
			<strong>4. Your Relationship with Your Utility and Supplier</strong>
		</h4>
		<p>
			Arbor does not supply, transmit, or distribute electricity and is not affiliated or endorsed by any Utility or
			Supplier. By using Arbor’s Services, you acknowledge and agree that Arbor is acting only as an intermediary and is
			not a party to any transaction between you and your Utility or Supplier. Accordingly, Arbor assumes no
			responsibility and will have no liability of any kind whatsoever in respect to your dealings with your Utility or
			Supplier including with regards to electric delivery, payments, notices, and the proper and timely delivery of
			goods or services by your Utility or Supplier. Arbor in no way endorses, recommends, and/or bears any
			responsibility or liability for any products, services, statements, made by your Utility or Supplier. Statements
			and opinions of your Utility or Supplier are not representative of Arbor or its business partners.
		</p>
		<p>
			These Terms do not alter any liability or obligations that may exist between you and your Supplier. You agree that
			your relationship with any Supplier is governed solely by the Rate and associated agreement(s) with such Supplier.
		</p>
		<h4>
			<strong>5. Utility or Supplier Account</strong>
		</h4>
		<p>
			You authorize Arbor to retrieve, store, and use, on your behalf as your agent, your Utility Account Information
			and your Utility Account Credentials in order to provide our Services. By submitting your Utility Account
			Information, you certify and confirm that you are entitled to disclose this Utility Account Information and that
			you are an authorized user of your Utility Account. Arbor makes no effort to review your Utility Account
			Information for accuracy.
		</p>
		<p>
			To allow Arbor to retrieve and use your Utility Account Information, you understand that you must provide Arbor
			with your Utility Account Credentials. You agree to notify Arbor of any changes to your Utility Account
			Information or Utility Account Credentials. By providing Arbor with your Utility Account Credentials, you certify
			that you or another member of your household or small business owns the Utility Account(s) associated with your
			home or business.
		</p>
		<p>
			You acknowledge and agree that when Arbor is accessing and retrieving your Utility Account Information from
			third-party sites, Arbor is acting as your agent, and not as the agent for or on behalf of the third-party.
		</p>
		<p>
			You authorize Arbor to receive Supplier Notices on your behalf, where permitted, and you understand and agree that
			we may not pass along any or all of these Notices to you. You agree that Arbor shall not be liable for any delays,
			failure to deliver, or misdirected delivery of any Supplier Account Notices.
		</p>
		<h4>
			<strong>6. Actions by Arbor</strong>
		</h4>
		<p>
			If we have reason to believe that you have broken these Terms, we may take various actions to protect Arbor, a
			third party, or you from fees, costs, expenses, fines, penalties and any other liability. The actions we may take
			include but are not limited to the following:
		</p>
		<p>We may close, suspend, or limit your access to your Account or the Services;</p>
		<p>We may update inaccurate information you provided us;</p>
		<p>We may refuse to provide the Services to you in the future;</p>
		<p>Arbor may close, suspend, or limit your access to your Account or Services; and</p>
		<p>We may take legal action against you.</p>
		<p>
			Arbor, in its sole discretion, reserves the right to terminate access to its Website, to your Account, or to the
			Services for any reason and at any time upon notice to you.
		</p>
		<p>
			If we close your Account or terminate your use of the Services for any reason, we will provide you with notice of
			our actions. If we limit access to your Account, we will provide you with notice of our actions and the
			opportunity to request restoration of access if appropriate in our sole discretion.
		</p>
		<p>
			You authorize Arbor, directly or through third parties, to make any inquiries we consider necessary to verify your
			identity. This may include asking you for further information, requiring you to take steps to confirm ownership of
			your mail address or financial instruments, and verifying your information against third party databases or
			through other sources. If you cannot verify your identity, Arbor reserves the right to deny you use of the
			Services.
		</p>
		<h4>
			<strong>7. Your Privacy</strong>
		</h4>
		<p>
			Your use of the Services is also governed by our Privacy Policy. By using the Services, you indicate that you
			understand and consent to the collection, use, and disclosure of your information as described in our Privacy
			Policy. You further agree that you are licensing your Utility Account Information to Arbor for the purpose of
			providing the Services. Arbor may use and store the content in accordance with these Terms and our Privacy Policy.
		</p>
		<h4>
			<strong>8. Confidentiality and Notices</strong>
		</h4>
		<p>
			You agree and understand that you are responsible for maintaining the confidentiality and security of any personal
			URL that allows you to access the Services, or, if applicable, any password, which, together with your login ID
			email address, allows you to access the Services. That personal URL and/or login ID email address and password
			constitute your “Registration Information.” We recommend you do not use the same password for other online
			accounts, such as an online banking account. In order for the Services to function effectively, you must keep your
			Utility Account Credentials and Registration Information up to date and accurate. If you do not do this, the
			accuracy and effectiveness of the Services will be affected. If you become aware of any unauthorized use of your
			Utility Account Credentials or Registration Information, you agree to notify Arbor as soon as reasonably possible
			at the email address <a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<p>
			Our information collection and use policies with respect to the privacy of such Account Information, Utility
			Account Credentials, and Registration Information are set forth in the Privacy Policy, which is incorporated
			herein by reference for all purposes.
		</p>
		<p>
			By providing us with your email address and phone number, you agree to receive all required notices
			electronically, to that email address, or through live calls, texts, and/or robocalls to your phone number. It is
			your responsibility to update or change that address, as appropriate. Email notices will be provided in HTML (or,
			if your system does not support HTML, in plain-text) in the text of the email or through a link to the appropriate
			page on our Website, accessible through any standard, commercially available Internet browser.
		</p>
		<h4>
			<strong>9. Compliance with Applicable Laws</strong>
		</h4>
		<p>
			When you access or use the Services, you are required to comply with all applicable laws and any other conditions
			or restrictions in any written or online notice from Arbor (including these Terms). As a condition of your access
			to and use of the Services, you agree that you will not use the Services for any purpose that is unlawful or
			prohibited by these Terms. The Services are offered for your personal and non-commercial use only, and Arbor does
			not grant you any express or implied rights to access or use the Services for any other purpose.
		</p>
		<p>Without limiting the generality of the foregoing, you agree not to:</p>
		<p>
			Impersonate any person or entity, whether actual or fictitious, or falsely state or otherwise misrepresent your
			affiliation with any person or entity;
		</p>
		<p>
			Interfere with any other users’ rights to privacy and publicity, including by harvesting or collecting information
			from or about users;
		</p>
		<p>
			Upload or otherwise transmit any communication, software, or materials that contain a virus or is otherwise
			harmful to Arbor or its users’ computers or systems; or
		</p>
		<p>
			Engage in “spidering,” “screen scraping,” “database scraping,” harvesting of email addresses, wireless address, or
			other contact or personal information, or any other automatic means of obtaining lists of users or other
			information from Arbor, including without limitation any information residing on any server or database connected
			to the Services.
		</p>
		<h4>
			<strong>10. Intellectual Property of Arbor and Its Licensors</strong>
		</h4>
		<p>
			<strong>Copyright</strong>
		</p>
		<p>
			Our Website (including, but not limited to, text, images, photographs, graphics, user interface, screen shots,
			designs, and computer code, and the selection, coordination, and arrangement of such content) is protected under
			the copyright laws of the United States and other countries. Arbor owns all copyrights in our Website or its
			third-party suppliers and licensors to the full extent permitted under the United States Copyright Act and all
			international copyright laws. Unless expressly permitted in writing, you may not copy, reproduce, distribute,
			publish, enter into a database, display, perform, modify, create derivative works from, transmit, or in any way
			exploit any part of our Website, except that you may make use of the content for educational and non-commercial
			purposes only, provided that you maintain all copyright and other notices posted along with the content.
		</p>
		<p>
			<strong>Trade and Service Mark Rights</strong>
		</p>
		<p>
			All rights in the company names, trade names, logos, service marks, and slogans of the Services, whether or not
			appearing in large print or with the trademark symbol, belong exclusively to Arbor or its third-party suppliers
			and licensors and are protected from reproduction, imitation, dilution, or confusing or misleading uses under
			national and international trademark and copyright laws. The use or misuse of these trademarks or any materials is
			expressly prohibited, and nothing stated or implied on our Website confers on you any license or right under any
			trademark of Arbor, its affiliates, or any third party. To obtain written permission to use the trade and service
			mark rights of Arbor, please contact Arbor at <a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<h4>
			<strong>11. Notice of Infringement</strong>
		</h4>
		<p>
			If you believe in good faith that your work has been reproduced or is accessible on our Website in a way that
			constitutes copyright infringement or that your intellectual property rights have otherwise been violated, please
			provide Arbor with the following information in writing:
		</p>
		<p>Identification of the copyrighted work or other intellectual property claimed to have been infringed;</p>
		<p>
			Identification of the allegedly infringing material, reference, or link that is to be removed and information
			reasonably sufficient to permit Arbor to locate the material, reference, or link;
		</p>
		<p>
			Your name, address and daytime telephone number, and an email address if available, so that Arbor may contact you
			if necessary;
		</p>
		<p>
			A statement that the information in the notification is accurate, and under penalty of perjury, that the
			complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
		</p>
		<p>
			A statement that you have a good faith belief that the use of the material in the manner complained of is not
			authorized by the copyright or other intellectual property owner, its agent, or the law; and
		</p>
		<p>
			A physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right
			that is allegedly infringed.
		</p>
		<p>
			Please note that due to security concerns, email attachments will not be accepted. Any notice of infringement
			submitted electronically with an attachment will not be received or processed. Upon receipt of a notice of claimed
			infringement (or any statement in conformity with 17 U.S.C. § 512(c)(3)), Arbor will expeditiously remove or
			disable access to the allegedly infringing content. Arbor may terminate the service privileges of users who
			repeatedly infringe the copyrights or other intellectual property rights of others.
		</p>
		<h4>
			<strong>12. Third-Party Content and Links to Third-Party Websites</strong>
		</h4>
		<p>
			You may be able to link from our Website to certain Linked Sites. This includes links from advertisers, sponsors,
			and small business partners that may use our logo(s) as part of a co-branding relationship. You acknowledge and
			agree that Arbor has no responsibility for the information, content, products, services, advertising, code, or
			other materials which may or may not be provided by or through Linked Sites, even if they are owned or run by
			affiliates or partners of ours, and you rely on the same at your own risk. Links to such websites or resources do
			not imply any endorsement by Arbor of such websites or resources or the content, products, or services available
			from such websites or resources. Such Linked Sites are not under our control, and links to other websites are
			provided solely for the convenience of users. You acknowledge that when you leave our Website by any means,
			including, but not limited to, when you click on a link that leaves our Website, the website you will land on is
			not controlled by us and different terms of use and privacy policies apply. If you elect to use or purchase
			services from third parties, you are subject to their terms and conditions and privacy policy.
		</p>
		<h4>
			<strong>13. Disclaimer of Warranties</strong>
		</h4>
		<p>
			<strong>
				YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
			</strong>
			. Arbor expressly disclaims all warranties of any kind, whether express, implied, or statutory, including, but not
			limited to the implied warranties of merchantability, fitness for a particular purpose, title, and
			non-infringement.Arbor makes no warranty that (i) the Services will meet your requirements, (ii) the Services will
			be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the
			Services will be accurate or reliable, or (iv) the quality of any products, services, information, or other
			material purchased or obtained by you through the Services will meet your expectations.
		</p>
		<h4>
			<strong>14. Limitation of Liability</strong>
		</h4>
		<p>
			You expressly understand and agree that Arbor will not be liable for any indirect, incidental, special,
			consequential, exemplary damages, or damages for loss of profits including but not limited to, damages for loss of
			goodwill, use, data, or other intangible losses (even if Arbor has been advised of the possibility of such
			damages), whether based on contract, tort, negligence, strict liability or otherwise, resulting from: (i) the use
			or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from
			any goods, data, information, or services purchased or obtained or messages received or transactions entered into
			through or from the Services; (iii) unauthorized access to your account information; (iv) statements or conduct of
			any third party on the Services; (v) late or never received Statements or Notices, or (vi) any other matter
			relating to the Services. In no event will Arbor’s total liability to you for all damages, losses, or causes of
			action exceed one hundred dollars ($100). Some jurisdictions do not allow the exclusion of certain warranties or
			the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the
			limitations set forth above may not apply to you. If you are dissatisfied with any portion of the Services or with
			these terms, your sole and exclusive remedy is to discontinue use of the Services.
		</p>
		<p>
			<strong>
				WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, ARBOR DISCLAIMS ALL LIABILITY OF ANY KIND ARISING FROM THE
				UNAUTHORIZED ACCESS TO OR USE OF YOUR PERSONAL INFORMATION (as defined in the Privacy Policy).
			</strong>
		</p>
		<h4>
			<strong>15. Indemnification</strong>
		</h4>
		<p>
			You agree to release, indemnify, and hold harmless Arbor, its affiliates, and its and their respective officers,
			employees, directors, members, and agents from any and all losses, damages, costs, expenses (including reasonable
			attorneys’ fees), rights, claims, actions of any kind and injury (including death) arising out of or relating to
			your use of the Services, your connection to the Services, your use of products or services obtained by or through
			Linked Sites, your violation of these Terms, or your violation of any rights of another. If you are a California
			resident, you waive California Civil Code Section 1542, which says: “A general release does not extend to claims
			which the creditor does not know or suspect to exist in his or her favor at the time of executing the release,
			which if known by him or her must have materially affected his or her settlement with the debtor.” If you are a
			resident of another jurisdiction, you waive any comparable statute or doctrine.
		</p>
		<h4>
			<strong>16. Arbitration of Claims</strong>
		</h4>
		<p>
			Any claim that you might have against Arbor must be resolved through binding arbitration before the American
			Arbitration Association using its Consumer Arbitration Rules and must be brought within one (1) year of the claim
			arising.
		</p>
		<p>
			Any arbitration hearing will be held in Los Angeles, CA. You understand and agree that you will arbitrate with
			Arbor in your individual capacity, not as a representative or member of a class. Your claim may not be joined with
			the claim of any other person, and there will not be authority for any dispute to be arbitrated on a class-action
			basis. The arbitrator, and not any federal, state, or local court or agency, will have exclusive authority to
			resolve any dispute relating to the interpretation, applicability, enforceability or formation of these Terms
			including, but not limited to any claim that all or any part of these Terms is void or voidable.
		</p>
		<p>
			Any party refusing to comply with an order of the arbitrators will be liable for costs and expenses, including
			attorneys’ fees, incurred by the other party in enforcing the award.
		</p>
		<h4>
			<strong>17. No Waiver</strong>
		</h4>
		<p>
			The failure of Arbor to enforce any provisions of these Terms or respond to a breach by you or other parties will
			not in any way waive its right to enforce subsequently any terms or conditions of these Terms or to act with
			respect to similar breaches.
		</p>
		<h4>
			<strong>18. Miscellaneous</strong>
		</h4>
		<p>
			<strong>System Availability</strong>
		</p>
		<p>
			Arbor will use commercially reasonable efforts to make the Services available at all times except for: (a) planned
			downtime, or (b) any unavailability due to Force Majeure. Planned downtime and routine maintenance and updates may
			result in temporary unavailability of the Services. We will have no liability to you or any third party in respect
			of your inability to access the Services at any time.
		</p>
		<p>
			<strong>Your Liability </strong>
		</p>
		<p>
			You are responsible for all fees, costs, expenses, fines, penalties, and other liabilities incurred by Arbor,
			yourself, or a third-party caused by or arising out of your breach of these Terms and/or your use of the Services.
			You agree to reimburse Arbor or a third-party of any and all such liability.
		</p>
		<p>
			<strong>Choice of Law and Forum</strong>
		</p>
		<p>
			These Terms are governed by, and must be construed in accordance with, the laws of California. In the event Arbor
			brings a claim against you, you agree to submit to the exclusive jurisdiction of the federal or local courts
			located in Los Angeles, CA.
		</p>
		<p>
			<strong>Integration and Severability</strong>
		</p>
		<p>
			Unless otherwise specified herein, the Terms constitute the entire agreement between you and Arbor and govern your
			use of the Services. If any portion of the Terms is held invalid or unenforceable, that portion will be construed
			in a manner consistent with applicable law to reflect, as nearly as possible, the original intention of the
			parties, and the remaining portions will remain in full force and effect.
		</p>
		<h4>
			<strong>19. State Specific Rules</strong>
		</h4>
		<p>
			<strong>For Maryland Customers</strong>
		</p>
		<p>
			You consent that Arbor and the Supplier may receive the following information from the Utility on your behalf:
		</p>
		<ul>
			<li>Account name</li>
			<li>Billing address</li>
			<li>Service address</li>
			<li>
				Utility account number and any other number designated by the utility as necessary to process an enrollment
			</li>
			<li>Bill cycle</li>
			<li>Voltage level</li>
			<li>Utility rate class or code</li>
			<li>Load profile</li>
			<li>Meter number</li>
			<li>Meter type</li>
			<li>Multiple meter indicator</li>
			<li>Peak load contribution</li>
			<li>Metered demand</li>
			<li>Billed demand</li>
			<li>Monthly historical demand for the previous 12 months</li>
			<li>Monthly historical consumption for the previous 12 months</li>
			<li>Monthly time-of-use data for the previous 12 months; and</li>
			<li>Interval meter data for the previous 12 months.</li>
		</ul>
		<p>
			If Arbor ever quotes you a Supplier Rate, the price quoted is only for the specified commodity provided by the
			Supplier and does not include any tax, Utility distribution charge, or other Utility fee or charge; and the
			Supplier’s price is not regulated by the Maryland Public Service Commission.
		</p>
		<p>
			<strong>For New York Customers</strong>
		</p>
		<p>
			If you have a dispute with the Supplier, you can contact the New York State Public Service Commission at their
			hotline at 1-888-697-7728, or write to them at the Office of Consumer Services, NYS Department of Public Service,
			3 Empire State Plaza, Albany, New York, 12223.
		</p>
		<p>
			More information can be found at:{" "}
			<a href="http://www3.dps.ny.gov/W/PSCWeb.nsf/All/5EFBF7F3F195F44985257687006F3954?OpenDocument">
				http://www3.dps.ny.gov/W/PSCWeb.nsf
			</a>
		</p>
		<p>Your Rights as an Energy Services Company Consumer ESCO Consumers Bill of Rights</p>
		<p>Customers can purchase energy from an Energy Services Company (ESCO) or from a traditional utility. </p>
		<p>If you choose to purchase energy from an ESCO you are entitled to:</p>
		<ul>
			<li>A clear description of the services offered by the ESCO.</li>
			<li>Receive energy delivery and 24 hour emergency services from your utility company.</li>
			<li>
				Clear procedures for switching energy suppliers, including information about the enrollment process. Disclosure,
				in simple and clear language, of the terms and conditions of the agreement between you and the ESCO including:
			</li>
			<li>price and all variable charges or fees;</li>
			<li>length of the agreement;</li>
			<li>terms for renewal of the agreement;</li>
			<li>
				cancellation process and any early termination fees, which are limited by law; and no conditions, if any, under
				which the ESCO guarantees cost savings.
			</li>
			<li>
				Rescind an agreement with an ESCO within three days of receiving the agreement, if you are a residential
				customer. A description of how pre-payment agreements work, if offered.
			</li>
			<li>
				Notice from the ESCO, no less than thirty days prior to the contract renewal date, of the renewal terms and the
				options you have as a customer.
			</li>
			<li>A fair and timely complaint resolution process.</li>
			<li>
				Provision of any written documents (contracts, marketing materials, and this ESCO Consumers Bill of Rights) in
				the same language used to enroll you as a customer.
			</li>
		</ul>
		<p>
			If you are a residential customer you are also entitled to the rights and protections of the Home Energy Fair
			Practices Act (HEFPA) which requires that all utility customers be treated fairly with regard to application for
			service, customer billing, and complaint procedures. For more information go to{" "}
			<a href="https://www.dps.ny.gov/resright.html">www.dps.ny.gov/resright.html</a>. ESCOs that do not assure these
			consumer rights could lose their eligibility to provide service in New York. Please report any complaints to the
			Department of Public Service at 1- 800-342-3377 (8:30 am - 4:00 pm), by mail at Office of Consumer Services, NYS
			Department of Public Service, 3 Empire State Plaza, Albany, NY 12223, or online at{" "}
			<a href="http://www.dps.ny.gov">http://www.dps.ny.gov</a>.
		</p>
		<p>
			You can find more information about your energy alternatives by visiting:{" "}
			<a href="http://www.AskPSC.com">www.AskPSC.com</a>
		</p>
		<p>
			<strong>For Ohio Customers</strong>
		</p>
		<p>
			You have a right to request from your Supplier, twice within a twelve-month period, up to twenty-four months of
			your payment history without charge.
		</p>
		<p>You can reach Arbor at:</p>
		<ul>
			<li>2418 Spring Ln. #5524 Austin, TX 78763</li>
			<li>
				<a href="https://www.joinarbor.com/">www.joinarbor.com</a>
			</li>
			<li>
				<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>
			</li>
		</ul>
		<p>
			Arbor provides only electric brokerage services. See the Terms for more detail on the services we provide and
			those that are still provided by your Utility and Supplier.
		</p>
		<p>
			If you have any complaints about your Arbor service, please contact us at{" "}
			<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>. If you have complaints about your Supplier
			contract or service, or utility service please contact your Utility or Supplier directly. If your complaint is not
			resolved after you have called your electric supplier and/or your electric utility, or for general utility
			information, residential and business customers may contact the public utilities commission of Ohio (PUCO) for
			assistance at 1-800-686-7826 (toll free) from eight a.m. to five p.m. weekdays, or at{" "}
			<a href="http://www.puco.ohio.gov/">http://www.puco.ohio.gov</a>. Hearing or speech impaired customers may contact
			the PUCO via 7-1-1 (Ohio relay service).
		</p>
		<p>
			The Ohio consumers' counsel (OCC) represents residential utility customers in matters before the PUCO. The OCC can
			be contacted at 1-877-742-5622 (toll free) from eight a.m. to five p.m. weekdays, or at{" "}
			<a href="http://www.pickocc.org/">http://www.pickocc.org</a>
		</p>
		<p>This Agreement will remain in effect until cancelled by you or Arbor.</p>
		<p>
			Arbor is your energy broker only. The Utility or Supplier is still responsible for billing you for the services
			they provide you.
		</p>
		<p>
			Arbor does not collect your social security number. We are prohibited from disclosing account number(s) without
			your consent except for our own collections and credit reporting, participation in programs funded by the
			universal service fund pursuant to section 4928.52 of the Revised Code, or assigning a customer contract to
			another provider. By agreeing to these terms you agree to the following statement:
		</p>
		<p>
			I realize that under the rules and regulations of the public utilities commission of Ohio, I may refuse to allow
			Arbor to release the information set forth above, including my name, account number, and service address to the
			Supplier. By providing my electronic signature, I freely give my Utility permission to release the information
			designated above.
		</p>
		<p>If you do not agree to these terms Arbor cannot provide you Autopilot services.</p>
		<p>
			If you switch back to your local electric utility you may or may not be served under the same rates, terms, and
			conditions that apply to other customers served by the electric utility.
		</p>
		<p>
			The failure to pay electric utility charges may result in you being disconnected in accordance with the electric
			utility tariff
		</p>
		<p>
			The provisions of Section 16 (“Arbitration”) shall not apply to Ohio customers. However, you do agree that this
			Agreement will be governed by the laws of the California, excluding conflict of law rules. You agree to submit to
			the personal and exclusive jurisdiction of the courts located in California.
		</p>
	</Typography>
)

export default TermsOfService
