import axios from "axios"
import { Functions } from "firebase/functions"
import util from "util"
import { cloudFunctions } from "../firebase"

class AppService {
	functions: Functions

	timeout = 5000

	constructor() {
		this.functions = cloudFunctions
	}

	http = axios.create({
		baseURL: util.format(process.env.REACT_APP_BACKEND_API, process.env.REACT_APP_FIREBASE_PROJECT_ID),
	})

	grabIP = (sessionId: string) => {
		this.http.post("/grabIP", { sessionId })
	}
}

export default AppService
