import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/fondation/Typography/Typography"
import SimpleTable, { PairRowData } from "@components/elements/Table/SimpleTable"
import Stack from "@components/fondation/Stack/Stack"
import { useInstances } from "react-ioc"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import NavLink from "@components/elements/NavLink/NavLink"
import EastOutlinedIcon from "@mui/icons-material/EastOutlined"
import LinkUtilityStore from "@store/auth/link-utility.store"
import { linkBenefitsList } from "@components/elements/Card/RateDetailsCard/mocks"
import UtilityStore from "@store/auth/utilities.store"
import CallToLinkAccount from "./CallToLinkAccount"

export const UtilityDetails = observer(() => {
	const [linkStore, utilityStore] = useInstances(LinkUtilityStore, UtilityStore)

	const status = linkStore.linkingStatus
	const accountNumber = linkStore.service?.accountNumber

	const utilityTableData: PairRowData[] = useMemo(
		() => [
			{
				label: "Service",
				value: "Electric",
			},
			{
				label: "Utility",
				value: utilityStore.utility?.name ?? " - ",
			},
			{
				label: "Account Holder",
				value:
					status === "in-progress" && !accountNumber
						? "Processing data"
						: linkStore.service?.contactName ?? linkStore.userStore.user?.name ?? "-",
			},
			{
				label: utilityStore.utility?.powerkiosk?.accountNumLabel ?? "Account Number",
				value: status === "in-progress" && !accountNumber ? "Processing data" : accountNumber ?? "-",
			},
		],
		[utilityStore.utility, linkStore.service, linkStore.userStore.user?.name, accountNumber, status],
	)

	const errorAlertNode = (
		<Alert severity="error">
			<AlertTitle>{linkStore.error?.title ?? "Account connection failed"}</AlertTitle>
			{linkStore.error?.message ?? "Unknown error"}
			<br />
			<NavLink to="/enrollment/provider/connect">
				Link account
				<EastOutlinedIcon />
			</NavLink>
		</Alert>
	)

	if (status === "error")
		return (
			<>
				{errorAlertNode}
				<CallToLinkAccount title="Try linking again to get:" points={linkBenefitsList} />
			</>
		)

	if (status === "unlinked") return <CallToLinkAccount title="Link your account and get:" points={linkBenefitsList} />

	const utilityDetailsNode = (
		<Stack spacing={3.5}>
			<Typography variant="h4">Utility Details</Typography>

			{linkStore.error && !accountNumber ? errorAlertNode : null}

			<SimpleTable rows={utilityTableData} />
		</Stack>
	)

	if (status === "partially-linked")
		return (
			<>
				<CallToLinkAccount title="Sign into your utility account for additional benefits:" points={linkBenefitsList} />
				{utilityDetailsNode}
			</>
		)

	return utilityDetailsNode
})

export default UtilityDetails
