import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import Box from "@components/fondation/Box/Box"
import styled from "@mui/material/styles/styled"

export interface HeaderProps {
	before?: React.ReactNode
	after?: React.ReactNode
	children?: React.ReactNode
}

const Container = styled(Box)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr auto 1fr",
	width: "100%",
	alignItems: "center",
	"&>*:last-child": {
		justifySelf: "flex-end",
	},
	[theme.breakpoints.down("lg")]: {
		gridTemplateColumns: "auto auto auto",
	},
}))

const Header: React.FC<HeaderProps> = ({ before, after, children }) => (
	<Container>
		<Box>{before}</Box>

		<Typography variant="h6" textAlign="center">
			{children}
		</Typography>

		<Box>{after}</Box>
	</Container>
)

export default Header
