import React from "react"
import MUIThemeProvider from "@mui/material/styles/ThemeProvider"

import defaultTheme from "./defaultTheme"

interface ThemeProviderProps {
	children?: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
	<MUIThemeProvider theme={defaultTheme}>{children}</MUIThemeProvider>
)

export default ThemeProvider
