import React from "react"
import { useInstance } from "react-ioc"
import { observer } from "mobx-react-lite"

import Box from "@components/fondation/Box/Box"
import Typography from "@components/fondation/Typography/Typography"
import NavLink from "@components/elements/NavLink/NavLink"
import TermsAndPolicyStore from "@store/app/terms-policy.store"
import useZendeskWidget from "../hooks/useZendeskWidget"

const AgreementFooter = observer(() => {
	const { show } = useInstance(TermsAndPolicyStore)
	useZendeskWidget()

	const onTermsClick = () => show("terms")
	const onPolicyClick = () => show("policy")

	return (
		<Box width="100%" textAlign="center">
			<Typography
				variant="subtitle1"
				sx={(t) => ({
					marginBottom: "3px",
					fontWeight: "bold",
					color: t.palette.grey[900],
				})}
			>
				We Care About Your Privacy.
			</Typography>
			<Typography
				variant="caption"
				sx={(t) => ({
					color: t.palette.grey[700],
				})}
			>
				By clicking “Continue” you agree to our{" "}
				<NavLink to="" onClick={onTermsClick}>
					Terms of Service
				</NavLink>{" "}
				and{" "}
				<NavLink to="" onClick={onPolicyClick}>
					Privacy Policy.
				</NavLink>{" "}
				You also consent to receive automated marketing text messages, calls, and pre-recorded messages from Arbor. You
				can unsubscribe at any time by replying “STOP”. Consent is not a condition of purchase and standard text message
				rates apply.
			</Typography>
		</Box>
	)
})

export default AgreementFooter
