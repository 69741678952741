import React from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"

import RatePlanCard from "@components/elements/Card/RatePlanCard"
import MetricsGrid from "@components/elements/Card/RatePlanCard/MetricsGrid"
import { renderDate, renderRate } from "@components/prototypes/dashboard/utils"
import { LabelProps } from "@components/elements/Card/RatePlanCard/Label"
import Footer from "@components/elements/Card/RatePlanCard/Footer"
import Loader from "@components/elements/Loader/Loader"
import Link from "@mui/material/Link"
import RateContract from "@store/product/RateContract"
import { percentFormatter, shortMoneyFormatter } from "@root/lib/numberUtils"
import NewPlanHeader from "./NewPlanHeader"

const getNewPlanMetrics = (contract: RateContract): LabelProps[] => {
	if (!contract.isRenewable)
		return [
			{
				label: "Rate",
				color: "success.main",
				children: (
					<>
						{renderRate(contract.contractRate)}
						<sub>/kWh</sub>
					</>
				),
			},
			{
				label: "Term",
				children: (
					<>
						{contract.term ?? " - "} <sub>months</sub>
					</>
				),
			},
			{
				label: "Estimated Savings",
				children: contract.estimatedSavings > 0 ? shortMoneyFormatter.format(contract.estimatedSavings) : " - ",
			},
		]

	return [
		{
			label: "Rate",
			color: "success.main",
			children: (
				<>
					{renderRate(contract.contractRate)}
					<sub>/kWh</sub>
				</>
			),
		},
		{
			label: "Term",
			children: (
				<>
					{contract.term ?? " - "} <sub>months</sub>
				</>
			),
		},
		{
			label: "Renewable",
			children: percentFormatter.format(1),
		},
	]
}

const getAdditionalMetrics = (contract: RateContract): LabelProps[] => {
	const links = [
		{
			label: "Disclaimer",
			value: contract?.documents?.disclaimer,
		},
		{
			label: "EFL",
			value: contract?.documents?.efl,
		},
		{
			label: "Terms of Service",
			value: contract?.documents?.tos,
		},
	].filter(({ value }) => value != null)

	return [
		{
			label: "Estimated Start date",
			children: renderDate(contract.estStartDate),
		},
		{
			label: "Estimated End date",
			children: renderDate(contract.estEndDate),
		},
		{
			label: "Links",
			children: links.length
				? links.map((link, idx) => (
						<>
							{idx !== 0 && ", "}
							<Link href={link.value} key={link.label} target="_blank" rel="noreferrer">
								{link.label}
							</Link>
						</>
				  ))
				: " - ",
		},
	]
}

export const NewRatePlan = observer(() => {
	const [contract] = useInstances(RateContract)

	if (!contract) throw new Error("Contract is now available")

	const status = contract.service?.status

	const newPlanSupplier = contract?.supplier?.name ?? "-"

	const footerNode = status === "searching" && (
		<Footer>
			<Loader color="inherit" />
			&nbsp;Autopilot is monitoring for a better rate
		</Footer>
	)

	return (
		<RatePlanCard supplier={newPlanSupplier} header={<NewPlanHeader />} footer={footerNode}>
			<MetricsGrid cellData={[...getNewPlanMetrics(contract), ...getAdditionalMetrics(contract)]} />
		</RatePlanCard>
	)
})

export default NewRatePlan
