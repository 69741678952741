import React from "react"
import { matchPath, Outlet, useLocation, useMatch, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Stack from "@components/fondation/Stack/Stack"
import Grid from "@components/fondation/Grid/Grid"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import MenuGroup from "@components/elements/List/MenuGroup"
import MenuItem from "@components/elements/List/MenuItem"
import AccountLayout from "@components/modules/layouts/AccountLayout"
import LogoutModal from "@pages/auth/LogoutModal"
import useAccountMenu from "./useAccountMenu"

const AccountOutlet: React.FC = observer(() => {
	const navigate = useNavigate()
	const location = useLocation()

	const isRoot = useMatch("/account/")
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const [isLogoutConfirmation, confirmLogout] = React.useState(false)
	const toggleLogoutConfirmation = React.useCallback(() => {
		confirmLogout((v) => !v)
	}, [])

	const menuOptions = useAccountMenu({ onLogout: toggleLogoutConfirmation })

	const isActive = (name: string) => !!matchPath(`/account/${name}/*`, location.pathname)
	const getLink = (name: string) => `/account/${name}`

	return (
		<AccountLayout>
			<Grid container spacing={8}>
				{(!isMobile || isRoot) && (
					<Grid item xs={12} lg={5}>
						<Stack spacing={4}>
							{menuOptions.map((groupList) => (
								<MenuGroup key={groupList[0].id}>
									{groupList.map((itemProps) => (
										<MenuItem
											onClick={() => navigate(getLink(itemProps.id))}
											{...itemProps}
											active={isActive(itemProps.id)}
											key={itemProps.id}
										/>
									))}
								</MenuGroup>
							))}
						</Stack>
					</Grid>
				)}
				<Grid item xs={12} lg={7}>
					<Outlet />
				</Grid>
			</Grid>
			<LogoutModal open={isLogoutConfirmation} onCancel={toggleLogoutConfirmation} />
		</AccountLayout>
	)
})

export default AccountOutlet
