import React from "react"
import Stack from "@components/fondation/Stack/Stack"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import { initArrayByLength } from "@root/lib/arrayUtils"

export interface CarouselPointsProps {
	slides?: number
	index?: number
	onChangeIndex?: (idx: number) => void
}

const Point = styled("span")<{ active?: boolean }>(({ theme, active }) => ({
	position: "relative",
	height: r(13),
	width: r(13),
	borderRadius: "50%",
	boxSizing: "border-box",
	border: active ? `1px solid ${theme.palette.grey[900]}` : "none",
	cursor: "pointer",

	"&:after": {
		content: "''",
		position: "absolute",
		height: r(8),
		width: r(8),
		transform: "translate(-50%, -50%)",
		borderRadius: "50%",
		left: "50%",
		top: "50%",
		opacity: active ? 1 : 0.3,
		background: theme.palette.grey[900],
	},
}))

export const CarouselPoints: React.FC<CarouselPointsProps> = ({ onChangeIndex, index = 0, slides = 1 }) => (
	<Stack direction="row" justifyContent="center" spacing={0.5}>
		{initArrayByLength(slides).map((_, idx) => (
			<Point active={index === idx} onClick={() => onChangeIndex?.(idx)} />
		))}
	</Stack>
)

export default CarouselPoints
