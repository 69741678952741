/* eslint-disable react/no-unescaped-entities */
import React from "react"

import Typography from "@components/fondation/Typography/Typography"

const PrivacyPolicy: React.FC = () => (
	<Typography variant="body2" sx={{ wordWrap: "break-word" }}>
		<h2>Privacy Policy</h2>
		<p>Updated: March 18, 2020 Effective date of California and CCPA Privacy Rights and Disclosures: 1/1/2020 </p>
		<p>
			<strong>Scope of this Privacy Policy</strong>
		</p>
		<p>
			Please read this Privacy Policy carefully to understand our policies and practices regarding your Information (as
			defined below) and how we will treat it.
		</p>
		<p>
			This Privacy Policy explains how your Information is collected, used, and disclosed by Arbor Energy Holdings LLC
			entities as listed in the controllers &amp; contact section ("we" or "us"). It also tells you how you can access
			and update your Information and make certain choices about how your Information is used.
		</p>
		<p>
			This Privacy Policy covers both our online and offline data collection activities, including information that we
			collect through our various channels such as websites, apps, third party social networks, customer care centers,
			studios, attractions/exhibitions, points of sales and events.
		</p>
		<p>
			Finally, we note that California law requires us to provide certain information about how we collect and use the
			personal information of California residents and to grant those California residents certain rights with respect
			to their data. If you are a California resident, please see the California and CCPA Privacy Rights and Disclosures
			section of this Policy for these disclosures and a description of your privacy rights, including a right to
			access, delete, or opt out of the sale of your personal information.
		</p>
		<p>
			<strong>INFORMATION WE COLLECT</strong>
		</p>
		<p>
			We collect and combine different types of information together, and collectively refer to all of this information
			in this Privacy Policy as “Information”. Such Information includes:
		</p>
		<p>
			<strong>Registration, account, and sign-up information</strong>. We collect Information in the course of your use
			of, or registration with, us. For example, when you create an account, register for, or download an application,
			or sign-up for a product or service, you provide us with certain personal information. This type of personal
			information can include: name, postal address, telephone number or email address. We can also receive Information
			about your interest in and use of various products, programs, services, and content available.
		</p>
		<p>
			<strong>Information about others/inviting friends.</strong> In certain situations, we collect Information you
			submit about other people. For example, you can submit Information to invite a friend to participate in an
			offering, make recommendations, or share content. By processing these requests, we can receive the other person’s
			Information, including details such as his/her name, postal address, email address, telephone number, or
			information about the recipient’s interest in and use of various products, programs, services, and content. In
			certain situations, we also allow you to invite your friends to participate in activities by providing their
			contact details or importing contacts from your address book or from Social Media Sites (as defined below).
		</p>
		<p>
			<strong>Information from other sources. </strong>We, on occasion, combine Information we receive online with other
			information, including usage information from our other sites and our online advertising and media. We also
			supplement or combine Information with information from a variety of other sources or outside records, such as
			demographic, transaction history, or personal information, and we use that combined information in accordance with
			this Privacy Policy.
		</p>
		<p>
			<strong>Social media</strong>. You can engage with some of our content and offerings, such as videos,
			applications, and other offerings on or through third party communities, forums, and social media sites, services,
			plug-ins, and applications (“Social Media Sites”). When you link to or interact with us or content through Social
			Media Sites, you allow us to receive certain Information from your social media account (e.g., name, user ID,
			email address, profile photo, photos and videos, gender, birthday, your list of friends and their contact details,
			people you follow and/or who follow you, the posts or the 'likes' you make). We also receive Information from your
			interaction with our content (e.g., content viewed, and information about advertisements you have been shown or
			have clicked on). Where permitted by law, by providing this Information or otherwise interacting with our Sites
			through Social Media Sites, you consent to our use of Information from the Social Media Site in accordance with
			this Privacy Policy.
		</p>
		<p>
			<strong>Location information</strong>. We have access to certain Information about your location, such as your
			country or address, when you provide it or via device information (such as an IP address). With your consent
			(where required by law), we can also collect Information about your device’s precise location (e.g. geolocation
			via mobile devices).
		</p>
		<p>
			<strong>Technical and usage information.</strong> We also collect certain technical and usage information such as
			the type of device, browser, and operating system you are using, your Internet service provider or mobile carrier,
			unique device identifier, IDFA or IDFV, MAC address, IP address, device and browser settings, the webpages and
			apps you use, advertisements you see and interact with, and certain site usage information. See our Cookies and
			Other Technical Information section for more information on how we may use these technologies to collect this
			Information.
		</p>
		<p>
			<strong>Customer inquiries.</strong> Where you contact us through one of our customer helpdesks or customer call
			centers, we can have access to the application, or product/service that you have purchased, your purchase history,
			your previous correspondence with us or your contact details. Calls to customer call centers may be recorded.
		</p>
		<p>
			<strong>HOW WE USE THE INFORMATION</strong>
		</p>
		<p>We use Information for the purposes described in this Privacy Policy or disclosed at the time of collection.</p>
		<p>
			<strong>Providing and marketing products and services. </strong>
		</p>
		<p>
			We use the Information we collect about you, to fulfill your purchase, or to fulfill your requests for, and
			otherwise provide or analyze your use of our products, application, services, and content, to facilitate sharing
			and other interactions with Social Media Sites, and to provide, develop, maintain, personalize, protect, and
			improve your experience and our offerings. For example, we use Information collected on our sites to enable you to
			do things like (i) get information about our products, services, and content, and (ii) locate and access
			personalized information, ads or functionality based on your interests and location. We also use Information for
			your payment of any products/services you purchase, to prevent fraud or to offer, market, and advertise products,
			programs, and services from us and our affiliates, trusted partners, and select third parties that we believe can
			be of interest to you.
		</p>
		<p>
			<strong>Our Reasons</strong>
		</p>
		<ul>
			<li>Fulfilling contractual obligations</li>
			<li>Legal obligations</li>
			<li>With your consent (where required)</li>
			<li>Our legitimate interests:</li>
			<li>Improving and developing new products and services</li>
			<li>Being more efficient</li>
			<li>Fighting fraud</li>
			<li>
				Retaining your shopping history and use details of the products you have previously purchased to make
				suggestions to you for other products which we believe you will also be interested in
			</li>
		</ul>
		<p>
			<strong>Communicating with you and others. </strong>
		</p>
		<p>
			We use Information about you to communicate with you, such as (i) to notify you when you win one of our contests
			or sweepstakes, or when we make changes to our policies or user agreements, (ii) to respond to your inquiries,
			(iii) to communicate with you about your purchases or transactions, (iv) to contact you about your account, or (v)
			to send you information about promotions, offerings, and our features. You can also choose to receive push
			notifications from us on your mobile device. If you choose to submit content for publication online or in other
			forums, we can publish your screen name and other Information you have provided to us, the Internet, or elsewhere.
			If you choose to contact one of our call centers we can record such calls. We use Information that you provide
			about others to enable us to send them invitations, promotions, or other content on your behalf or through our
			sites. From time to time, where permitted by law, we also use this Information to offer, market, or advertise
			products, programs, or services to them from us and our affiliates, business partners, and select third parties.
		</p>
		<p>
			<strong>Our Reasons</strong>
		</p>
		<ul>
			<li>Fulfilling contractual obligations</li>
			<li>Legal obligations</li>
			<li>With your consent (where required)</li>
			<li>Our legitimate interests:</li>
			<li>Communicating important information</li>
			<li>Improving and developing new products and services</li>
			<li>Being more efficient</li>
			<li>Compiling statistics</li>
		</ul>
		<p>
			<strong>Third party social networks. </strong>
		</p>
		<p>
			We use your Information when you interact with third party social networking features to serve you with
			advertisements and engage with you on third party social networks. You can learn more about how these features
			work, the profile data that we obtain about you, and find out how to opt out by reviewing the privacy notices of
			the relevant third party social networks.
		</p>
		<p>
			<strong>Our Reasons</strong>
		</p>
		<ul>
			<li>With your consent (where required)</li>
			<li>Our legitimate interests:</li>
			<li>Defining types of customers for new products or services</li>
			<li>
				Developing a profile of you and your interactions with us so that we can provide tailored offers, opportunities
				and services that may be of interest to you
			</li>
		</ul>
		<p>
			<strong>Use of technical and usage information. </strong>
		</p>
		<p>
			We use technical and usage information to improve our design, functionality and content and to enable us to
			personalize your experience with our content and offerings. We use this Information (i) to provide, develop,
			maintain, personalize, protect, and improve our products, applications, services and to operate our business, (ii)
			to perform analytics, including to analyze and report on usage and performance, (iii) to protect against,
			identify, and prevent fraud and other unlawful activity, (iv) to create aggregate data about groups or categories
			of our users, and (iv) for us and our affiliates, business partners, and select third parties to target, offer,
			market, or advertise products, programs, or services.
		</p>
		<p>
			<strong>Our Reasons</strong>
		</p>
		<ul>
			<li>With your consent (where required)</li>
			<li>Our legitimate interests:</li>
			<li>Defining types of customers for new products or services</li>
			<li>
				Developing a profile of you and your interactions with us so that we can provide tailored offers, opportunities
				and services that may be of interest to you
			</li>
		</ul>
		<p>
			<strong>Compliance.</strong>
		</p>
		<p>
			We use Information we collect to detect, investigate, and prevent activities that violate our terms of use, could
			be fraudulent, violate copyright, or other rules or that can be otherwise illegal, to comply with legal
			requirements, and to protect our rights and the rights and safety of our users and others.
		</p>
		<p>
			<strong>Our Reasons</strong>
		</p>
		<ul>
			<li>Legal obligations</li>
			<li>Our legitimate interests:</li>
			<li>Protecting our business interests and rights, privacy, safety and property, or that of the public</li>
			<li>
				Sharing your Information with third parties in order to permit us to pursue available remedies or limit damage
				that we may sustain
			</li>
		</ul>
		<p>
			<strong>INFORMATION SHARING AND DISCLOSURE</strong>
		</p>
		<p>
			We share and disclose Information in the following ways or for any other purpose disclosed at the time of
			collection:
		</p>
		<p>
			<strong>Law enforcement bodies and courts. </strong>We disclose Information in response to legal process, for
			example in response to a court order or a subpoena, or in response to a law enforcement body's request. We also
			disclose such Information to third parties: (i) in connection with fraud prevention activities, (ii) where we
			believe it is necessary to investigate, prevent, or take action regarding illegal activities, (iii) in situations
			that can involve violations of our terms of use or other rules, (iv) to protect our rights and the rights and
			safety of others, and (v) as otherwise required by law.
		</p>
		<p>
			<strong>Change of control.</strong> We transfer Information in the event of a business transaction, such as if we
			or one of our business units or our relevant assets are acquired by, sold to, or merged with another company or as
			part of a bankruptcy proceeding or a business reorganization.
		</p>
		<p>
			<strong>Service providers.</strong> Our agents and contractors have access to Information to help carry out the
			services they are performing for us, such as, but not limited to, fulfilment, creation, maintenance, hosting, and
			delivery of products and services, conduct marketing, handle payments, email and order fulfillment, administer
			contests, conduct research and analytics, or customer service.
		</p>
		<p>
			<strong>Linked sites.</strong> Some of our sites contain links to other sites, including Social Media Sites, whose
			information practices can be different from ours. You should consult the other sites' privacy disclosures and
			terms before submitting any Information, as we have no control over Information that is submitted to, or collected
			by, these third parties.
		</p>
		<p>
			<strong>Sponsors and co-promotions.</strong> We sometimes offer content or programs that are sponsored by or
			co-branded with identified third parties. By virtue of these relationships, the third parties collect or obtain
			Information from you when participating in the activity. We have no control over these third parties' use of this
			Information. We encourage you to look at the privacy disclosure of any such third party to learn about their data
			practices.
		</p>
		<p>
			<strong>Advertising networks.</strong> We share certain Information with third parties to provide advertising to
			you based on your interests. For more information, please see our (i) Cookies and Other Technical Information and
			(ii) Ad Choices sections below.
		</p>
		<p>
			<strong>YOUR CHOICES</strong>
		</p>
		<p>
			<strong>Marketing communications and sharing with third parties.</strong> We provide you with an opportunity to
			express your preferences with respect to receiving certain marketing communications from us, and our sharing of
			Information with trusted partners for their direct marketing purposes. If you previously chose to receive push
			notifications on your mobile device from us, but no longer wish to receive them, you can manage your preferences
			through your device or app settings, depending on the type of device.
		</p>
		<p>
			<strong>Ad Choices.</strong> We work with reputable third parties to present advertisements, and engage in data
			collection, reporting, ad delivery and response measurement, and site analytics on our sites and on third party
			websites across the Internet and applications over time. These third parties use cookies, web beacons, pixels, or
			similar technologies to perform this activity. They also obtain information about applications you use, websites
			you visit, and other information from across your devices and browsers in order to help serve advertising that
			will be more relevant to your interests on and off our sites and across your devices and browsers. This type of
			advertising is known as interest-based advertising. They also use this information to associate various browsers
			and devices together for the purpose of interest-based advertising and other purposes like analytics.
		</p>
		<p>
			For more information about interest-based advertising on your desktop or mobile browser, and your ability to opt
			out of this type of advertising by third parties that participate in self-regulatory programs, please visit
			https://optout.networkadvertising.org/?c=1. Please note that any opt-out choice you exercise through these
			programs will apply to interest-based advertising by the third parties you select, but will still allow the
			collection of data for other purposes, including research, analytics, and internal operations. You can continue to
			receive advertising, but that advertising will be less relevant to your interests.
		</p>
		<p>
			You can have more options depending on your mobile device and operating system. For example, most device operating
			systems (e.g., iOS for Apple phones, Android for Android devices, and Windows for Microsoft devices) provide their
			own instructions on how to limit or prevent the delivery of tailored in-application advertisements. You can review
			the support materials and/or the privacy settings for the respective operating systems to learn more about these
			features and how they apply to tailored in-app advertisements.
		</p>
		<p>
			See also Cookies and Other Technical Information for more choices about managing other technical and usage
			information.
		</p>
		<p>
			<strong>COOKIES AND OTHER TECHNICAL INFORMATION</strong>
		</p>
		<p>
			<strong>Cookies and other technologies.</strong> We, and our affiliates, third party service providers, and
			trusted partners send "cookies" to your device or use similar technologies to understand and enhance your online
			experience with us and through our advertising and media across the Internet including Social Media Sites.
		</p>
		<p>
			Cookies are small text files placed in your browser. We also use pixels or "web beacons" that monitor your use of
			our sites. Web beacons are small strings of code that provide a method for delivering a graphic image on a webpage
			for the purpose of transferring data, such as the IP address of the device that downloaded the page on which the
			web beacon appears, the URL of the page on which the web beacon appears, the time the page containing the web
			beacon was viewed, the type of browser that fetched the web beacon, and the identification number of any cookie on
			the device previously placed by that server.
		</p>
		<p>
			We can use cookies and other technologies to help recognize your browser or device, maintain your preferences,
			provide certain features, and collect Information about your online interactions with us, our content, and our
			communications. For example, when corresponding with you via HTML capable email, web beacons and other
			technologies let us know about your activity, including whether you received and opened our email, clicked through
			a link, or otherwise interacted with our content, and this information can be associated with Information
			previously collected.
		</p>
		<p>
			We also use cookies and other technologies (i) to provide, develop, maintain, personalize, protect, and improve
			our sites, products, services, and to operate our business, (ii) to perform analytics, including to analyze and
			report on usage and performance of our content and marketing materials, (iii) to protect against, identify, and
			prevent fraud and other unlawful activity, (iv) to create aggregate data about groups or categories of our users,
			(v) to synchronize users across devices, affiliates, and trusted partners, and (vi) for us and our affiliates, and
			trusted partners to target, offer, market, or advertise products, apps, or services. Cookies and other
			technologies also facilitate, manage, and measure the performance of advertisements displayed on or delivered by
			or through us.
		</p>
		<p>
			<strong>Managing cookies and other technologies. </strong>
		</p>
		<p>
			<strong>Details</strong>
		</p>
		<p>
			<strong>Strictly necessary cookies or similar technologies:</strong> They are essential in order to enable you to
			move around our sites and use its features, such as accessing secure areas. Without them, services like enabling
			appropriate content based on your type of device cannot be provided.
		</p>
		<p>
			<strong>Performance cookies or similar technologies: </strong>They collect information about how you use our
			sites, so that we can analyze traffic and understand users' interactions. We may use third party service providers
			such as Google Analytics for this purpose who may use their own cookies or similar technologies. The information
			is used to improve our site.
		</p>
		<p>
			<strong>Functionality cookies or similar technologies</strong>: They allow our sites to remember choices you make
			(such as your user name, or the region you are in) and provide enhanced, more personal features. They can also be
			used to remember changes you have made to text size, fonts and other parts of web pages that you can customize.
			They may also be used to provide services you have asked for such as watching a video or commenting on a blog.
		</p>
		<p>
			<strong>Social media cookies or similar technologies:</strong> They are used when you share information using a
			social media sharing button or “like” button on our sites, or when you link your account or engage with our
			content on or through a social media site such as Facebook, Twitter or Google+. The social network will record
			that you have done this. This information may be linked to targeting/advertising activities.
		</p>
		<p>
			<strong>Advertising cookies or similar technologies:</strong> Some of our sites may use a third party advertising
			network, to deliver targeted advertising. They may also have the capability to track your browsing across sites
			and Social Media Sites.
		</p>
		<p>
			We do not currently take actions to respond to Do Not Track signals because a uniform technological standard has
			not yet been developed. We continue to review new technologies and may adopt a standard once one is created.
		</p>
		<p>See the Your Choices section to learn how to control data collection for certain purposes. </p>
		<p>
			<strong>DATA RETENTION</strong>
		</p>
		<p>
			In broad terms, we will only retain your personal information for as long as is necessary for the purposes
			described in this Privacy Policy. This means that the retention periods will vary according to the type of
			personal information and the reason that we have the personal information in the first place. For example, some
			personal information related to the provision of services to you or by you will be kept for a number of years in
			order to comply with various finance and tax related legal obligations. Other service related personal information
			may be kept for a different period because it is in our legitimate interests to do so in order to provide or
			receive appropriate follow up service.
		</p>
		<p>
			We have a detailed internal retention policy that sets out varying retention periods for different categories of
			personal information depending on our legal obligations and whether there is a commercial need. After a retention
			period has elapsed, the personal information is securely deleted.
		</p>
		<p>
			<strong>HOW YOU CAN ACCESS OR CORRECT INFORMATION</strong>
		</p>
		<p>
			Under conditions, you have the right to ask us for a copy of your Information, to correct it, erase it or restrict
			its processing, and to obtain the Information you provide in a structured, machine readable format. You also have
			the right to ask us to transfer some of this Information to other organizations.
		</p>
		<p>
			You have the right to object to the processing of personal information on the basis of our legitimate interests.
			Where we have asked for your consent to process Information, you have the right to withdraw this consent at any
			time.
		</p>
		<p>
			We reserve the right not to allow access to your Information or to limit your rights (e.g. if such disclosure is
			prohibited by law or if the rights of another individual might be violated). In some instances, this may mean that
			we are able to retain your Information even if you withdraw your consent. To exercise these rights, or to contact
			our Data Protection Officer, please submit a request via email to{" "}
			<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<p>
			Additionally, California law grants California residents the right to, among other things, access, delete, and opt
			out of the sale of certain personal information. For a description of these rights, please see the section on
			California and CCPA Privacy Rights and Disclosures.
		</p>
		<p>
			<strong>NOTIFICATION REGARDING UPDATES</strong>
		</p>
		<p>
			From time to time, we can update this Privacy Policy. We will notify you about material changes to this Privacy
			Policy by placing a notice on our sites. We encourage you to periodically check back and review this policy so
			that you always know our current privacy practices.
		</p>
		<p>
			<strong>CALIFORNIA AND CCPA PRIVACY RIGHTS AND DISCLOSURES </strong>(effective 1/1/2020)
		</p>
		<p>
			This California Privacy Rights and Disclosures section addresses legal obligations and rights laid out in the
			California Consumer Privacy Act, or CCPA. These obligations and rights apply to businesses doing business in
			California and to California residents and information that identifies, relates to, describes, is reasonably
			capable of being associated with, or could reasonably be linked, directly or indirectly, with California consumers
			or households (“California Information”). The information contained in this section applies to California
			Information collected and used by Arbor Energy Holdings LLC (for the purposes of this section, “we” or “us”). If
			you are a California resident interested in the rights that the CCPA grants to you, please review the following
			sections.
		</p>
		<p>
			<strong>
				Your California Privacy Rights to Request Disclosure of Information We Collect and Share About You.
			</strong>
			If you are a California resident, California Civil Code Section 1798.83 permits you to request information about
			our practices related to the disclosure of your personal information by Arbor Energy Holdings LLC to certain third
			parties for their direct marketing purposes. You may be able to opt-out of our sharing of your personal
			information with unaffiliated third parties for the third parties’ direct marketing purposes in certain
			circumstances. Please send your request (along with your full name, email address, postal address, and the subject
			line labeled “Your California Privacy Rights”) by email at{" "}
			<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<p>
			In addition, if you are a California resident, the CCPA grants you the right to request certain information about
			our practices with respect to California Information. In particular, you can request the following:
		</p>
		<p>• The categories and specific pieces of your California Information that we’ve collected</p>
		<p>• The categories of sources from which we collected California Information</p>
		<p>• The business or commercial purposes for which we collected or sold California Information</p>
		<p>• The categories of third parties with which we shared California Information. </p>
		<p>You can submit a request to us for the following additional information: </p>
		<p>
			• The categories of third parties to which we’ve sold California Information, and the category or categories of
			California Information sold to each.
		</p>
		<p>
			• The categories of California Information that we’ve shared with service providers who provide services for us,
			like processing your bill.
		</p>
		<p>
			<strong>Your Right to Request the Deletion of California Information.</strong> Upon your request, we will delete
			the California Information we have collected about you, except for situations when that information is necessary
			for us to: provide you with a product or service that you requested; perform a contract we entered into with you;
			maintain the functionality or security of our systems; comply with or exercise rights provided by the law; or use
			the information internally in ways that are compatible with the context in which you provided the information to
			us, or that are reasonably aligned with your expectations based on your relationship with us.
		</p>
		<p>
			<strong>Your Right to Ask Us Not to Sell Your California Information. </strong>You can always tell us not to sell
			your California Information by emailing us at <a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
		<p>
			Once we receive and process your request, we will not sell your California Information unless you later allow us
			to do so.
		</p>
		<p>
			<strong>Do Not Track Notice.</strong> We do not currently take actions to respond to Do Not Track signals and
			similar signals because a uniform technological standard has not yet been developed. We continue to review new
			technologies and may adopt a standard once one is created.
		</p>
		<p>See the Your Choices section to learn how to control data collection for certain purposes.</p>
		<p>
			<strong>Our Support for the Exercise of Your Data Rights.</strong> We are committed to providing you control over
			your California Information. If you exercise any of these rights explained in this section of the Privacy Policy,
			we will not disadvantage you. You will not be denied or charged different prices or rates for goods or services or
			provided a different level or quality of goods or services.
		</p>
		<p>
			<strong>Consumers Under 16 Years Old. </strong>CCPA has specific rules regarding the use of California Information
			from consumers under 16 years of age. In particular, consistent with the CCPA, if we knowingly collect the
			California Information of a consumer under the age of 16, we will not sell the information unless we receive
			affirmative permission to do so. If the consumer is between the ages of 13 and 16 years of age, the consumer may
			provide that permission; if the consumer is under the age of 13, the consumer’s parent or guardian must provide
			the permission.
		</p>
		<p>
			If you would like further information on how we handle California Information from consumers under the age of 16
			years of age, or if you have questions about these information practices, you may contact us at{" "}
			<a href="mailto:support@joinarbor.com">support@joinarbor.com</a>.
		</p>
	</Typography>
)

export default PrivacyPolicy
