import React, { useMemo, useEffect } from "react"
import { useInstances } from "react-ioc"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Grid from "@components/fondation/Grid/Grid"
import Typography from "@components/fondation/Typography/Typography"
import UtilityMeta from "@components/prototypes/utilities/UtilityMeta/UtilityMeta"
import ConnectUtilityForm, { FormData } from "@components/modules/forms/connect-utility/ConnectForm"
import NavLink from "@components/elements/NavLink/NavLink"
import Box from "@components/fondation/Box/Box"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import { CountdownLoader } from "@components/prototypes/CountdownLoader"
import { trackEvent } from "@model/utils/tracking"
import AppProvider from "@root/AppProvider"
import LinkUtilityStore from "@store/auth/link-utility.store"
import UtilityStore from "@store/auth/utilities.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import ContainerBox from "@components/prototypes/utilities/UtilityMeta/ContainerBox"

AppProvider.register(LinkUtilityStore)

const ConnectUtilityPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const [linkUtilityStore, utilityStore] = useInstances(LinkUtilityStore, UtilityStore)

	useEffect(() => {
		trackEvent("Link Attempted", "Urjanet")
	}, [])

	const { currentUtility, isLoading, isVerifying, error } = linkUtilityStore
	const { utilityLogo, utilityShortName } = utilityStore

	const handleSubmit = async (form: FormData) => {
		await linkUtilityStore.linkUtility(form)
		const isTimeUp = await linkUtilityStore.waitForCountDown()
		if (isTimeUp && linkUtilityStore.navigateToSuccess) navigate("/enrollment/success")
		if (isTimeUp && linkUtilityStore.isVerifying) navigate("/enrollment/verifying")
		if (isTimeUp && linkUtilityStore.navigateToLink) navigate("/enrollment/provider/failed")
	}

	const initialValues = useMemo(() => {
		const link = currentUtility?.link
		if (!link) return {}
		return {
			username: link.username ?? "",
			password: link.password ?? "",
		}
	}, [currentUtility])

	return (
		<ModalBox>
			<Box>
				<ContainerBox>
					<UtilityMeta name={utilityShortName} logoSrc={utilityLogo} />
				</ContainerBox>

				<Typography variant="h3" mb={3}>
					Enter your credentials
				</Typography>
			</Box>

			{isVerifying ? (
				<CountdownLoader />
			) : (
				<Box>
					<Grid container spacing={3} alignItems="flex-start">
						<Grid item xs={12}>
							<ConnectUtilityForm loading={isLoading} initialValues={initialValues} onSubmit={handleSubmit}>
								{!!error && (
									<Alert severity="error">
										<AlertTitle>{error.title}</AlertTitle>
										{error.message}, or <NavLink to="../link">Link Account Manually</NavLink>
									</Alert>
								)}
							</ConnectUtilityForm>
						</Grid>

						<Grid item xs={12} textAlign="center">
							<Typography variant="body2">
								<NavLink to="../link">Link Account Manually</NavLink>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			)}
		</ModalBox>
	)
})

export default ConnectUtilityPage
