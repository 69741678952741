import React from "react"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Typography from "@components/fondation/Typography/Typography"
import AddIcon from "@mui/icons-material/Add"
import Collapse from "@components/elements/Collapse/Collapse"
import IconButton from "@components/elements/Button/IconButton"
import useToggle from "@components/prototypes/hooks/useToggle"
import CardBox from "../CardBox"

export interface CollapsibleCardProps {
	title: string
	children: React.ReactNode
}

interface CommonProps {
	open: boolean
}

const Container = styled(CardBox)<CommonProps>(({ theme, open }) => ({
	borderColor: open ? theme.palette.primary.main : "transparent",
	boxShadow: open ? "0px 5px 41px rgba(14, 15, 25, 0.1)" : undefined,
}))

const Trigger = styled(IconButton)<CommonProps>(({ theme, open }) => ({
	fontSize: r(20),
	padding: r(4),
	backgroundColor: open ? "initial" : theme.palette.grey[50],
	transition: "transform .3s",
	transform: open ? "rotate(-45deg)" : undefined,
}))

const Header = styled("div")(({ onClick }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-start",
	cursor: onClick != null ? "pointer" : "default",
	gap: r(10),
	padding: r(16),
	margin: r(-16),
}))

const Content = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[800],
	marginTop: r(10),
}))

const CollapsibleCard: React.FC<CollapsibleCardProps> = React.memo((props) => {
	const { children, title } = props
	const { isActive: open, handleToggle } = useToggle(false)

	return (
		<Container open={open}>
			<Header onClick={handleToggle}>
				<Typography variant="h5">{title}</Typography>
				<Trigger open={open}>
					<AddIcon />
				</Trigger>
			</Header>

			<Collapse in={open}>
				<Content variant="body2">{children}</Content>
			</Collapse>
		</Container>
	)
})

export default CollapsibleCard
