import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Box from "@components/fondation/Box/Box"
import TicketPerforation from "./TicketPerforation"

export const Container = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.green.main,
	color: theme.palette.common.white,
	padding: r(16),
}))

export interface HeaderProps {
	label?: React.ReactNode
	title?: React.ReactNode
	children?: React.ReactNode
}

export const TicketStub: React.FC<HeaderProps> = ({ label, title, children }) => (
	<Container>
		<Typography variant="h3" color="inherit" fontSize={r(20)} hidden={label == null} textAlign="center">
			{label}
			<TicketPerforation />
		</Typography>

		<Typography variant="h4" color="inherit" mb={1}>
			{title}
		</Typography>

		<Typography variant="body2" color="inherit">
			{children}
		</Typography>
	</Container>
)

export default TicketStub
