import React from "react"
import { observer } from "mobx-react-lite"

import SupportForm, { SupportFormData } from "@components/modules/forms/SupportForm"
import CardBox from "@components/elements/Card/CardBox"
import { Typography } from "@mui/material"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import SubPageLayout from "@pages/account/AccountSectionLayout"
import UserStore from "@store/auth/user.store"
import { useInstance } from "react-ioc"
import { toast } from "react-toastify"
import { ErrorToast, SuccessToast } from "@components/elements/Toast/Toast"

const AccountSupportPage: React.FC = observer(() => {
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))
	const userStore = useInstance(UserStore)

	const handleSubmit = async (form: SupportFormData) => {
		const requestError = await userStore.supportRequest(form)
		if (requestError) {
			toast(<ErrorToast title="Oops!" description={requestError.message} />)
		} else {
			toast(<SuccessToast title="Alright!" description="We have received your message and will respond shortly." />)
		}
	}

	return (
		<SubPageLayout label="Support">
			<CardBox bg={isMobile ? "secondary" : "primary"} elevation={isMobile ? 0 : 1} p={isMobile ? 0 : 6}>
				<Typography variant="h4" mb={2.5}>
					Contact Us
				</Typography>
				<SupportForm onSubmit={handleSubmit} loading={userStore.isLoading} />
			</CardBox>
		</SubPageLayout>
	)
})

export default AccountSupportPage
