import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useInstance } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import Button from "@components/elements/Button/Button"
import { getImageUrl } from "@components/elements/Image/collection"
import Stack from "@components/fondation/Stack/Stack"
import NavLink from "@components/elements/NavLink/NavLink"
import UserStore from "@store/auth/user.store"
import Loader from "@components/elements/Loader/Loader"

const IMAGE_SRC = getImageUrl("switch.png")
const TITLE = "Want to automate your savings?"
const MESSAGE =
	"Activate our free Autopilot service and we’ll monitor the energy markets for a better rate than your current plan. When we find one, we’ll reserve it for you. If you don’t opt out, you'll get your new rate on your next bill."

const AutopilotPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const userStore = useInstance(UserStore)

	const handleSubmit = async () => {
		await userStore.updateAutopilot(true)
		navigate("/enrollment/provider")
	}

	const loading = userStore.isLoading

	return (
		<Stack alignItems="stretch" mt={10}>
			<Stack alignItems="center" mb={5}>
				<Image src={IMAGE_SRC} width={192} />
			</Stack>

			<Typography variant="h3" textAlign="center" mb={2.5}>
				{TITLE}
			</Typography>

			<Typography variant="body1" color="grey.800" textAlign="center" mb={10}>
				{MESSAGE}
			</Typography>

			<Stack spacing={5} alignItems="center">
				<Button variant="contained" fullWidth onClick={handleSubmit} disabled={loading} endIcon={loading && <Loader />}>
					Yes, Activate autopilot
				</Button>
				<NavLink to="../rate" disabled={loading}>
					<Typography variant="h6">No thanks, I’ll do everything manually</Typography>
				</NavLink>
			</Stack>
		</Stack>
	)
})

export default AutopilotPage
