import React from "react"
import Grid from "@components/fondation/Grid/Grid"
import { useNavigate } from "react-router-dom"

import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import ModalHeader from "@components/elements/HeaderBar/ModalHeader"
import NavLink from "@components/elements/NavLink/NavLink"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"

const IMAGE_SRC = getImageUrl("shake.png")
const TITLE = "Thank you!"
const WELCOME_MESSAGE = "We are currently compiling the best ways to save in your area. We will be in touch very soon!"

const UnsupportedConfirmationPage: React.FC = React.memo(() => {
	const navigate = useNavigate()

	const handleBack = () => {
		navigate("/area")
	}

	return (
		<ModalBox flexBasis="100%">
			<ModalHeader BackButtonProps={{ onClick: handleBack }} hideEndButton />

			<ModalBox flexBasis="100%" p={4}>
				<Grid container spacing={6} justifyContent="center" marginY="auto">
					<Grid item xs={12} textAlign="center">
						<Image src={IMAGE_SRC} height={120} />
					</Grid>

					<Grid item xs={12} marginX={10}>
						<Typography variant="h2" textAlign="center" mb={3}>
							{TITLE}
						</Typography>
						<Typography variant="body1" color="grey.800" textAlign="center">
							{WELCOME_MESSAGE}
						</Typography>
					</Grid>

					<Grid item>
						<Typography variant="body2" color="black">
							<NavLink to="/area">Start Over</NavLink>
						</Typography>
					</Grid>
				</Grid>
			</ModalBox>
		</ModalBox>
	)
})

export default UnsupportedConfirmationPage
