import React, { ReactElement } from "react"
import { useField } from "formik"
import TextField from "@components/elements/TextField/TextField"
import { TextFieldProps } from "@mui/material/TextField"
import { fieldHasError, getFieldErrorMessage } from "./utils"

export type FormTextFieldProps<T = string> = TextFieldProps & {
	name: keyof T
}

export const FormTextField = <T,>({ name, helperText, ...rest }: FormTextFieldProps<T>): ReactElement => {
	const [input, meta] = useField<T>(name)
	return (
		<TextField
			name={name}
			value={input.value}
			onChange={input.onChange}
			onBlur={input.onBlur}
			error={fieldHasError(meta)}
			helperText={getFieldErrorMessage(meta) || helperText}
			{...rest}
		/>
	)
}

export default FormTextField
