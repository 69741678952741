import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import { FileRejection } from "react-dropzone"
import { useNavigate } from "react-router-dom"

import Box from "@components/fondation/Box/Box"
import Grid from "@components/fondation/Grid/Grid"
import Typography from "@components/fondation/Typography/Typography"
import Dropzone from "@components/elements/Dropzone/Dropzone"
import Button from "@components/elements/Button/Button"
import BillsStore from "@store/bills.store"
import BillFile from "@components/prototypes/utilities/BillFile"
import Loader from "@components/elements/Loader/Loader"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import ModalBox from "@components/elements/Modal/ModalBox"
import LinkUtilityStore from "@store/auth/link-utility.store"
import UtilityStore from "@store/auth/utilities.store"
import { trackEvent } from "@model/utils/tracking"

const BillUploadPage: React.FC = observer(() => {
	const navigation = useNavigate()
	const [billsStore, linkStore, utilityStore] = useInstances(BillsStore, LinkUtilityStore, UtilityStore)

	useEffect(() => {
		trackEvent("Link Attempted", "Bill Upload")
	}, [])

	const handleUpload = (files: File[], rejected: FileRejection[]) => {
		billsStore.add([...files, ...rejected])
	}

	const handleSubmit = async () => {
		const { folderId, folderName, totalFiles } = billsStore
		const error = await linkStore.uploadBills(folderId, folderName, totalFiles)
		if (!error) navigation("/enrollment/success")
	}

	const utilityName = utilityStore.utilityShortName

	const formDisable = !billsStore.isAllUploaded || !billsStore.totalFiles || linkStore.isLoading
	const loading = linkStore.isLoading

	return (
		<ModalBox flexBasis="100%">
			<Box flexGrow={1} pb={5}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h3" mb={1}>
							Upload your electric bill
						</Typography>
						<Typography variant="body1" color="grey.800">
							Share a copy of a recent {utilityName} bill.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Alert severity="warning">
							<AlertTitle>Please include every page of your bill</AlertTitle>
							You can upload multiple files, if needed.
						</Alert>
					</Grid>

					<Grid item xs={12}>
						<Dropzone onFileAdded={handleUpload} maxFileSize={50} />
					</Grid>

					{billsStore.list.map((bill) => (
						<Grid item xs={12} key={bill.file.name}>
							<BillFile data={bill} onDelete={billsStore.deleteBill} />
						</Grid>
					))}
				</Grid>
			</Box>

			<Box>
				<Button
					fullWidth
					variant="contained"
					size="large"
					type="submit"
					disabled={formDisable}
					onClick={handleSubmit}
					endIcon={loading && <Loader />}
				>
					Continue
				</Button>
			</Box>
		</ModalBox>
	)
})

export default BillUploadPage
