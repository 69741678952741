/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { UtilityStatus } from "@components/prototypes/utilities/UtilityMeta/type"
import { UserService } from "@model/types/user"

export const extractUtilityStatus = (service?: UserService): UtilityStatus => {
	if (service == null) return "unlinked"

	if (service.accountNumber && service.utility?.link?.status === "ok") return "linked"

	// with cred
	if ((!service.accountNumber && service.utility.link?.status === "ok") || service.utility.link?.status === "verifying")
		return "in-progress"

	// with bill
	if (!service.accountNumber && service.updates?.length) return "in-progress"

	if (!service.accountNumber && service.utility?.link && service.utility?.link?.status === "error") return "error"

	if (service.accountNumber) return "partially-linked"

	return "unlinked"
}

export const formNeedsVerification = (form: { email?: string; phone?: string }) => !!form?.email || !!form?.phone

export const formHasProps = (form: any) => Object.keys(form).length > 0
