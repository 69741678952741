import React from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/fondation/Stack/Stack"
import MenuGroup from "@components/elements/List/MenuGroup"
import MenuItem, { MenuItemProps } from "@components/elements/List/MenuItem"
import { matchPath, useLocation, useNavigate } from "react-router-dom"
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined"
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined"
import SubPageLayout from "@pages/account/AccountSectionLayout"

export const menuList: MenuItemProps[] = [
	{
		id: "terms",
		primaryText: "Terms of service",
		icon: <HandshakeOutlinedIcon />,
	},
	{
		id: "privacy",
		primaryText: "Privacy policy",
		icon: <PrivacyTipOutlinedIcon />,
	},
]

const LegalPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const location = useLocation()

	const isActive = (name: string) => !!matchPath(`/${name}`, location.pathname)
	const getLink = (name: string) => `/${name}`

	return (
		<SubPageLayout label="Legal">
			<Stack spacing={4}>
				<MenuGroup>
					{menuList.map((itemProps) => (
						<MenuItem
							{...itemProps}
							onClick={() => navigate(getLink(itemProps.id))}
							active={isActive(itemProps.id)}
							key={itemProps.id}
						/>
					))}
				</MenuGroup>
			</Stack>
		</SubPageLayout>
	)
})

export default LegalPage
