import React from "react"
import { observer } from "mobx-react-lite"

import RateDetailsCard from "@components/elements/Card/RateDetailsCard"
import SimpleTable from "@components/elements/Table/SimpleTable"
import LabelCard from "@components/elements/Card/LabelCard"
import Button from "@components/elements/Button/Button"
import MarkedItem from "@components/elements/List/MarkedItem"
import Stack from "@components/fondation/Stack/Stack"
import { shortMoneyFormatter } from "@root/lib/numberUtils"
import Divider from "@components/fondation/Divider/Divider"
import useRateOffer from "./useRateOffer"

interface RateOfferCardProps {
	onSwitch?: React.MouseEventHandler
}

export const RateOfferCard: React.FC<RateOfferCardProps> = observer(({ onSwitch }) => {
	const cardData = useRateOffer()

	return (
		<RateDetailsCard
			headerProps={{
				label: cardData.header.label,
				title: cardData.header.title,
				children: cardData.header.description,
			}}
		>
			<SimpleTable rows={cardData.rateInfo} />

			{cardData.savings > 0 ? (
				<LabelCard label="Potential Savings" color="success">
					{shortMoneyFormatter.format(cardData.savings)}
					<sub> over {cardData.offer.guaranteedTerm ?? 0} months</sub>
				</LabelCard>
			) : null}

			{!onSwitch ? (
				<Divider />
			) : (
				<Button variant="contained" onClick={onSwitch} fullWidth>
					switch now
				</Button>
			)}

			<Stack>
				{cardData.offer.benefits.map((item) => (
					<MarkedItem key={item}>{item}</MarkedItem>
				))}
			</Stack>
		</RateDetailsCard>
	)
})

export default RateOfferCard
