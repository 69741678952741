import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import UserStore from "@store/auth/user.store"
import { renderFullAddress } from "@model/utils/address"
import { EditablePreviewCard, EditablePreviewCardProps } from "./EditablePreviewCard"

interface AddressCard extends EditablePreviewCardProps {}

export const AddressCard: React.FC<AddressCard> = observer(({ label = "Service Address", ...props }) => {
	const userStore = useInstance(UserStore)
	const { user } = userStore

	const fullAddress = (user != null && renderFullAddress(user)) || "Please provide your address"

	return (
		<EditablePreviewCard {...props} label={label}>
			<Typography variant="body2">{fullAddress}</Typography>
		</EditablePreviewCard>
	)
})

export default AddressCard
