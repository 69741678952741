import { UserArea } from "@model/types/user"
import AbstractService from "../AbstractService"
import { formatDocument } from "../utils"

class AreaService extends AbstractService {
	constructor() {
		super("areas")
	}

	getArea = async (key: string): Promise<UserArea> => {
		const snapshot = await this.getById(key)
		return formatDocument(snapshot.data()) as Promise<UserArea>
	}
}

export default AreaService
