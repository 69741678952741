import React, { useEffect } from "react"
import { gsap } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import { CustomEase } from "gsap/CustomEase"
import SvgIcon from "@mui/material/SvgIcon"
import { r } from "@theme/utils"
import {
	loaderPart1Path,
	loaderPart2Path,
	loaderPart3Path,
	animationFunction1,
	animationFunction2,
	animationFunction3,
} from "@components/elements/Loader/constants"

// Registering gsap plugins
gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(CustomEase)

export type TSize = "small" | "medium" | "large" | "inherit"

export interface ILoaderProps {
	center?: boolean
	size?: TSize
	speed?: number // 0 - 10
	color?: "inherit" | "default"
}

const sizeMap: Record<TSize, string> = {
	small: r(40),
	medium: r(80),
	large: r(120),
	inherit: "inherit",
}

const cRad = 35
const colors = ["#d88d48", "#d6b943", "#7bae9d"]

const getColorByIndex = (idx: number) => {
	if (colors.length <= 0) return "black"
	return colors[idx % colors.length]
}

const Loader = React.memo(({ size = "inherit", color = "default", center = false, speed = 5 }: ILoaderProps) => {
	const duration = 10 / speed || 1
	const getColor = color === "default" ? getColorByIndex : () => color

	useEffect(() => {
		gsap.to(".loader_part_1", {
			motionPath: loaderPart1Path,
			duration,
			repeat: -1,
			ease: CustomEase.create("custom", animationFunction1),
		})

		gsap.to(".loader_part_2", {
			motionPath: loaderPart2Path,
			duration,
			repeat: -1,
			ease: CustomEase.create("custom", animationFunction2),
		})

		gsap.to(".loader_part_3", {
			motionPath: loaderPart3Path,
			duration,
			repeat: -1,
			ease: CustomEase.create("custom", animationFunction3),
		})
	}, [duration])

	return (
		<SvgIcon
			className="arbor-loader"
			sx={{ fontSize: sizeMap[size], margin: center ? "auto" : 0 }}
			viewBox="0 0 300 300"
		>
			<g className="loader_part_1">
				<ellipse rx={cRad} ry={cRad} fill={getColor(0)} strokeWidth="0" />
			</g>
			<g className="loader_part_2">
				<ellipse rx={cRad} ry={cRad} fill={getColor(1)} strokeWidth="0" />
			</g>
			<g className="loader_part_3">
				<ellipse rx={cRad} ry={cRad} fill={getColor(2)} strokeWidth="0" />
			</g>
		</SvgIcon>
	)
})

export default Loader
