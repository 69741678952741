import React from "react"
import MUIBackdrop from "@mui/material/Backdrop"
import styled from "@mui/material/styles/styled"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import Portal from "@mui/material/Portal"
import { r } from "@theme/utils"
import UIImage from "../Image/Image"
import IconButton from "../Button/IconButton"

interface Props {
	src: string
	showNextButton?: boolean
	showPrevButton?: boolean
	open: boolean
	onClose: () => void
	onNext?: () => void
	onPrev?: () => void
}

const Container = styled(MUIBackdrop)(({ theme }) => ({
	background: theme.palette.common.black,
	zIndex: theme.zIndex.modal,
}))

const Image = styled(UIImage)({
	width: "min(700px, 100vw)",
	maxHeight: "100vh",
})

const CloseButton = styled(IconButton)(({ theme }) => ({
	position: "fixed",
	top: r(15),
	right: r(15),
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.common.white,
	padding: r(5),
	fontSize: r(14),
	"&:hover": {
		backgroundColor: theme.palette.secondary.light,
	},
}))

const NextButton = styled(IconButton)(({ theme }) => ({
	position: "fixed",
	top: "50%",
	right: r(15),
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.common.white,
	padding: r(5),
	fontSize: r(14),
	"&:hover": {
		backgroundColor: theme.palette.secondary.light,
	},
}))

const PrevButton = styled(IconButton)(({ theme }) => ({
	position: "fixed",
	top: "50%",
	left: r(15),
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.common.white,
	padding: r(5),
	fontSize: r(14),
	"&:hover": {
		backgroundColor: theme.palette.secondary.light,
	},
}))

const ImageViewer: React.FC<Props> = ({ src, showPrevButton, showNextButton, open, onClose, onNext, onPrev }) => (
	<Portal>
		<Container open={open}>
			<Image src={src} />

			{showNextButton && (
				<NextButton onClick={onNext}>
					<ArrowForwardIosIcon fontSize="inherit" />
				</NextButton>
			)}

			{showPrevButton && (
				<PrevButton onClick={onPrev}>
					<ArrowBackIosNewIcon fontSize="inherit" />
				</PrevButton>
			)}

			<CloseButton color="secondary" onClick={onClose}>
				<CloseOutlinedIcon fontSize="inherit" />
			</CloseButton>
		</Container>
	</Portal>
)

ImageViewer.defaultProps = {
	showNextButton: false,
	showPrevButton: false,
}

export default ImageViewer
