import { User } from "@model/types/user"
import { onSnapshot, serverTimestamp } from "firebase/firestore"
import AbstractService from "../AbstractService"
import { formatDocument } from "../utils"
import { UserUpdateActions, UserActionData, UpdateUserLogRequest } from "./request.types"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

type ValidationFunc = (user?: User) => boolean

class UserService extends AbstractService {
	constructor() {
		super("users")
	}

	getUserById = async (userId: string): Promise<User> => {
		const snapshot = await this.getById(userId)
		if (!snapshot.exists()) throw Error("User not exists")
		return { ...formatDocument(snapshot.data()), id: userId }
	}

	updateLog = <A extends UserUpdateActions, T extends UserActionData<A>>(userId: string, action: A, actionData: T) => {
		const request: UpdateUserLogRequest<A, T> = {
			...formatDocument(actionData),
			timestamp: serverTimestamp(),
			action,
		}
		if (IS_DEV_MODE) console.log(`ACTION [USER LOG]: ${request.action}`, request)
		return this.addToSubCollection(userId, "log", request)
	}

	waitForSessionUpdate = (userId: string, isValid: ValidationFunc = () => true) =>
		new Promise<User>((resolve, reject) => {
			const subscription = onSnapshot<User>(this.doc(userId), {
				next: async (snapshot) => {
					const res = snapshot.data()
					if (res && isValid(res)) {
						subscription()
						resolve({ ...formatDocument(res), id: userId })
					}
				},
				error: (err) => {
					subscription()
					reject(err)
				},
			})
		})

	subscribe = (userId: string, onNext: (user: User | null) => void, onError?: (err: unknown) => void) =>
		onSnapshot<User>(this.doc(userId), {
			next: async (snapshot) => {
				if (!snapshot.exists()) {
					onNext(null)
					return
				}
				onNext(formatDocument(snapshot.data()))
			},
			error: onError,
		})
}

export default UserService
