import { deleteObject, FirebaseStorage, ref, uploadBytesResumable } from "firebase/storage"
import { storage } from "../firebase"

class UserStorageService {
	collection = "users"

	storage: FirebaseStorage

	constructor() {
		this.storage = storage
	}

	getStorageRef = (userId: string) => ref(this.storage, `${this.collection}/${userId}`)

	getBillFolderPath = (userId: string, folderName: string) =>
		`${this.collection}/${userId}/uploads/utility-bills/${folderName}`

	getBillsStorageRef = (userId: string, folderName: string, fileName: string) =>
		ref(this.storage, `${this.getBillFolderPath(userId, folderName)}/${fileName}`)

	createUploadBillTask = (userId: string, folderName: string, file: File) =>
		uploadBytesResumable(this.getBillsStorageRef(userId, folderName, file.name), file)

	deleteFile = (userId: string, folderName: string, fileName: string) =>
		deleteObject(this.getBillsStorageRef(userId, folderName, fileName))
}

export default UserStorageService
