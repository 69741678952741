import React from "react"
import { FormikProvider, useFormik } from "formik"
import merge from "lodash/merge"

import Button from "@components/elements/Button/Button"
import Loader from "@components/elements/Loader/Loader"
import { ButtonProps } from "@mui/material/Button"
import Stack from "@components/fondation/Stack/Stack"
import Box from "@components/fondation/Box/Box"
import { signUpValidationScheme } from "@components/modules/forms/common/validations"
import FormTextField from "@components/modules/forms/common/FormTextField"
import { BaseSchema } from "yup"
import PhoneNumberTextField from "./auth/components/PhoneNumberTextField"
import { SignUpFormData } from "./common/types"

export const validationSchema: BaseSchema = signUpValidationScheme

export type FormData = SignUpFormData

const defaultInitialValues: FormData = { firstName: "", lastName: "", email: "", phone: "" }

export interface ContactInfoFormProps {
	initialValues?: FormData
	loading?: boolean
	children?: React.ReactNode
	submitButtonProps?: ButtonProps
	onSubmit: (form: FormData) => void
}

const AccountForm: React.FC<ContactInfoFormProps> = ({
	initialValues,
	loading,
	children,
	submitButtonProps = null,
	onSubmit,
}) => {
	// Override default values with the ones passed from props.
	const formikInitialValues = merge(defaultInitialValues, initialValues)

	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema,
		onSubmit: (form) => onSubmit(validationSchema.cast(form)),
	})

	return (
		<FormikProvider value={formik}>
			<Stack minWidth="100%" spacing={3} component="form" onSubmit={formik.handleSubmit}>
				{children != null && <Box>{children}</Box>}

				<FormTextField fullWidth name="firstName" label="First Name" />

				<FormTextField fullWidth name="lastName" label="Last Name" />

				<FormTextField fullWidth name="email" label="Email" />

				<PhoneNumberTextField fullWidth name="phone" label="Phone" />

				<Button
					fullWidth
					variant="contained"
					size="large"
					type="submit"
					disabled={loading}
					endIcon={loading && <Loader />}
					{...submitButtonProps}
				>
					{submitButtonProps?.children ?? "Save"}
				</Button>
			</Stack>
		</FormikProvider>
	)
}

export default AccountForm
