import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import SessionStore from "@store/auth/session.store"
import { useInstances } from "react-ioc"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import { observer } from "mobx-react-lite"
import Loader from "@components/elements/Loader/Loader"
import { validateMagicLinkParams } from "@model/utils/auth"
import ModalBox from "@components/elements/Modal/ModalBox"
import AuthStore from "@store/auth/auth.store"
import useQueryParams, { IUrlQueryParams } from "@components/prototypes/hooks/useQueryParams"
import { ErrorToast } from "@components/elements/Toast/Toast"
import { toast } from "react-toastify"
import { AppError, createError } from "@model/utils/error"

const errorNotification = (error: AppError) => toast(<ErrorToast title={error.title} description={error.message} />)

const MagicLinkPage = observer(() => {
	const [sessionStore, authStore] = useInstances(SessionStore, AuthStore)
	const navigate = useNavigate()

	const urlParams = useQueryParams()

	const { error } = sessionStore

	const signUp = async (email: string, link: string) => {
		const signUpError = await sessionStore.finalizeSignUp(email, link)
		if (signUpError) {
			errorNotification(signUpError)
			navigate("/enrollment/about/register", { replace: true })
			return
		}
		navigate("/enrollment/about/address", { replace: true })
	}

	const login = async (email: string, link: string) => {
		const loginError = await authStore.loginWithLink(email, link)
		if (loginError) {
			errorNotification(loginError)
			navigate("/login", { replace: true })
			return
		}
		navigate("/dashboard", { replace: true })
	}

	const handleMagicLink = async (params: Partial<IUrlQueryParams>) => {
		const magicParams = await validateMagicLinkParams(params)
		if (!magicParams) {
			errorNotification(createError("INVALID_MAGIC_LINK", "Oops!", "Invalid magic link"))
			navigate("/login", { replace: true })
			return
		}
		if (magicParams.signInMethod === "register") signUp(magicParams.email, window.location.href)
		if (magicParams.signInMethod === "login") login(magicParams.email, window.location.href)
	}

	useEffect(() => {
		handleMagicLink(urlParams)
	}, [])

	return (
		<ModalBox flexBasis="100%">
			{error ? (
				<Alert severity="error">
					<AlertTitle>{error.title}</AlertTitle>
					{error.message}
				</Alert>
			) : (
				<Loader size="large" center />
			)}
		</ModalBox>
	)
})

export default MagicLinkPage
