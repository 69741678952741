import React, { useEffect } from "react"
import { FormikProvider, useFormik } from "formik"
import merge from "lodash/merge"
import * as yup from "yup"

import Button from "@components/elements/Button/Button"
import Loader from "@components/elements/Loader/Loader"
import { ButtonProps } from "@mui/material/Button"
import Stack from "@components/fondation/Stack/Stack"
import Box from "@components/fondation/Box/Box"

import FormTextField from "@components/modules/forms/common/FormTextField"
import { signUpValidationScheme } from "@components/modules/forms/common/validations"
import { useTheme } from "@mui/system"
import PhoneNumberTextField from "./components/PhoneNumberTextField"
import { SignUpFormData } from "../common/types"

const defaultInitialValues: SignUpFormData = { firstName: "", lastName: "", email: "", phone: "" }

const validationSchema: yup.BaseSchema = signUpValidationScheme

export interface ContactInfoFormProps {
	initialValues?: Partial<SignUpFormData>
	loading?: boolean
	children?: React.ReactNode
	submitButtonProps?: ButtonProps
	onSubmit: (form: SignUpFormData) => void
}

const ContactInfoForm: React.FC<ContactInfoFormProps> = ({
	initialValues,
	loading,
	children,
	submitButtonProps = null,
	onSubmit,
}) => {
	const theme = useTheme()
	// Override default values with the ones passed from props.
	const formikInitialValues = merge(defaultInitialValues, initialValues)

	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema,
		onSubmit: (form) => onSubmit(validationSchema.cast(form)),
	})

	useEffect(() => {
		formik.validateForm()
	}, [])

	const submitButtonDisabled = !formik.isValid || loading

	return (
		<FormikProvider value={formik}>
			<Stack minWidth="100%" spacing={3} component="form" onSubmit={formik.handleSubmit}>
				{children != null && <Box>{children}</Box>}

				<FormTextField fullWidth name="firstName" label="First Name" />

				<FormTextField fullWidth name="lastName" label="Last Name" />

				<FormTextField fullWidth name="email" type="email" label="Email" />

				<PhoneNumberTextField fullWidth name="phone" label="Phone Number" />

				<Button
					fullWidth
					variant="contained"
					size="large"
					type="submit"
					sx={{ marginTop: `${theme.spacing(8)} !important` }}
					disabled={submitButtonDisabled}
					endIcon={loading && <Loader />}
					{...submitButtonProps}
				>
					{submitButtonProps?.children ?? "Submit"}
				</Button>
			</Stack>
		</FormikProvider>
	)
}

export default ContactInfoForm
