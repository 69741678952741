import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"

const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID || "arbor-prod"

export const firebaseApp = initializeApp({
	projectId,
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN || `${projectId}.firebaseapp.com`,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || `${projectId}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

export const firestore = getFirestore(firebaseApp)
export const cloudFunctions = getFunctions(firebaseApp)
export const storage = getStorage(firebaseApp)
