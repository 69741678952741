import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Stack from "@components/fondation/Stack/Stack"
import Box from "@components/fondation/Box/Box"
import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import Button from "@components/elements/Button/Button"
import RichListItem from "@components/elements/List/RichListItem"
import Loader from "@components/elements/Loader/Loader"
import { useInstances } from "react-ioc"
import SessionStore from "@store/auth/session.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"
import ProductStore from "@store/product/product.store"

const IMG_SRC = getImageUrl("globe_02.png")

const getOptions = (utilityName = "utility") => [
	{
		title: "We monitor the market",
		description: `We continuously scan the market for the best rates for ${utilityName} customers.`,
		imgSrc: getImageUrl("monitor.png"),
	},
	{
		title: "We help you select",
		description: "You can choose from our top picks or we can do it for you.",
		imgSrc: getImageUrl("compare.png"),
	},
	{
		title: "You save",
		description: `Start saving with no commitment or change to your ${utilityName} service.`,
		imgSrc: getImageUrl("save.png"),
	},
]

const GetStartedPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const [sessionStore, productStore] = useInstances(SessionStore, ProductStore)

	const handleSubmit = () => navigate("/enrollment/about", { replace: true })

	const loading = sessionStore.isLoading
	const canContinue = sessionStore.isAreaValid && !loading

	const options = getOptions(sessionStore.currentUtilityShortName)
	const foundRates = productStore.utilityRate?.rateSummary.supplierRateCount ?? 0

	const title = foundRates > 0 ? "Great news!" : "We're working on it."
	const message =
		foundRates > 0
			? `We found ${foundRates} available rates in your area.`
			: "Searching for available rates in your area."

	return (
		<ModalBox p={4} pt={8} height={1}>
			<Stack spacing={5} alignItems="stretch" direction="column" minHeight={1} flexShrink={0}>
				<Box alignSelf="center">
					<Image src={IMG_SRC} height={90} />
				</Box>

				<Box>
					<Typography variant="h3" textAlign="center" mb={3}>
						{title}
					</Typography>
					<Typography variant="h4" textAlign="center" mb={3}>
						{message}
					</Typography>
				</Box>
				{!productStore.utilityRate?.rateSummary ? (
					<Typography variant="h3" textAlign="center" mb={3}>
						<Loader size="large" center />
					</Typography>
				) : (
					<>
						<Box flexGrow={1}>
							<Typography variant="subtitle2" color="grey.700" fontWeight={700} marginX={2} mb={1}>
								Here&lsquo;s how it works.
							</Typography>
							{options.map((item) => (
								<RichListItem {...item} key={item.title} />
							))}
						</Box>

						<Box>
							<Button
								variant="contained"
								fullWidth
								size="large"
								disabled={!canContinue}
								endIcon={loading && <Loader />}
								onClick={handleSubmit}
							>
								HELP ME SAVE
							</Button>
						</Box>
					</>
				)}
			</Stack>
		</ModalBox>
	)
})

export default GetStartedPage
