import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import DashboardLayout from "@components/modules/layouts/DashboardLayout"
import UserRate from "@components/prototypes/dashboard/UserRate"
import UtilityAccountStore from "@store/auth/utility-account.store"
import Product from "@components/prototypes/dashboard/Product"
import AppProvider from "@root/AppProvider"
import ProductStore from "@store/product/product.store"
import RateOffer from "@store/product/RateOffer"
import RateContract from "@store/product/RateContract"

AppProvider.register(ProductStore, RateOffer, RateContract)

const DashboardPage: React.FC = observer(() => {
	const { service } = useInstance(UtilityAccountStore)

	const isContractReady =
		service?.status == null ||
		service?.status === "confirmed" ||
		service?.status === "submitted" ||
		service?.status === "active" ||
		service?.status === "ineligible" ||
		service?.status === "rejected" ||
		service?.status === "dropped"

	return <DashboardLayout>{isContractReady ? <UserRate /> : <Product />}</DashboardLayout>
})

export default DashboardPage
