import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import Stack from "@components/fondation/Stack/Stack"
import Button from "@components/elements/Button/Button"
import MarkedItem from "@components/elements/List/MarkedItem"
import { useNavigate } from "react-router-dom"

interface CallToLinkAccountProps {
	title: string
	points: string[]
}

export const CallToLinkAccount: React.FC<CallToLinkAccountProps> = React.memo(({ title, points }) => {
	const navigate = useNavigate()

	const onLink = () => navigate("/enrollment/provider/connect")

	return (
		<Stack spacing={3}>
			<Typography variant="h4">{title}</Typography>

			<Stack>
				{points.map((item) => (
					<MarkedItem key={item}>{item}</MarkedItem>
				))}
			</Stack>

			<Button variant="contained" color="primary" onClick={onLink}>
				Link Account
			</Button>
		</Stack>
	)
})

export default CallToLinkAccount
