import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/fondation/Box/Box"
import CollapsibleCard from "@components/elements/Card/CollapsibleCard"
import Stack from "@components/fondation/Stack/Stack"
import SwitchTabs from "@components/elements/Tabs/SwitchTabs"
import Tab from "@components/elements/Tabs/Tab"
import SubPageLayout from "@pages/account/AccountSectionLayout"
import capitalize from "lodash/capitalize"
import Markdown from "@components/elements/Markdown/Markdown"
import { navLinkRenderer } from "@components/elements/Markdown/utils"
import { FAQOption } from "@model/types/support"
import { groups, GroupType, useFAQList } from "@model/constants/support/FAQ"
import Typography from "@components/fondation/Typography/Typography"

type FAQOptionType = FAQOption<GroupType>
type FAQMapType = Record<GroupType, FAQOption<GroupType>[]>

const AccountFaqsPage: React.FC = observer(() => {
	const [tabIdx, setTabIdx] = useState(0)

	const handleChange = (_: unknown, newValue: number) => {
		setTabIdx(newValue)
	}

	const faqMap = useMemo(() => {
		const initialMap = Object.fromEntries<FAQOptionType[]>(groups.map((tabName) => [tabName, []])) as FAQMapType

		const faqList = useFAQList()

		return faqList.reduce<FAQMapType>((acc, item) => {
			item.group.forEach((key) => {
				if (acc[key] != null) acc[key].push(item)
			})
			return acc
		}, initialMap)
	}, [])

	const currentTabName = groups[tabIdx]
	const list = faqMap[currentTabName]

	return (
		<SubPageLayout label="FAQs">
			<Box mb={4}>
				<SwitchTabs value={tabIdx} onChange={handleChange}>
					{groups.map((tabName) => (
						<Tab label={capitalize(tabName)} key={tabName} />
					))}
				</SwitchTabs>
			</Box>

			<Stack spacing={4}>
				{list.length ? (
					list.map(({ question, answer }) => (
						<CollapsibleCard title={question} key={`${question}_${currentTabName}`}>
							<Markdown renderLink={navLinkRenderer}>{answer}</Markdown>
						</CollapsibleCard>
					))
				) : (
					<Typography color="grey.500" marginX="auto">
						No questions found
					</Typography>
				)}
			</Stack>
		</SubPageLayout>
	)
})

export default AccountFaqsPage
