import React from "react"
import { PatternFormat } from "react-number-format"
import { PatternFormatProps } from "react-number-format/types/types"
import { TextFieldProps } from "@mui/material/TextField"

import FormTextField from "@components/modules/forms/common/FormTextField"

const MaskedTextField = React.forwardRef((props: TextFieldProps & PatternFormatProps, ref: React.Ref<unknown>) => (
	<PatternFormat {...props} format="(###) ###-####" mask="_" getInputRef={ref} />
))

const PhoneNumberTextField: React.FC<TextFieldProps> = ({ label, name }) => (
	<FormTextField
		label={label}
		name={name as never}
		type="tel"
		InputProps={{
			/* eslint-disable @typescript-eslint/no-explicit-any */
			inputComponent: MaskedTextField as any,
		}}
	/>
)

export default PhoneNumberTextField
