import { FAQOption } from "@model/types/support"
import { ServiceStatus } from "@model/types/user"
import { useInstance } from "react-ioc"
import UserStore from "@store/auth/user.store"

export const groups = ["About Arbor", "Autopilot", "Electricity Rates 101"] as const

export type GroupType = typeof groups[number]

export const useFAQList = (): FAQOption<GroupType, ServiceStatus>[] => {
	const userStore = useInstance(UserStore) ?? null
	const referralLink = `https://app.joinarbor.com?affiliate=customer_referral&id=${userStore.user?.email}`

	return [
		{
			answer:
				"Arbor is a smart energy platform that makes it safe and easy to lower your bill and your impact on the environment. Think of us as your digital energy advisor. By analyzing your energy usage and costs, we can help you find the right energy products and services for you.",
			question: "What is Arbor?",
			status: [
				"unlinked",
				"pending",
				"incomplete",
				"searching",
				"user-approving",
				"review",
				"submitted",
				"confirmed",
				"active",
				"ineligible",
				"rejected",
				"dropped",
			],
			group: ["About Arbor"],
			page: "Intro",
		},
		{
			answer:
				"If you switch to a new energy rate through us, one of our energy supply partners gets a new customer, and pays us a referral fee from their marketing budget. Because we connect suppliers with so many customers at a low cost, they can pass on those savings to you as lower rates. We never sell your data, and we only get paid when you choose to switch to a new rate. Our energy supply partners pay us so you don't have to.",
			question: "How does Arbor make money?",
			status: [],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"Arbor only uses your data to help you save on energy. We do not sell your data and we only share it with your permission.",
			question: "What does Arbor do with my data?",
			status: [],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"Linking your utility account is a secure and convenient way to share your energy data with us, so that we can analyze how much you are paying for energy, how much energy you use, and where your energy comes from. If you link your utility account, we can alert you when we find a better rate. We can also share personalized insights and recommendations on more ways to save.",
			question: "Why should I link my utility account?",
			status: ["unlinked"],
			group: ["About Arbor"],
			page: "Account linking page",
		},
		{
			answer:
				"Never. Updating your rate in competitive electricity markets is seamless. You get the same reliable electricity, pay the same utility bill, all with a better rate. No equipment or installation required.",
			question: "Will I have to get a new meter or buy any new equipment?",
			status: [],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"When you sign up for a new rate from a competitive supplier, you just keep paying your local utility. They continue to manage your bill, and deliver the same reliable electricity to your home. You'll just see the lower rate and the name of your competitive supplier on your bill.",
			question: "Who will I pay my bills to if I enroll in a new rate?",
			status: [],
			group: ["About Arbor"],
			page: "Checkout page",
		},
		{
			answer:
				"We offer reimbursement on early cancellation fees. If you sign up for a rate through Arbor and cancel for any reason before the end of the term, we'll reimburse you for any early termination fee you pay. All you'd need to do is send us your bill showing you paid a fee and we'll reimburse the full amount.",
			question: "Will there ever be an early termination fee for my new rate?",
			status: [],
			group: ["About Arbor"],
			page: "Checkout page",
		},
		{
			answer:
				"Once a new rate is submitted, you'll receive a confirmation email with the details of your rate (price/plan duration/supplier). From there, the new rate will be on your bill in the next 1 -2 billing cycles. The switch time varies for each utility company. When you switch to a new rate, your utility stays the same. There's no interruption in your service, and you'll get your bill only from Utility, you'll just see the supplier rate in the supply section of the bill.",
			question: "What happens when I sign up for a new rate?",
			status: ["user-approving", "unlinked", "pending", "searching"],
			group: ["About Arbor"],
			page: "Checkout page",
		},
		{
			answer:
				"If you were previously on your utility's default rate, you can switch back at anytime. If you were previously on a competitive supply rate, you may not be able to switch back to the same rate because suppliers change their rates all the time, but you will be able to switch to any competitive rate available at that time.",
			question: "Can I switch back to my old rate?",
			status: [],
			group: ["About Arbor"],
			page: "Checkout page",
		},
		{
			answer:
				"When you turn on Autopilot, we'll monitor the market for a better rate. If you set Autopilot to lower your bill, a rate is considered better when it is lower than the rate on the most recent bill you shared with us or if it is lower than your forecasted rate for the next month. If you set Autopilot to prioritize renewable energy, a rate is better if it is renewable and you are currently not on a renewable plan.",
			question: "How does Arbor decide if a rate is better than my current rate?",
			status: ["searching", "unlinked", "pending", "incomplete", "user-approving", "review"],
			group: ["About Arbor"],
			page: "Dashboard",
		},
		{
			answer:
				"We do not currently have any rates available from alternative suppliers in your area. Arbor continuously is looking for new rates so when one does show up we will let you know.",
			question: "What does it mean when there are no rates available?",
			status: [],
			group: ["About Arbor"],
			page: "Dashboard when no rates",
		},
		{
			answer:
				"You first create an online account with the utility company in your area by visiting their website. If you encounter any difficulties, don't hesitate to reach out to [support](/account/support). We will be happy to assist you with the account creation process. Alternatively, you can upload a bill or manually enter your billing information.",
			question: "I don't have my username and password for my utility how can I find that?",
			status: [],
			group: ["About Arbor"],
			page: "On the do you have an online account page",
		},
		{
			answer:
				'Check the "supply" or "generation" section of your bill to see which company is listed as your supplier. If you have not switched before, your supplier will typically be your utility, so you can answer "yes" to this question. ',
			question: "What does this mean: Is *utility company name* listed as your Supplier?",
			status: [],
			group: ["About Arbor"],
			page: "Manual Account linking page",
		},
		{
			answer:
				"Utility account linking can fail for a number of reasons. The most common reason is that your login credentials were invalid. Other reasons include the utility's website being down, or security features on your account like multi-factor authentication. If utility linking fails more than once, we recommend you simply upload a bill or manually enter your account information. If you still have questions reach out to [support](/account/support).",
			question: "Why is my account not linking?",
			status: ["unlinked", "pending", "incomplete"],
			group: ["About Arbor"],
			page: "Dashboard (unlinked)",
		},
		{
			answer:
				"You can link your account by clicking [here](/enrollment/provider/connect) and following the directions. If you have any questions please contact [support](/account/support).",
			question: "How can I link my account?",
			status: ["unlinked", "pending", "incomplete"],
			group: [],
			page: "Dashboard (unlinked)",
		},
		{
			answer: `Yes, here is your referral link: [${referralLink}](${referralLink}) When you use this unique link, we will reward you and the person you refer. Contact [support](/account/support) for details, restrictions apply.`,
			question: "Can I refer someone?",
			status: [
				"unlinked",
				"pending",
				"incomplete",
				"searching",
				"user-approving",
				"review",
				"submitted",
				"confirmed",
				"active",
			],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"Typically when you move, you can transfer the rate to your new address if you stay within the same utility company territory (i.e. UTILITY). If you move out of UTILITY, then you cannot transfer, so you would terminate the rate. But when you move, typically suppliers cannot impose early termination fees by law, and where they can, they typically do not seek to enforce them. All that being said, we do provide a backstop guarantee such that if you do indeed have to pay a fee, we will reimburse you.",
			question: "What happens if I move?",
			status: [],
			group: ["About Arbor"],
			page: "Checkout",
		},
		{
			answer:
				"No, we are your energy advisor. We compare rates from competitive suppliers to find better rates for our users. When you sign up and link your utility account, we will see if a better competitive supply rate is available than what you currently pay. If your electric supply rate is better than competitive rates available, we'll continue to monitor the market and let you know when we've found a better rate.",
			question: "Is Arbor a supplier?",
			status: [],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"No! With Arbor there are no hidden fees. We aim to keep everything as transparent and easy to understand as possible.",
			question: "Are there any hidden fees?",
			status: ["user-approving", "unlinked", "pending", "searching"],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"If you have activated Autopilot, Arbor will automatically switch you to the best rate available from our energy supply marketplace. In the event that you have not chosen this option, we will inform you of your contract's impending expiration and provide recommendations for alternative plans for you to consider.",
			question: "What happens when the contract is coming to the end of its rate term?",
			status: [],
			group: ["About Arbor", "Autopilot"],
			page: "Checkout",
		},
		{
			answer:
				"Typically a fixed rate requires a contract for 12-36 months but you are not locked in. If you need to switch plans or we find a better deal we will switch you and cover the early termination fee.",
			question: 'Why do rates have a "term"? Am I locked into a contract?',
			status: [],
			group: ["About Arbor"],
			page: "Checkout page / dashboard?",
		},
		{
			answer:
				"Activate our free Autopilot service and we'll monitor the energy markets for a better rate than your current plan. When we find one, we'll reserve it for you and notify you so you can review the details. If you don't opt-out, you'll get your new rate on your next utility bill. There are no hidden fees, and there is no change or interruption to your reliable utility service. If you'd rather be more hands on, leave Autopilot off, we'll still notify you when we find a better rate, but we won't request the new rate until you approve it.",
			question: "What is Autopilot?",
			status: [],
			group: ["Autopilot"],
			page: "Autopilot / dashboard",
		},
		{
			answer:
				"At Arbor, we believe in providing our customers with transparency and choice. If you turn off Autopilot, instead of automatically reserving a better rate when we find one, we will just notify you that a better rate is available. As the market for rates is ever-changing, it is advisable to act promptly upon receiving information about a rate that you deem favorable. We will be here to help you every step of the way.",
			question: "What happens if I opt-out of Autopilot?",
			status: [],
			group: ["Autopilot"],
			page: "",
		},
		{
			answer:
				"Yes, so long as you live in an area with competitive electricity markets, including CT, DE, IL, MA, MD, ME, NH, NJ, NY, OH, PA, RI, TX, and Washington DC. Within those territories, some areas are served by utilities that do not allow competitive energy rates, so check with Arbor today to see if you qualify.",
			question: "Can I really switch to a different energy rate?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "Landing Page/intro page",
		},
		{
			answer:
				'Utilities operate the electrical grid (the "poles and wires" company), which distributes electricity from power plants, over transmission lines, to your home. In most places, the utility is the default supplier, but in competitive electricity\'s markets you can choose to buy electricity from a competitive supplier. You still receive the same reliable electricity and the same monthly bill from your utility, but with a different rate from the competitive supplier. ',
			question: "What's a utility?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer:
				"A supplier purchases or generates electricity and then sells it to end users through the local utility grid, and on the local utility bill. Suppliers are active in competitive electricity markets, where companies compete to offer electricity, just like cable or internet companies compete for your business.",
			question: "What's a supplier?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer:
				"Arbor only offers fixed rate rates, usually with a term of 12 months or longer. Fixed rate rates provide price protection and certainty, with a guaranteed rate for a period of time. Variable rates, on the other hand, fluctuate month-to-month, exposing you to unexpected price hikes. Most default utility rates and competitive supplier rates are variable rates.",
			question: "What's the difference between fixed rate & variable rate rates?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer:
				'You can find your current electricity rate on your utility bill, under the "Supply" or "Generation" section. Most people are on the default utility service, and for your convenience we assume that is your current price. But if you have switched to a competitive supplier, your price may be different. When you link your utility account, we automatically calculate and monitor your rate each month.',
			question: "How can I find my current electricity supply rate?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer: "Lowest price plan means we will find you the lowest price available, regardless of its source.",
			question: "What does the lowest price plan mean?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "Enrollment preference selection page",
		},
		{
			answer: "No, you as the user do not have to do anything. Arbor will take care of everything for you.",
			question: "Do I need to change my supplier?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer:
				"If you have questions about Arbor's service get in touch wih [support](/account/support). If you have questions about your electricity service generally, you should contact your utility. If you have questions about your new rate after you have been enrolled through Arbor, you should contact your new supplier directly. We will give you their contact information once you are enrolled.",
			question: "Who do I call if I have problems with my service?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "",
		},
		{
			answer:
				"100% renewable means that all of the energy you use is associated with the generation of energy at renewable energy facilities like wind and solar farms.",
			question: "What does 100% renewable energy mean?",
			status: [],
			group: ["Electricity Rates 101"],
			page: "Enrollment preference selection page",
		},
		{
			answer: "No, you will not lose power at any point and will continue paying the same utility bill.",
			question: "Will I lose power?",
			status: [],
			group: ["About Arbor"],
			page: "",
		},
		{
			answer:
				"You can [link](/enrollment/provider/connect) your account. Linking your utility account allows us to see your current rate so we can compare your rate to the rest of the market and make sure you have the best possible rate.",
			question: "What can I do now?",
			status: ["unlinked"],
			group: [],
			page: "",
		},
		{
			answer:
				"We are currently processing your data so that we can help you make the most informed decision. You can still see available rates, and can [switch now](/switch) if you find one you like.",
			question: "What can I do now?",
			status: ["pending"],
			group: [],
			page: "",
		},
		{
			answer:
				"We need some more information from you to proceed, reach out to [support](/account/support) if they haven't contacted you yet.",
			question: "What can I do now?",
			status: ["incomplete"],
			group: [],
			page: "",
		},
		{
			answer:
				"We are monitoring the market to find you a better plan - we check daily! If you want to lock in this rate, you can [switch now](/switch). If you have any questions or concerns contact [support](/account/support).",
			question: "What can I do now?",
			status: ["searching"],
			group: [],
			page: "",
		},
		{
			answer: "We found you a better and all you need to do is approve it! [Switch now](/switch) to get started.",
			question: "What can I do now?",
			status: ["user-approving"],
			group: [],
			page: "",
		},
		{
			answer:
				"Nothing! We are in the middle of switching you to a new rate. You will not lose power at any point and will continue paying the same utility bill. If you have any questions please contact [support](/account/support).",
			question: "What can I do now?",
			status: ["review", "submitted"],
			group: [],
			page: "",
		},
		{
			answer:
				"Nothing! Your new plan has been approved by the supplier, the new rate will become active within 2 months. You will not lose power at any point and will continue paying the same utility bill. If you have any questions please contact [support](/account/support).",
			question: "What can I do now?",
			status: ["confirmed"],
			group: [],
			page: "",
		},
		{
			answer: "Nothing! Your new rate is active! If you have any questions please contact [support](/account/support).",
			question: "What can I do now?",
			status: ["active"],
			group: [],
			page: "",
		},
		{
			answer:
				"It looks like something went wrong! If you have any questions please contact [support](/account/support).",
			question: "What can I do now?",
			status: ["ineligible", "rejected", "dropped"],
			group: [],
			page: "",
		},
	]
}
