import React, { useEffect, useState, useMemo } from "react"
import TagManager, { TagManagerArgs } from "react-gtm-module"

interface TagsProps {
	children?: React.ReactNode
}

type CustomTagManager = {
	active: boolean
} & TagManagerArgs

const Tags: React.FC<TagsProps> = ({ children }) => {
	const [childrenLocal, setChildrenLocal] = useState<React.ReactNode>()
	const tags = useMemo(() => [{ active: true, gtmId: process.env.REACT_APP_TAG_MANAGER_ID }] as CustomTagManager[], [])
	useEffect(() => {
		if (tags) {
			const activeTags = tags.filter((tag) => !!tag.active && !!tag.gtmId)
			activeTags.forEach((tag) => TagManager.initialize(tag))
		}
		setChildrenLocal(children)
	}, [children, tags])

	return <>{childrenLocal}</>
}

export default Tags
