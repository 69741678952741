import { useEffect } from "react"
import FacebookPixel from "react-facebook-pixel"

const useFacebookPixel = (): void => {
	useEffect(() => {
		if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
			FacebookPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
		}
	}, [])
}
export default useFacebookPixel
