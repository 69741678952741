import React from "react"
import { observer } from "mobx-react-lite"

import Header from "@components/elements/HeaderBar/Header"
import Image from "@components/elements/Image/Image"
import { useNavigate } from "react-router-dom"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"

const MAIN_LOGO = "/assets/images/main_logo.svg"

export interface MainHeaderProps {
	before?: React.ReactNode
	after?: React.ReactNode
}

const MainHeader: React.FC<MainHeaderProps> = observer((props) => {
	const navigate = useNavigate()

	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const handleHomeClick = () => navigate("/dashboard")

	return <Header {...props}>{isMobile ? null : <Image src={MAIN_LOGO} onClick={handleHomeClick} />}</Header>
})

export default MainHeader
