import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom"
import DoneIcon from "@mui/icons-material/Done"
import { useInstances } from "react-ioc"

import Stepper from "@components/elements/TabStepper/Stepper"
import Step from "@components/elements/TabStepper/Step"
import StepLabel from "@components/elements/TabStepper/StepLabel"
import SessionStore from "@store/auth/session.store"
import UserStore from "@store/auth/user.store"
import AuthModalHeader from "@components/prototypes/Header/AuthModalHeader"
import ModalBox from "@components/elements/Modal/ModalBox"
import Box from "@components/fondation/Box/Box"
import useScrollPosition from "@components/prototypes/hooks/useScrollPosition"
import AppBar from "@components/elements/HeaderBar/AppBar"
import LinkUtilityStore from "@store/auth/link-utility.store"

const steps = [
	{ name: "about", label: "About You" },
	{ name: "preferences", label: "Preferences" },
	{ name: "provider", label: "Savings Options" },
]

const EnrollmentOutlet: React.FC = observer(() => {
	const location = useLocation()
	const navigate = useNavigate()
	const [sessionStore, linkStore, userStore] = useInstances(SessionStore, LinkUtilityStore, UserStore)

	const scrollRef = useRef()
	const isScrolled = useScrollPosition({ target: scrollRef.current }) !== 0

	const activeStep = steps.findIndex(({ name }) => matchPath(`/enrollment/${name}/*`, location.pathname))
	const hideBackButton = !!matchPath("/enrollment/about/address", location.pathname)
	const hideEndButton = userStore?.user?.property?.class === "commercial"

	const handleBack = () => {
		navigate(location.state?.backPath ?? -1)
	}

	const handleClose = () => {
		linkStore.skipLinking()
	}

	useEffect(() => {
		if (!sessionStore.isLoading && !sessionStore.isUtilityValid) {
			navigate("/utilities", { replace: true })
		}
	}, [sessionStore.isLoading])

	return (
		<ModalBox overflow="hidden" flexBasis="100%">
			<AppBar position="relative" color={isScrolled ? "primary" : "secondary"}>
				<AuthModalHeader
					onBack={handleBack}
					onClose={handleClose}
					hideBackButton={hideBackButton}
					hideEndButton={hideEndButton}
				/>

				<Box mt={4} mb={2} paddingX={4}>
					<Stepper activeStep={activeStep}>
						{steps.map(({ name, label }) => (
							<Step key={name}>
								<StepLabel StepIconComponent={DoneIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
			</AppBar>

			<ModalBox flexBasis="100%" padding={4} pt={5} ref={scrollRef}>
				<Outlet />
			</ModalBox>
		</ModalBox>
	)
})

export default EnrollmentOutlet
