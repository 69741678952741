import { inject } from "react-ioc"
import { autorun, makeAutoObservable, toJS } from "mobx"

import { AppError, createError } from "@model/utils/error"
import UserService from "@services/firebase/user.service"
import UtilityService from "@services/firebase/utility.service"
import { Utility } from "@model/types/utilities"
import { ServiceUtility } from "@model/types/user"
import { getUtilityLogo, getUtilityShortName } from "@model/utils/utilities"
import UserStore from "./user.store"
import SessionStore from "./session.store"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

class UtilityStore {
	// injections

	userService = inject(this, UserService)

	userStore = inject(this, UserStore)

	sessionStore = inject(this, SessionStore)

	utilitiesService = inject(this, UtilityService)

	// constructors

	constructor() {
		makeAutoObservable(this)

		autorun(() => {
			if (!this.linkedUtilityId && !this.isLoading) {
				this.utility = null
				this.error = null
				return
			}

			if (this.isLoading || this.error) return

			if (
				(this.userStore.userId || this.linkedUtilityId) &&
				(this.utility == null || this.linkedUtilityId !== this.utility.id)
			) {
				this.loadUtility()
			}
		})

		if (IS_DEV_MODE)
			autorun(() => {
				console.log("User Utility", toJS(this.utility))
			})
	}

	// attributes

	utility: Utility | null = null

	error: AppError | null = null

	isLoading = false

	// computed

	get linkedUtility(): ServiceUtility | undefined {
		return this.userStore.user?.services?.electric?.utility ?? this.sessionStore.session?.services?.electric?.utility
	}

	get linkedUtilityId(): string | undefined {
		return this.linkedUtility?.id
	}

	get utilityShortName(): string | undefined {
		return getUtilityShortName(this.utility)
	}

	get utilityLogo(): string | undefined {
		return getUtilityLogo(this.utility)
	}

	// actions

	loadUtility = () => {
		this.utility = null
		if (this.userStore.userId) {
			this.loadUtilityByUserId(this.userStore.userId)
		} else if (this.linkedUtilityId) {
			this.loadByUtilityId(this.linkedUtilityId)
		}
	}

	loadByUtilityId = async (utilityId: string) => {
		try {
			this.isLoading = true
			this.error = null

			const utility = await this.utilitiesService.getByUtilityId(utilityId)

			if (utility != null) {
				this.utility = utility
			} else {
				throw new Error("Failed to load")
			}
		} catch (error) {
			this.error = createError()
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	loadUtilityByUserId = async (userId: string) => {
		try {
			this.isLoading = true
			this.error = null

			const response = await this.utilitiesService.getByUserId(userId)
			if (response.data.ok) {
				this.utility = response.data.utilityRate
			} else {
				throw new Error("Failed to load")
			}
		} catch (error) {
			this.error = createError()
		} finally {
			this.isLoading = false
		}
		return this.error
	}
}

export default UtilityStore
