import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Box from "@components/fondation/Box/Box"

export interface SequenceContainerProps {
	orientation?: "vertical" | "horizontal"
}

const SequenceContainer = styled(Box)<SequenceContainerProps>(({ theme, orientation }) => ({
	display: "flex",
	flexDirection: orientation === "horizontal" ? "row" : "column",
	alignItems: "stretch",
	gap: r(20),
	position: "relative",
	zIndex: 0,

	".SequenceCard-root": {
		flexDirection: orientation === "horizontal" ? "column" : "row",
	},

	"&:before": {
		content: "''",
		position: "absolute",
		zIndex: -1,
		backgroundColor: theme.palette.grey[150],
		...(orientation === "horizontal"
			? {
					top: r(31),
					left: r(32),
					right: r(32),
					height: r(3),
			  }
			: {
					left: r(31),
					top: r(32),
					bottom: r(32),
					width: r(3),
			  }),
	},
}))

SequenceContainer.defaultProps = {
	orientation: "horizontal",
}

export default SequenceContainer
