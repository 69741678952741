import React, { MutableRefObject } from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import ModalDrawer from "@components/elements/Drawer/ModalDrawer"
import IconButton from "@components/elements/Button/IconButton"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import Box from "@components/fondation/Box/Box"
import TermsOfService from "@components/prototypes/TermAndPolicy/TermsOfService"
import TermsAndPolicyStore from "@store/app/terms-policy.store"
import Typography from "@components/fondation/Typography/Typography"
import AppProvider from "@root/AppProvider"
import PrivacyPolicy from "./PrivacyPolicy"

export interface DrawerContentProps {
	container?: MutableRefObject<Element | null>
}

AppProvider.register(TermsAndPolicyStore)

export const TermAndPolicyDrawer: React.FC<DrawerContentProps> = observer(({ container }) => {
	const { opened, isOpened, hide } = useInstance(TermsAndPolicyStore)

	return (
		<ModalDrawer open={isOpened} anchor="bottom" disablePortal container={container?.current}>
			<Box display="flex" flexDirection="column" overflow="hidden">
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h3">Legal</Typography>
					<IconButton onClick={hide}>
						<CloseOutlinedIcon />
					</IconButton>
				</Box>

				<Box overflow="auto" flex="1 1 100%">
					{opened === "terms" && <TermsOfService />}
					{opened === "policy" && <PrivacyPolicy />}
				</Box>
			</Box>
		</ModalDrawer>
	)
})

export default TermAndPolicyDrawer
