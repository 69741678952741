import React from "react"
import MUIStepper, { StepperProps as MUIStepperProps } from "@mui/material/Stepper"
import styled from "@mui/material/styles/styled"

import { r } from "@theme/utils"

const SimpleConnector = styled("div")(() => ({
	flex: "1 1",
	minWidth: r(12),
	height: "100%",
}))

interface StepLabelProps extends MUIStepperProps {}

const Stepper: React.FC<StepLabelProps> = (props) => <MUIStepper {...props} connector={<SimpleConnector />} />

export default Stepper
