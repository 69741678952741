import React, { useEffect, useRef, useState } from "react"

import { Line } from "react-chartjs-2"
import {
	Chart as ChartJS,
	Tooltip,
	Plugin,
	PointElement,
	CategoryScale,
	LinearScale,
	LineElement,
	ChartData,
	Filler,
	TimeScale,
	ChartOptions,
} from "chart.js"
import { NULL } from "../utils"
import "chartjs-adapter-date-fns"

ChartJS.register(LinearScale, TimeScale, CategoryScale, PointElement, LineElement, Tooltip, Filler)

export type AreaChartProps = {
	className?: string
	data: ChartData<"line">
	options?: ChartOptions<"line">
	plugins?: Plugin<"line">[]
}

const LineChart: React.FC<AreaChartProps> = React.memo(({ data, options, plugins }) => {
	const chartRef = useRef<ChartJS<"line">>(null)

	const [chartData, setChartData] = useState<ChartData<"line">>({
		datasets: [],
	})

	useEffect(() => {
		setChartData(data)
	}, [plugins, data])

	return <Line plugins={plugins} ref={chartRef} data={chartData} options={options} height={NULL} width={NULL} />
})

export default LineChart
