import React from "react"
import { observer } from "mobx-react-lite"
import { Outlet, RouteProps } from "react-router-dom"
import { useInstance } from "react-ioc"

import SessionStore from "@store/auth/session.store"
import LoadingLayout from "../layouts/LoadingOutlet"

const SessionOutlet: React.FC<RouteProps> = observer(() => {
	const sessionStore = useInstance(SessionStore)

	const isLoading = !sessionStore.isInit && !sessionStore.error
	const error = !sessionStore.isInit ? sessionStore.error : null

	return (
		<LoadingLayout loading={isLoading} error={error}>
			<Outlet />
		</LoadingLayout>
	)
})

export default SessionOutlet
