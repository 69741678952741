import React from "react"
import { observer } from "mobx-react-lite"

import FileUploadCard from "@components/elements/FileUpload/FileUpload"
import UploadableBill from "@store/UploadableBill"

export interface Props {
	data: UploadableBill
	onDelete?: (id: string) => void
}

const BillFile: React.FC<Props> = observer(({ data, onDelete }) => {
	const handleDelete = () => {
		onDelete?.(data.id)
	}

	return (
		<FileUploadCard
			fileName={data.file.name}
			progress={data.progress}
			size={data.file.size}
			error={data.error?.title}
			isCompleted={data.status === "success"}
			onDeleteClick={handleDelete}
		/>
	)
})

export default BillFile
