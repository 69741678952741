import React from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import UtilityAccountStore from "@store/auth/utility-account.store"
import ContentHeader from "@components/elements/Card/RatePlanCard/Header/ContentHeader"
import UtilityStore from "@store/auth/utilities.store"
import ImageHeader from "@components/elements/Card/RatePlanCard/Header/ImageHeader"
import { SUPPORT_ROUTE } from "@model/constants/utilities/app"
import Markdown from "@components/elements/Markdown/Markdown"
import { navLinkRenderer } from "@components/elements/Markdown/utils"

export const NewPlanHeader = observer(() => {
	const [utilityAccount, utilityStore] = useInstances(UtilityAccountStore, UtilityStore)
	const status = utilityAccount.service?.status
	const utilityName = utilityStore.utilityShortName ?? "utility"
	const isRenewable = utilityAccount.service?.renewable === "very-important"

	if (status === "confirmed")
		return (
			<ContentHeader status="CONFIRMED">
				<Markdown renderLink={navLinkRenderer}>
					{`Your new plan is confirmed and will appear on your ${utilityName} bill within 1-2 billing cycles. If you have any questions, just reach out to [support](${SUPPORT_ROUTE}).`}
				</Markdown>
			</ContentHeader>
		)

	if (status === "submitted")
		return (
			<ContentHeader status="NEW_RESERVED">
				<Markdown renderLink={navLinkRenderer}>
					{isRenewable
						? `We found you a 100% renewable rate and reserved it on your behalf. If you want to opt out, just reach out to [support](${SUPPORT_ROUTE}). Otherwise, we will notify you as soon as ${utilityName} confirms the request, usually within 2 weeks.`
						: `We found you a better rate and reserved it on your behalf. If you want to opt out, just reach out to [support](${SUPPORT_ROUTE}). Otherwise, we will notify you as soon as ${utilityName} confirms the request, usually within 2 weeks.`}
				</Markdown>
			</ContentHeader>
		)

	if (status === "rejected" || status === "ineligible")
		return (
			<ContentHeader status="REJECTED">
				<Markdown renderLink={navLinkRenderer}>
					{`The provider rejected your rate request and we are working to fix it. We will notify you if we need any further information. Don't worry your power will not be interrupted. Please reach out to [support](${SUPPORT_ROUTE}) with any question.`}
				</Markdown>
			</ContentHeader>
		)

	return <ImageHeader status="CURRENT" />
})

export default NewPlanHeader
