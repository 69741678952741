import { parseUTC } from "@root/lib/date"
import { BaseRate, UpdateService } from "@model/types/user"
import startOfMonth from "date-fns/startOfMonth"
import getUnixTime from "date-fns/getUnixTime"

export const updatesToBaseRateMap = (list: UpdateService[], getKey = getUnixTime): Record<string, BaseRate> =>
	list.reduce<Record<string, BaseRate>>((acc, item) => {
		if (item.endDate != null && item.active) {
			const rate = item.userRate
			const date = startOfMonth(parseUTC(item.endDate))
			const key = getKey(date)

			acc[key] = {
				rate,
				effectiveDate: date,
			}
		}
		return acc
	}, {})

export const baseRatesToBaseRateMap = (list: BaseRate[], getKey = getUnixTime): Record<string, BaseRate> =>
	list.reduce<Record<string, BaseRate>>((acc, item) => {
		const { rate, effectiveDate } = item
		if (item.effectiveDate != null) {
			const date = startOfMonth(parseUTC(effectiveDate))
			const key = getKey(date)

			acc[key] = {
				rate,
				effectiveDate: date,
			}
		}

		return acc
	}, {})

export const rateMapToBaseRates = (
	labels: Date[],
	rateMap: Record<string, BaseRate>,
	getKey = getUnixTime,
): BaseRate[] =>
	labels.map((date) => {
		const effectiveDate = startOfMonth(date)
		const xKey = getKey(effectiveDate)

		return (
			rateMap[xKey] ?? {
				rate: null,
				effectiveDate,
			}
		)
	})

export const getBaseRateToXPoints = (list: BaseRate[]): Date[] =>
	list.map(({ effectiveDate }) => startOfMonth(parseUTC(effectiveDate)))

export const getBaseRatesToYPoints = (data: BaseRate[]): (number | null)[] => data.map(({ rate }) => rate ?? null)
