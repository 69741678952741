import { Utility } from "@model/types/utilities"
import axios from "axios"
import util from "util"
import AbstractService from "../AbstractService"
import { formatDocument } from "../utils"

type GeyUtilityResponse = { ok: boolean; utilityRate: Utility }

class UtilityService extends AbstractService {
	constructor() {
		super("utilities")
	}

	http = axios.create({
		baseURL: util.format(process.env.REACT_APP_BACKEND_API, process.env.REACT_APP_FIREBASE_PROJECT_ID),
	})

	getByUserId = async (userId: string) => this.http.post<GeyUtilityResponse>("/utility", { userId })

	getByUtilityId = async (utilityId: string): Promise<Utility> => {
		const snapshot = await this.getById(utilityId)
		return formatDocument(snapshot.data()) as Promise<Utility>
	}
}

export default UtilityService
