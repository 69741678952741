import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import Box from "@components/fondation/Box/Box"
import Loader from "@components/elements/Loader/Loader"
import LinkUtilityStore from "@store/auth/link-utility.store"

export const CountdownLoader = observer(() => {
	const { linkingCountDown: timer } = useInstance(LinkUtilityStore)

	return (
		<Box
			display="flex"
			flexDirection="column"
			textAlign="center"
			alignItems="center"
			justifyContent="center"
			gap={2}
			flexGrow={1}
		>
			<Loader size="large" />
			<Typography variant="h3">{timer.current}</Typography>
			<Typography
				variant="body1"
				sx={(t) => ({
					marginInline: 15,
					color: t.palette.grey[800],
				})}
			>
				Verifying the connection. This could take up to {timer.duration} seconds
			</Typography>
		</Box>
	)
})
