import { inject } from "react-ioc"
import { makeAutoObservable, reaction, toJS } from "mobx"
import { FileRejection } from "react-dropzone"
import { catchException } from "@model/utils/error"
import format from "date-fns/format"

import UserStorageService from "@services/firebase/user.service/user.storage.service"
import UserStore from "./auth/user.store"
import UploadableBill from "./UploadableBill"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

class BillsStore {
	// injections

	userStorageService = inject(this, UserStorageService)

	userStore: UserStore = inject(this, UserStore)

	// constructors

	constructor() {
		makeAutoObservable(this)

		this.resetFolder()

		if (IS_DEV_MODE) {
			reaction(
				() => this.list,
				(list) => console.log("UPLOADED_BILLS", toJS(list)),
			)
		}
	}

	// attributes

	folderName = "default"

	folderId = 0

	list: UploadableBill[] = []

	// computed

	get totalFiles(): number {
		return this.list.length
	}

	get isAllUploaded(): boolean {
		return this.list.every((file) => file.status === "success")
	}

	get userId(): string {
		if (!this.userStore.userId) throw new Error("User is Not initialized")
		return this.userStore.userId
	}

	// actions

	add = (file: (File | FileRejection)[]) => {
		file.map((f) => this.addBill(new UploadableBill(f)))
	}

	addBill = (bill: UploadableBill) => {
		try {
			this.list.push(bill)
			if (!bill.error) {
				bill.runUploadTask(this.userStorageService.createUploadBillTask(this.userId, this.folderName, bill.file))
			}
		} catch (error) {
			catchException(error)
		}
	}

	deleteBill = async (billId: string) => {
		try {
			const billIdx = this.list.findIndex((bill) => bill.id === billId)
			if (billIdx > -1) {
				const billToDelete = this.list[billIdx]

				// TODO move to another method
				if (billToDelete.inProgress) {
					billToDelete.cancel()
				}

				if (billToDelete.isDeletable) {
					await this.userStorageService.deleteFile(this.userId, this.folderName, billToDelete.file.name)
				}

				this.list = this.list.filter(({ id }) => id !== billId)
			}
		} catch (error) {
			catchException(error)
		}
	}

	resetFolder = () => {
		this.folderName = format(new Date(), "yyyyMMdd-Hmm")
		this.folderId = Number(this.folderName.replace("-", ""))
	}
}

export default BillsStore
