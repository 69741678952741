import { v4 as uuid } from "uuid"
import { getImageUrl } from "@components/elements/Image/collection"

export const arborBenefits = [
	{
		id: uuid(),
		title: "No Service Changes",
		content:
			"There's no service interruption or change in the reliability of your power. Just pay the same utility bill, with a new rate.",
		image: getImageUrl("shake.png"),
	},
	{
		id: uuid(),
		title: "100% Free",
		content:
			"Our energy supply partners pay us so you don't have to, and we only get paid when you choose to switch to a new rate.",
		image: getImageUrl("save.png"),
	},
	{
		id: uuid(),
		title: "No Risk, Cancel Anytime",
		content:
			"Cancel your new rate anytime. If there's an early termination fee that you pay, we'll reimburse you for it, no questions asked.",
		image: getImageUrl("finish.png"),
	},
	{
		id: uuid(),
		title: "Lifetime Protection",
		content:
			"No more surprise rate hikes. When your new rate expires, we'll automatically alert you or request a new one on your behalf, so you're always protected.",
		image: getImageUrl("groovy.png"),
	},
	{
		id: uuid(),
		title: "Private & Secure",
		content:
			"We only use your personal data to help you save, and we never share your information without your permission.",
		image: getImageUrl("privacy.png"),
	},
	{
		id: uuid(),
		title: "Easy & Seamless",
		content:
			"We handle all the busy work of finding and enrolling you in a new rate. No more confusing fine print, long phone calls, or annoying forms.",
		image: getImageUrl("switch.png"),
	},
]
