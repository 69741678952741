import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import DashboardLayout from "@components/modules/layouts/DashboardLayout"
import ProductStore from "@store/product/product.store"
import Loader from "@components/elements/Loader/Loader"
import Box from "@components/fondation/Box/Box"
import CheckoutForm from "@components/modules/forms/connect-utility/EnterBillForm/CheckoutForm"

const CheckoutPage: React.FC = observer(() => {
	const product = useInstance(ProductStore)

	return (
		<DashboardLayout>
			{!product.user || !product.service || !product.utilityRate ? (
				<Box display="flex" width="100%">
					<Loader size="large" center />
				</Box>
			) : (
				<CheckoutForm />
			)}
		</DashboardLayout>
	)
})

export default CheckoutPage
