export const preferenceOptions = [
	{
		value: "not-important",
		label: "Savings",
	},
	{
		value: "very-important",
		label: "Renewables",
	},
]
