import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Typography from "@components/fondation/Typography/Typography"
import { getImageUrl } from "@components/elements/Image/collection"
import PerformanceCard, { PerformanceCardProps } from "@components/elements/Card/PerformanceCard/PerformanceCard"
import RadioGroup from "@components/elements/Radio/RadioGroup"
import Stack from "@components/fondation/Stack/Stack"
import RadioCard from "@components/elements/Radio/RadioCard"
import { useInstance } from "react-ioc"
import LinkUtilityStore from "@store/auth/link-utility.store"

const TITLE = "Link preferences"
const MESSAGE = "Choose whether you want personalized results, or if you want to see the best available rate now."

export type RatePreference = "link" | "skip"

type Option = {
	name: RatePreference
} & PerformanceCardProps

const ratePreferenceOptions: Option[] = [
	{
		name: "link",
		title: "I want to link my utility account for personalized results",
		description: "Link your utility account or bill so we can analyze your current rate.",
		imageSrc: getImageUrl("link.png"),
		badgeProps: {
			color: "green",
			label: "Most personalized",
		},
	},
	{
		name: "skip",
		title: "I want to see the best rate available now",
		description: "We'll show you the best option based on your preferences right now.",
		imageSrc: getImageUrl("monitor.png"),
		badgeProps: {
			color: "rust",
			label: "Fastest",
		},
	},
]

const RatePreferencesPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const linkStore = useInstance(LinkUtilityStore)

	const [preference, setPreference] = useState<string | null>(null)

	const handleSubmit = (value: RatePreference) => {
		if (value === "link") navigate("/enrollment/provider")
		if (value === "skip") {
			linkStore.skipLinking()
			navigate("/enrollment/success", { state: { messageVariant: "skip" } })
		}
	}

	const handleChange = (value: string) => {
		setPreference(value)
		handleSubmit(value as RatePreference)
	}

	return (
		<Stack spacing={6} alignItems="center">
			<Stack>
				<Typography variant="h3" mb={2.5}>
					{TITLE}
				</Typography>
				<Typography variant="body1" color="grey.800">
					{MESSAGE}
				</Typography>
			</Stack>

			<RadioGroup name="preferences" value={preference}>
				<Stack spacing={3}>
					{ratePreferenceOptions.map(({ name, ...rest }) => (
						<RadioCard value={name} key={name} fullWidth onClick={() => handleChange(name)}>
							<PerformanceCard {...rest} />
						</RadioCard>
					))}
				</Stack>
			</RadioGroup>

			<Typography variant="body2" color="grey.800">
				Either choice is free, with no commitment or change to your utility service.
			</Typography>
		</Stack>
	)
})

export default RatePreferencesPage
