import React from "react"
import { observer } from "mobx-react-lite"

import RateDetailsCard from "@components/elements/Card/RateDetailsCard"
import SimpleTable from "@components/elements/Table/SimpleTable"
import LabelCard from "@components/elements/Card/LabelCard"
import { shortMoneyFormatter } from "@root/lib/numberUtils"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import useRateOffer from "./useRateOffer"

interface RateCardProps {}

export const RatePreviewCard: React.FC<RateCardProps> = observer(() => {
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const cardData = useRateOffer()

	return (
		<RateDetailsCard
			collapsible={isMobile}
			headerProps={{
				title: cardData.header.title,
				children: cardData.header.description,
			}}
		>
			<SimpleTable rows={cardData.rateInfo} />

			{cardData.savings > 0 ? (
				<LabelCard label="Potential Savings" color="success">
					{shortMoneyFormatter.format(cardData.savings)}
					<sub> over {cardData.offer.guaranteedTerm ?? 0} months</sub>
				</LabelCard>
			) : null}
		</RateDetailsCard>
	)
})

export default RatePreviewCard
