import React from "react"
import UIToolbar, { ToolbarProps } from "@mui/material/Toolbar"
import styled from "@mui/material/styles/styled"
import Typography from "@components/fondation/Typography/Typography"
import { r } from "@theme/utils"
import { matchPath, useLocation } from "react-router-dom"
import useZendeskWidget from "../hooks/useZendeskWidget"

const isLegalRoute = (pathname: string) => [`/account/legal`].find((name) => matchPath(name, pathname))
const version = process.env.REACT_APP_VERSION ? ` 🌳 v${process.env.REACT_APP_VERSION}` : ""

interface DashboardFooterProps extends ToolbarProps {}

const Container = styled(UIToolbar)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "auto auto",
	gap: r(30),
	justifyContent: "center",
	borderTop: "1px solid",
	borderColor: theme.palette.grey[200],
	[theme.breakpoints.up("xs")]: {
		minHeight: r(66),
	},
}))

const DashboardFooter: React.FC<DashboardFooterProps> = (props) => {
	const location = useLocation()

	const showFooter = isLegalRoute(location.pathname)

	useZendeskWidget()

	if (!showFooter) return null
	return (
		<Container {...props}>
			<Typography variant="caption" textAlign="center">
				ⓒ {new Date().getFullYear()} Arbor Energy Holdings Inc.
			</Typography>
			<Typography variant="caption" textAlign="center">
				{version}
			</Typography>
		</Container>
	)
}

export default DashboardFooter
