import React from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import Box from "@components/fondation/Box/Box"
import SwitchTabs from "@components/elements/Tabs/SwitchTabs"
import Tab from "@components/elements/Tabs/Tab"
import { preferenceOptions } from "@store/product/product.store"
import RateOffer from "@store/product/RateOffer"
import UtilityStore from "@store/auth/utilities.store"
import BenefitsList from "./BenefitsList"
import HowItWorksList from "./HowItWorksList"
import ProductFAQList from "./ProductFAQList"
import RateComparison from "./RateComparison"

const sectionMargin = { md: 10, xs: 7.5 }

const Product: React.FC = observer(() => {
	const [offer, utilityStore] = useInstances(RateOffer, UtilityStore)

	const utilityName = utilityStore.utilityShortName
	const isRenewable = offer.productStore.activePlan === "best-renewable"
	const rateCount = isRenewable
		? offer.productStore.utilityRate?.rateSummary.greenRateCount
		: offer.productStore.utilityRate?.rateSummary.supplierRateCount ?? 0

	const isReview = offer.serviceStatus === "review"

	return (
		<Box>
			<Typography variant="h2" mb={5}>
				{isRenewable ? "Your Renewable Options" : "Your Savings Options"}
			</Typography>
			{!isReview ? (
				<Typography variant="body1" marginY={5}>
					We found <b>{rateCount} available rates</b> for {utilityName} customers. Use the toggle below to see both
					savings and renewables rates.
				</Typography>
			) : (
				<Typography variant="body1" marginY={5}>
					We are in the process of switching you to the best of <b>{rateCount} available rates</b> for {utilityName}{" "}
					customers.
				</Typography>
			)}

			<Box mt={{ xs: 3, lg: 5 }} mb={{ xs: 7.5, lg: 10 }}>
				{!isReview && (
					<SwitchTabs
						value={offer.productStore.activePlan}
						onChange={(_, value) => offer.productStore.changeOption(value)}
					>
						{preferenceOptions.map(({ name, label }) => (
							<Tab label={label} value={name} key={name} />
						))}
					</SwitchTabs>
				)}
			</Box>

			<Box mb={sectionMargin}>
				<RateComparison />
			</Box>

			<Box marginY={sectionMargin}>
				<Typography variant="h3" mb={4}>
					Arbor Benefits
				</Typography>
				<BenefitsList />
			</Box>

			<Box marginY={sectionMargin}>
				<Typography variant="h3" mb={4}>
					How It Works
				</Typography>
				<HowItWorksList />
			</Box>

			<Box marginY={sectionMargin}>
				<Typography variant="h3" mb={4}>
					FAQs
				</Typography>
				<ProductFAQList />
			</Box>
		</Box>
	)
})

export default Product
