import React from "react"
import { RadioProps } from "@mui/material/Radio"
import { useRadioGroup } from "@mui/material/RadioGroup"
import styled from "@mui/material/styles/styled"

import { r } from "@theme/utils"
import Radio from "./Radio"

export interface RadioCardOwnProps {
	color?: RadioProps["color"]
	checked?: boolean
	disabled?: boolean
	fullWidth?: boolean
	children?: React.ReactNode
}

export type RadioCardProps = RadioProps & RadioCardOwnProps

const LabelCard = styled("label", { name: "RadioCardLabel" })<RadioCardProps>(
	({ theme, checked, color = "default", fullWidth, disabled }) => {
		const mainColor = color === "default" ? theme.palette.grey[900] : theme.palette[color].main
		const getBorderColor = (checkerColor: string) => (checked ? checkerColor : "transparent")
		return {
			display: "flex",
			flexGrow: fullWidth ? 1 : "auto",
			padding: r(17, 16),
			width: fullWidth ? "100%" : "fit-content",
			borderRadius: theme.shape.borderRadius,
			pointerEvents: disabled ? "none" : undefined,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: disabled ? getBorderColor(theme.palette.grey[200]) : getBorderColor(mainColor),
			boxShadow: "0px 5px 44px rgba(14, 15, 25, 0.06)",
			backgroundColor: theme.palette.common.white,
			cursor: "pointer",
			userSelect: "none",
			"&:hover": {
				borderColor: !checked ? theme.palette.grey[200] : undefined,
			},
		}
	},
)

const Content = styled("div")<{ disabled?: boolean }>(({ theme, disabled }) => ({
	...theme.typography.body1,
	lineHeight: "140%",
	flex: "1 1 100%",
	opacity: disabled ? 0.3 : 1,
}))

const Control = styled("div")({
	display: "flex",
	flexDirection: "column",
	flex: "1 1 auto",
	marginLeft: r(8),
	".MuiRadio-root": {
		padding: 0,
	},
})

const RadioCard: React.FC<RadioCardProps> = (props) => {
	const { children, name: nameProp, checked: checkedProp, value, color, disabled, fullWidth, ...radioProps } = props

	const radioGroup = useRadioGroup()

	const checked = radioGroup && checkedProp == null ? radioGroup.value === value : checkedProp
	const name = radioGroup && nameProp == null ? radioGroup.name : nameProp

	return (
		<LabelCard htmlFor={radioProps.id} checked={checked} color={color} disabled={disabled} fullWidth={fullWidth}>
			<Content disabled={disabled}>{children}</Content>
			<Control>
				<Radio {...radioProps} value={value} name={name} checked={checked} color={color} disabled={disabled} />
			</Control>
		</LabelCard>
	)
}

export default RadioCard
