/* eslint-disable camelcase */
import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import SubPageLayout from "@pages/account/AccountSectionLayout"
import CardBox from "@components/elements/Card/CardBox"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import Typography from "@components/fondation/Typography/Typography"
import DatePicker from "@components/elements/DatePicker/DatePicker"
import Box from "@components/fondation/Box/Box"
import Grid from "@components/fondation/Grid/Grid"
import AddressAutocomplete from "@components/modules/forms/auth/components/AddressAutocomplete"
import Loader from "@components/elements/Loader/Loader"
import Button from "@components/elements/Button/Button"
import FormTextField from "@components/modules/forms/common/FormTextField"
import {
	moveAddressInitialValues,
	moveAddressValidationSchema,
	parseAutocomplete,
} from "@components/modules/forms/auth/AddressForm/utils"
import { errorMessage, hasError } from "@components/modules/forms/common/utils"
import FormControl from "@mui/material/FormControl"
import { FormHelperText } from "@mui/material"
import { MoveAddressFormData } from "@components/modules/forms/auth/AddressForm/types"
import { useInstance } from "react-ioc"
import UserStore from "@store/auth/user.store"
import { ErrorToast, SuccessToast } from "@components/elements/Toast/Toast"

type PlaceResult = google.maps.places.PlaceResult

const failUpdateMessage = () =>
	toast(
		<ErrorToast
			title="Oops!"
			description="Something went wrong while sending your move address request. Please try again later."
		/>,
	)

const successUpdateMessage = () =>
	toast(<SuccessToast title="Alright" description="Your move address request successfully sent." />)

const MoveAddressPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const userStore = useInstance(UserStore)

	const handleSubmit = async (form: MoveAddressFormData) => {
		const updateError = await userStore.moveAddress(form)
		if (updateError) failUpdateMessage()
		else successUpdateMessage()
	}

	const formik = useFormik<MoveAddressFormData>({
		initialValues: { ...moveAddressInitialValues },
		validateOnChange: false,
		validateOnBlur: true,
		validationSchema: moveAddressValidationSchema,
		onSubmit: handleSubmit,
	})

	const handleBack = () => navigate("../address")

	const setAddressValues = useCallback(
		(result: PlaceResult | null) => {
			if (result == null) {
				formik.setFieldValue("address", "")
				return
			}
			const { place_id, address_components } = result
			if (!address_components || !place_id) return
			const newFormData = parseAutocomplete(place_id, address_components)

			formik.resetForm({ values: { ...formik.values, ...newFormData } })
			formik.validateForm()
		},
		[formik.values],
	)

	const loading = userStore.isLoading

	return (
		<SubPageLayout label="Move My Address" onBack={handleBack}>
			<CardBox bg={isMobile ? "secondary" : "primary"} elevation={isMobile ? 0 : 1} p={isMobile ? 0 : 6}>
				<FormikProvider value={formik}>
					<FormControl component="form" fullWidth onSubmit={formik.handleSubmit}>
						<Box mb={6}>
							<Typography variant="h4" mb={2.5}>
								When do you move out of your current place?
							</Typography>

							<DatePicker
								label="Select date"
								value={(formik.values.startDate as unknown as string) ?? null}
								onChange={(value) => formik.setFieldValue("startDate", value)}
								InputProps={{
									name: "startDate",
									error: hasError(formik, "startDate"),
									onBlur: formik.handleBlur,
								}}
							/>
							{hasError(formik, "startDate") && (
								<FormHelperText error>{errorMessage(formik, "startDate")}</FormHelperText>
							)}
						</Box>

						<Box mb={6}>
							<Typography variant="h4" mb={2.5}>
								New address
							</Typography>

							<Grid container spacing={4}>
								<Grid item xs={8}>
									<AddressAutocomplete
										defaultValue={formik.values.address}
										name="address"
										inputLabel="Street Address"
										error={hasError(formik, "address")}
										helperText={errorMessage(formik, "address")}
										onBlur={formik.handleBlur}
										onChange={setAddressValues}
									/>
									{hasError(formik, "address") && (
										<FormHelperText error>{errorMessage(formik, "address")}</FormHelperText>
									)}
								</Grid>

								<Grid item xs={4}>
									<FormTextField fullWidth name="apartment" label="Apt/Suite" />
								</Grid>

								<Grid item xs={12}>
									<FormTextField fullWidth name="city" label="City" />
								</Grid>

								<Grid item xs={6}>
									<FormTextField fullWidth name="state" label="State" />
								</Grid>

								<Grid item xs={6}>
									<FormTextField fullWidth name="postalCode" label="Zip Code" />
								</Grid>
							</Grid>
						</Box>

						<Box mb={6}>
							<Typography variant="h4" mb={2.5}>
								When do you move in to the new address?
							</Typography>

							<DatePicker
								label="Select date"
								value={(formik.values.endDate as unknown as string) ?? null}
								onChange={(value) => formik.setFieldValue("endDate", value)}
								InputProps={{
									name: "endDate",
									error: hasError(formik, "endDate"),
									onBlur: formik.handleBlur,
								}}
							/>
							{hasError(formik, "endDate") && <FormHelperText error>{errorMessage(formik, "endDate")}</FormHelperText>}
						</Box>
						<Button
							fullWidth
							variant="contained"
							size="large"
							type="submit"
							disabled={loading}
							endIcon={loading && <Loader />}
						>
							Submit move request
						</Button>
					</FormControl>
				</FormikProvider>
			</CardBox>
		</SubPageLayout>
	)
})

export default MoveAddressPage
