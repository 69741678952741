/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { observer } from "mobx-react-lite"
import Grid from "@components/fondation/Grid/Grid"
import { useLocation, useNavigate } from "react-router-dom"
import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import ModalHeader from "@components/elements/HeaderBar/ModalHeader"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@components/elements/Button/Button"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"
import { SUPPORT_PHONE } from "@model/constants/utilities/app"
import { useInstances } from "react-ioc"
import UserStore from "@store/auth/user.store"

const TITLE_IMAGE_SRC = getImageUrl("door_01.png")
const TITLE = "Good news!"
const SUB_TITLE = "You are eligible for our white glove service"

const ContactUsPage: React.FC = observer(() => {
	const location = useLocation()
	const navigate = useNavigate()
	const [userStore] = useInstances(UserStore)

	const handleBack = () => {
		const to = (location?.state as any)?.backPath
		return navigate(typeof to === "string" ? to : "/utilities")
	}

	const handleClose = () => navigate("/")

	const handleSubmit = () => {
		window.open(`tel:${SUPPORT_PHONE}`, "_self")
	}

	return (
		<ModalBox flexBasis="100%">
			<ModalHeader
				hideEndButton={userStore.user?.property?.class === "commercial"}
				hideBackButton={location?.state?.hideBackButton}
				BackButtonProps={{ onClick: handleBack }}
				EndButtonProps={{
					onClick: handleClose,
					children: <CloseIcon />,
				}}
			/>

			<ModalBox flexBasis="100%" p={4}>
				<Grid container spacing={5} justifyContent="center" marginY="auto">
					<Grid item xs={12} textAlign="center">
						<Image src={TITLE_IMAGE_SRC} height={120} />
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h2" textAlign="center" mb={3}>
							{TITLE}
							<br />
							{SUB_TITLE}
						</Typography>
						<Typography variant="body1" color="grey.800" textAlign="center">
							We will call you to review your savings options.
							<br />
							If you&lsquo;re ready to chat now, just call us at the number below.
							<br />
							<br />
							Contact us for your free savings consultation:
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Button variant="contained" fullWidth onClick={handleSubmit}>
							Call now {SUPPORT_PHONE}
						</Button>
					</Grid>
				</Grid>
			</ModalBox>
		</ModalBox>
	)
})

export default ContactUsPage
