export const designImages = [
	"bulb.png",
	"bulb_01.png",
	"choice.png",
	"click.png",
	"coffee.png",
	"compare.png",
	"cord.png",
	"door.png",
	"door_01.png",
	"download.png",
	"finish.png",
	"finish_01.png",
	"flower.png",
	"globe_01.png",
	"globe_02.png",
	"globe_03.png",
	"groovy.png",
	"hero_00.png",
	"hero_01.png",
	"hero_02.png",
	"hero_03.png",
	"hero_04.png",
	"high_five.png",
	"lamp.png",
	"link.png",
	"link_01.png",
	"money.png",
	"monitor.png",
	"monitor_01.png",
	"monitor_02.png",
	"phone.png",
	"plug.png",
	"plug_01.png",
	"power.png",
	"privacy.png",
	"privacy_01.png",
	"renewables.png",
	"save.png",
	"search.png",
	"security.png",
	"security_01.png",
	"security_02.png",
	"security_03.png",
	"security_04.png",
	"security_06.png",
	"shake.png",
	"solar.png",
	"success.png",
	"switch.png",
	"team.png",
	"wallet.png",
	"welcome.png",
	"zero_hassle.png",
	"battery.png",
] as const

export type ImagesName = typeof designImages[number]

export const getImageUrl = (name: ImagesName): string => `https://arbor-static.web.app/design/${name}`
