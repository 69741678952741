import * as yup from "yup"
import { parseFormattedPhoneNumber, parseInternationalPhoneNumber } from "@root/lib/numberUtils"
import { validUSAStates } from "@model/constants/address/states"

const sqlQueryRegexp =
	// eslint-disable-next-line no-useless-escape
	/(\s*([\0\b\'\"\n\r\t\%\_\\]*\s*(((select\s*.+\s*from\s*.+)|(insert\s*.+\s*into\s*.+)|(update\s*.+\s*set\s*.+)|(delete\s*.+\s*from\s*.+)|(drop\s*.+)|(truncate\s*.+)|(alter\s*.+)|(exec\s*.+)|(\s*(all|any|not|and|between|in|like|or|some|contains|containsall|containskey)\s*.+[\=\>\<=\!\~]+.+)|(let\s+.+[\=]\s*.*)|(begin\s*.*\s*end)|(\s*[\/\*]+\s*.*\s*[\*\/]+)|(\s*(\-\-)\s*.*\s+)|(\s*(contains|containsall|containskey)\s+.*)))(\s*[\;]\s*)*)+)/i

const validNameRegexp = /(^[a-zA-Z\u0080-\uFFFF]+(?:[\s][a-zA-Z\u0080-\uFFFF]+)*$)/

export const firstNameValidator = yup
	.string()
	.trim()
	.matches(validNameRegexp, { message: "Please input a valid Fist Name" })
	.test("sql injection not allowed", "Please input a valid Fist Name", (value) => !value?.match(sqlQueryRegexp))
	.max(64, "64 characters maximum")

export const lastNameValidator = yup
	.string()
	.trim()
	.matches(validNameRegexp, { message: "Please input a valid Last Name" })
	.test("sql injection not allowed", "Please input a valid Last Name", (value) => !value?.match(sqlQueryRegexp))
	.max(64, "64 characters maximum")

export const zipCodeValidator = yup.string().matches(/(^[0-9]{5}$)/g, "Please input a valid Zip Code")

export const identifierValidator = yup.string().matches(/^[0-9a-f]{32}$/i, "Please input a valid Identifier")

export const emailValidator = yup.string().trim().email("Please input a valid Email")

export const phoneValidator = yup
	.string()
	.trim()
	.transform(parseFormattedPhoneNumber)
	.length(10, "Phone number must be 10 digits long")

export const phoneWithCodeValidator = yup
	.string()
	.trim()
	.transform(parseInternationalPhoneNumber)
	.length(10, "Phone number must be 10 digits long")

export const DATE_FORMAT = "MMM d, yyyy"

export const dateFormatValidator = yup.date().typeError("Incorrect date format")

export const cityValidator = yup
	.string()
	.matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, { message: "Please input a valid City name" })
	.test("sql injection not allowed", "Please input a valid City name", (value) => !value?.match(sqlQueryRegexp))

export const usStateValidator = yup
	.string()
	.uppercase()
	.oneOf(validUSAStates, "Please input a two letter State abbreviation")

export const apartmentValidator = yup
	.string()
	.matches(/^[a-zA-Z0-9]*$/, { message: "Please input a valid Apt/Suite letter or number" })

export const signUpValidationScheme = yup.object({
	firstName: firstNameValidator.clone().required("First Name is required"),
	lastName: lastNameValidator.clone().required("Last Name is required"),
	email: emailValidator.clone().required("Email is required"),
	phone: phoneValidator.clone().required("Phone Number is required"),
})
