/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import Box from "@components/fondation/Box/Box"
import SwitchTabs from "@components/elements/Tabs/SwitchTabs"
import Tab from "@components/elements/Tabs/Tab"
import BillsStore from "@store/bills.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import BillUploadPage from "./UploadBillPage"
import LinkManuallyPage from "./LinkManuallyPage"

const tabs = ["Fill Out Form", "Upload Bill"]

const UtilityDetailsPage: React.FC = observer(() => {
	const billsStore = useInstance(BillsStore)

	const [page, setPage] = useState(0)

	const handleChange = (_: any, newValue: number) => {
		setPage(newValue)
	}

	useEffect(() => {
		billsStore.resetFolder()
	}, [])

	return (
		<ModalBox overflow="initial" sx={{ minHeight: "100%", flexShrink: 0 }}>
			<Box display="flex" justifyContent="center" pb={4.5}>
				<SwitchTabs value={page} onChange={handleChange}>
					{tabs.map((label) => (
						<Tab label={label} key={label} />
					))}
				</SwitchTabs>
			</Box>

			<Box display="flex" flexGrow={1}>
				{page === 0 && <LinkManuallyPage />}
				{page === 1 && <BillUploadPage />}
			</Box>
		</ModalBox>
	)
})

export default UtilityDetailsPage
