/* eslint-disable camelcase */
import { Place } from "@model/types/user"
import * as yup from "yup"
import {
	apartmentValidator,
	cityValidator,
	dateFormatValidator,
	usStateValidator,
	zipCodeValidator,
} from "@components/modules/forms/common/validations"
import { AddressFormData, MoveAddressFormData } from "./types"

export const addressInitialValues: AddressFormData = {
	address: "",
	apartment: "",
	city: "",
	state: "",
	postalCode: "",
}

export const defaultInitialValues: AddressFormData = {
	...addressInitialValues,
	propertyClass: undefined,
	owner: undefined,
}

export const moveAddressInitialValues: MoveAddressFormData = {
	...addressInitialValues,
	startDate: new Date(),
	endDate: new Date(),
}

export const defaultRequiredMessage = "Field is required"

export const classOptions = [
	{ name: "residential", label: "Home" },
	{ name: "commercial", label: "Business" },
]

export const ownershipOptions = [
	{ name: "own", label: "Own" },
	{ name: "rent", label: "Rent" },
]

export const addressValidator = {
	address: yup.string().trim().required("Street Address is required"),

	apartment: apartmentValidator.clone().trim(),

	city: cityValidator.clone().trim().required("City is required"),

	state: usStateValidator.clone().trim().required("State is required"),

	postalCode: zipCodeValidator.clone().trim().required("Zip Code is required"),
}

export const addressDetailsValidator = {
	propertyClass: yup
		.string()
		.required(defaultRequiredMessage)
		.oneOf(classOptions.map(({ name }) => name)),

	owner: yup.boolean(),
}

export const addressValidationSchema = yup.object({
	...addressValidator,
	owner: yup.boolean(),
})

export const fullAddressValidationSchema = yup.object({
	...addressValidator,
	...addressDetailsValidator,
})

export const moveAddressValidationSchema = yup.object({
	...addressValidator,
	startDate: dateFormatValidator.clone().required(),
	endDate: dateFormatValidator.clone().required(),
})

export const parseAutocomplete = (
	placeId: string,
	components: google.maps.GeocoderAddressComponent[],
): Partial<AddressFormData> => {
	const findType = (type: string) => components.find(({ types }) => types.includes(type))

	const street_number = findType("street_number")?.long_name
	const route = findType("route")?.long_name
	const locality = findType("locality")?.long_name
	const sublocality = findType("sublocality")?.long_name
	const neighborhood = findType("neighborhood")?.long_name
	const administrative_area_level_1 = findType("administrative_area_level_1")?.short_name
	const administrative_area_level_3 = findType("administrative_area_level_3")?.short_name
	const postal_code = findType("postal_code")?.short_name
	const country = findType("country")?.short_name

	const address = route ? (street_number ? `${street_number} ${route}` : route) : administrative_area_level_3

	const city = locality ?? sublocality ?? neighborhood ?? ""
	const state = administrative_area_level_1 ?? ""
	const postalCode = postal_code ?? ""

	const fullAddress =
		`${street_number ? `${street_number} ` : ""}` +
		`${route ? `${route} ` : ""}` +
		`${city}, ` +
		`${state}, ` +
		`${country}`

	return {
		id: placeId,
		components,
		address,
		city,
		state,
		postalCode,
		fullAddress,
	}
}

export const formToPlace = (form: AddressFormData): Place | null => {
	const { id, components, address, fullAddress, city, county, state, postalCode, countryCode } = form
	if (!id || !components) return null

	return {
		id,
		components,
		fullAddress,
		county,
		countryCode,
		address,
		city,
		state,
		postalCode,
	}
}
