import React, { useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { useInstances } from "react-ioc"
import { observer } from "mobx-react-lite"
import Link from "@mui/material/Link"

import EastIcon from "@mui/icons-material/East"
import Typography from "@components/fondation/Typography/Typography"
import EnterBillForm from "@components/modules/forms/connect-utility/EnterBillForm/EnterBillForm"
import UserStore from "@store/auth/user.store"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import ImageViewerStore from "@store/image-viewer.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import Stack from "@components/fondation/Stack/Stack"
import LinkUtilityStore from "@store/auth/link-utility.store"
import { EnterBillFormData } from "@components/modules/forms/connect-utility/EnterBillForm/utils"
import { trackEvent } from "@model/utils/tracking"
import UtilityStore from "@store/auth/utilities.store"

const LinkManuallyPage: React.FC = observer(() => {
	const helperContainerRef = React.useRef(null)
	const theme = useTheme()

	const navigate = useNavigate()
	const [linkStore, userStore, utilityStore, imageViewer] = useInstances(
		LinkUtilityStore,
		UserStore,
		UtilityStore,
		ImageViewerStore,
	)

	useEffect(() => {
		trackEvent("Link Attempted", "Manual")
	}, [])

	const handleSubmit = async (form: EnterBillFormData) => {
		const error = await linkStore.enterBill(form)
		if (!error) navigate("/enrollment/success")
	}

	const service = userStore.user?.services?.electric
	const { utility, utilityShortName } = utilityStore

	const handleOpenExample = () => {
		imageViewer.open(utilityStore?.utility?.key)
	}

	const detailsButtonStyle = {
		display: "block",
		color: theme.palette.grey[900],
		fontWeight: 700,
		fontSize: "12px",
	}

	return (
		<ModalBox overflow="initial" width="100%" ref={helperContainerRef}>
			<Stack flex="1 1 100%" spacing={3}>
				<Stack>
					<Typography variant="h3" mb={1}>
						Enter your account info
					</Typography>
					<Typography variant="body1" color={theme.palette.grey[800]}>
						Fill in details from a recent {utilityShortName} bill.
					</Typography>
				</Stack>

				<Alert severity="info" onClick={handleOpenExample}>
					<AlertTitle>Where do I find this information?</AlertTitle>
					View a sample bill
					<Typography variant="button" sx={detailsButtonStyle}>
						<Link onClick={handleOpenExample} mr={1}>
							Show details
						</Link>
						<EastIcon fontSize="inherit" />
					</Typography>
				</Alert>

				<EnterBillForm
					loading={userStore.isLoading}
					initialValues={service}
					config={utility?.powerkiosk}
					utilityName={utilityShortName}
					onSubmit={handleSubmit}
				/>
			</Stack>
		</ModalBox>
	)
})

export default LinkManuallyPage
