import React, { ChangeEvent } from "react"
import { useInstances } from "react-ioc"
import { observer } from "mobx-react-lite"

import Typography from "@components/fondation/Typography/Typography"
import CardBox from "@components/elements/Card/CardBox"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import Stack from "@components/fondation/Stack/Stack"
import Switch from "@components/elements/Switch/Switch"
import SwitchTabs from "@components/elements/Tabs/SwitchTabs"
import Tab from "@components/elements/Tabs/Tab"
import UtilityAccountStore from "@store/auth/utility-account.store"
import { PreferenceRenewableOption } from "@services/firebase/user.service/request.types"
import { getStatusDescription } from "@model/utils/utilities"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import Box from "@components/fondation/Box/Box"
import LinkUtilityStore from "@store/auth/link-utility.store"
import { successUpdateAutopilot, successUpdatePreference, throwErrorMessage } from "./messages"
import { preferenceOptions } from "./constants"

export const StatusControlCard: React.FC = observer(() => {
	const [utilityAccount, linkStore] = useInstances(UtilityAccountStore, LinkUtilityStore)
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const handleToggleAutopilot = async (e: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
		if (utilityAccount.isAutopilotSync) return
		const error = await utilityAccount.toggleAutopilot(newValue)
		if (error) {
			throwErrorMessage(error)
			return
		}
		successUpdateAutopilot(newValue)
	}

	const handleChangePreference = async (event: React.SyntheticEvent, preference: PreferenceRenewableOption) => {
		if (utilityAccount.isPreferenceSync) return
		const error = await utilityAccount.changePreference(preference)
		if (error) {
			throwErrorMessage(error)
			return
		}
		successUpdatePreference(preference)
	}

	const statusDescription = getStatusDescription(utilityAccount.service?.status)

	return (
		<CardBox bg={isMobile ? "primary" : "secondary"} elevation={0} p={4}>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mt={-2}>
				<Typography variant="h4">Autopilot</Typography>
				<Switch checked={utilityAccount.autopilot} onChange={handleToggleAutopilot} />
			</Stack>

			{linkStore.linkingStatus !== "linked" && linkStore.linkingStatus !== "partially-linked" && (
				<Box marginY={2}>
					<Alert severity="warning">
						<AlertTitle>Link your utility account </AlertTitle>
						Autopilot cannot help you save until you link your utility account.
					</Alert>
				</Box>
			)}

			<Typography variant="body1" color="grey.800" mb={3.5}>
				Automatically switch me when Arbor finds me a better rate based on my preferences.
			</Typography>

			<Stack component="table" width="100%" spacing={3.5}>
				<Stack
					direction="row"
					sx={{
						verticalAlign: "center",
					}}
				>
					<Typography variant="body1" color="grey.700" width="50%">
						Status
					</Typography>
					<Typography variant="body2" color="grey.700" lineHeight="160%" fontWeight={700}>
						{statusDescription.label}
					</Typography>
				</Stack>

				<Stack
					direction="row"
					sx={{
						verticalAlign: "top",
					}}
				>
					<Typography variant="body1" color="grey.700" width="50%">
						Preference
					</Typography>
					<Typography variant="body1">
						<SwitchTabs value={utilityAccount.renewable} onChange={handleChangePreference}>
							{preferenceOptions.map((props) => (
								<Tab {...props} key={props.value} />
							))}
						</SwitchTabs>
					</Typography>
				</Stack>
			</Stack>
		</CardBox>
	)
})

export default StatusControlCard
