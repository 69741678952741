import React from "react"
import { Toolbar as MUIToolbar } from "@components/elements/HeaderBar/Toolbar"
import ButtonBase from "@mui/material/ButtonBase"
import { ToolbarProps as MUIToolbarProps } from "@mui/material/Toolbar"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"

export interface ToolbarProps extends MUIToolbarProps {
	children?: React.ReactNode
}

export const Toolbar = styled(MUIToolbar)({
	width: "100%",
	padding: r(10, 16),
})

export const Button = styled(ButtonBase)({
	background: "white",
	borderRadius: r(0, 0, 16, 16),
	overflow: "hidden",
})

export const FinalToolbar: React.FC<ToolbarProps> = ({ children, onClick, ...rest }) => (
	<Button>
		<Toolbar variant="dense" color="primary" onClick={onClick} {...rest}>
			{children}
		</Toolbar>
	</Button>
)
