import styled from "@mui/material/styles/styled"
import Alert, { AlertProps } from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import React, { ReactElement, ReactNode } from "react"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import { r } from "@theme/utils"

type ToastProps = AlertProps & {
	title: string
	description: ReactNode
}

const ToastStyled = styled(Alert)<AlertProps>(({ theme, severity = "success" }) => ({
	overflow: "hidden",
	boxShadow: "0px 5px 41px rgba(14, 15, 25, 0.1)",
	minWidth: r(250),

	[theme.breakpoints.down("md")]: {
		borderRadius: 0,
		backgroundColor: theme.palette[severity].main,
		boxShadow: "none",
		alignItems: "center",
		height: "40px",
		minWidth: "auto",
	},

	".MuiAlert-icon": {
		[theme.breakpoints.down("md")]: {
			marginRight: r(8),
		},
	},

	"& .MuiAlert-message": {
		[theme.breakpoints.down("md")]: {
			color: theme.palette.grey[50],
			margin: 0,
			fontWeight: 700,
			fontSize: "12px",
		},
	},
}))

const Toast = ({ severity, title, description }: ToastProps): ReactElement => {
	const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

	return (
		<ToastStyled severity={severity}>
			{matches && <AlertTitle>{title}</AlertTitle>}
			{description}
		</ToastStyled>
	)
}

export const SuccessToast = (props: ToastProps): ReactElement => <Toast {...props} severity="success" />
export const ErrorToast = (props: ToastProps): ReactElement => <Toast {...props} severity="error" />
export const WarnToast = (props: ToastProps): ReactElement => <Toast {...props} severity="warning" />
export const InfoToast = (props: ToastProps): ReactElement => <Toast {...props} severity="info" />

export default Toast
