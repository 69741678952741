import React from "react"
import { useInstance } from "react-ioc"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import ModalHeader, { ModalHeaderProps } from "@components/elements/HeaderBar/ModalHeader"
import AuthStore from "@store/auth/auth.store"

export interface AuthModalHeaderProps extends ModalHeaderProps {
	onBack?: () => void
	onClose?: () => void
	hideBackButton?: boolean
	hideEndButton?: boolean
}

const AuthModalHeader: React.FC<AuthModalHeaderProps> = observer(({ onBack, onClose, ...rest }) => {
	const navigate = useNavigate()
	const authStore = useInstance(AuthStore)

	const handleClose = () => {
		onClose?.()
		navigate("/")
	}

	return (
		<ModalHeader
			{...rest}
			BackButtonProps={{
				onClick: onBack,
			}}
			hideEndButton={rest.hideEndButton || !authStore.isAuthorized}
			hideBackButton={rest.hideBackButton}
			EndButtonProps={{
				onClick: handleClose,
			}}
		/>
	)
})

export default AuthModalHeader
