import FacebookPixel from "react-facebook-pixel"
import TagManager from "react-gtm-module"

export function trackEvent(event: string, linkingMethod?: string): void {
	FacebookPixel.track(event)
	TagManager.dataLayer({
		dataLayer: {
			event,
			...(linkingMethod ? { linkingMethod } : {}),
		},
	})
}
