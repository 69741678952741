import React from "react"

import Grid from "@components/fondation/Grid/Grid"
import Typography from "@components/fondation/Typography/Typography"
import { useNavigate } from "react-router-dom"
import LinkCard from "@components/elements/LinkCard/LinkCard"
import UtilityMeta from "@components/prototypes/utilities/UtilityMeta/UtilityMeta"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import UtilityStore from "@store/auth/utilities.store"
import Loader from "@components/elements/Loader/Loader"
import ContainerBox from "@components/prototypes/utilities/UtilityMeta/ContainerBox"
import ModalBox from "@components/elements/Modal/ModalBox"

const options = [
	{
		path: "connect",
		label: "Yes, I have an online account",
	},
	{
		path: "link",
		label: "No, or I don't know my credentials",
	},
]

const AccountForkPage: React.FC = observer(() => {
	const navigate = useNavigate()

	const utilityStore = useInstance(UtilityStore)

	const { utility, utilityShortName, utilityLogo } = utilityStore

	const handleNext = (path: string) => () => {
		navigate(`../${path}`)
	}

	return (
		<ModalBox flex="1 1 100%">
			{utility ? (
				<Grid container spacing={4} alignItems="flex-start">
					<Grid item xs={12}>
						<ContainerBox>
							<UtilityMeta name={utilityShortName} logoSrc={utilityLogo} />
						</ContainerBox>
						<Typography variant="h3" mb={2}>
							Have you created an online account with {utilityShortName}?
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={3}>
							{options.map((item) => (
								<Grid item xs={12} key={item.path}>
									<LinkCard onClick={handleNext(item.path)}>{item.label}</LinkCard>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Loader size="large" center />
			)}
		</ModalBox>
	)
})

export default AccountForkPage
