import { makeAutoObservable, reaction } from "mobx"

class CountDown {
	/*
	 * wait: time to wait in seconds
	 */
	constructor(wait = 100) {
		makeAutoObservable(this)

		this.duration = wait

		reaction(
			() => this.current < 1,
			(done) => {
				if (!this.timer || !done) {
					return
				}
				this.stop()
			},
		)
	}

	duration = 0

	current = 0

	private timer: NodeJS.Timer | null = null

	get isRunning(): boolean {
		return this.timer != null
	}

	start = () => {
		if (this.isRunning) this.stop()
		this.current = this.duration
		this.timer = setInterval(() => {
			this.current -= 1
		}, 1000)
	}

	stop = () => {
		if (!this.timer) return
		clearInterval(this.timer)
		this.timer = null
		this.current = 0
	}

	reset = () => {
		if (this.isRunning) this.stop()
		this.current = this.duration
		this.timer = null
	}
}

export default CountDown
