import React, { ReactElement } from "react"

import Box from "@components/fondation/Box/Box"
import Typography from "@components/fondation/Typography/Typography"
import Button from "@components/elements/Button/Button"
import { useNavigate } from "react-router-dom"
import RichListItem from "@components/elements/List/RichListItem"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import UtilityStore from "@store/auth/utilities.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"

const TITLE = "Final step!  Let's link your utility account"
const MESSAGE =
	"By sharing information from your utility bill, we can give you personalized recommendations on how to save."

const options = [
	{
		title: "Connect effortlessly",
		description: "Arbor lets you securely connect your utility account account in seconds.",
		imgSrc: getImageUrl("link.png"),
	},
	{
		title: "Your data belongs to you",
		description: "Arbor doesn't sell personal info, and will only use it with your permission.",
		imgSrc: getImageUrl("privacy_01.png"),
	},
]

const IntroductionPage: React.FC = observer((): ReactElement => {
	const navigate = useNavigate()
	const { utilityShortName: utilityName } = useInstance(UtilityStore)

	const handleNext = () => {
		// TODO
		navigate("account-fork")
	}

	return (
		<ModalBox flexBasis="100%">
			<Box>
				<Typography variant="h3" mb={2}>
					{TITLE}
				</Typography>
				<Typography
					variant="body1"
					sx={(t) => ({
						color: t.palette.grey[800],
					})}
				>
					{MESSAGE}
				</Typography>
			</Box>

			<ModalBox flexBasis="100%" marginY={6}>
				{options.map((item) => (
					<RichListItem {...item} key={item.title} />
				))}
			</ModalBox>

			<Box>
				<Button fullWidth variant="contained" size="large" onClick={handleNext}>
					Continue
				</Button>
				{!!utilityName && (
					<Typography variant="body2" textAlign="center" mt="10px" sx={({ palette }) => ({ color: palette.grey[700] })}>
						Arbor is a service provider independent of, and not affiliated with, {utilityName}. {utilityName} has not
						endorsed this service.
					</Typography>
				)}
			</Box>
		</ModalBox>
	)
})

export default IntroductionPage
