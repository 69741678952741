import styled from "@mui/material/styles/styled"
import Box from "@components/fondation/Box/Box"
import { r } from "@theme/utils"

const ModalBox = styled(Box, { target: "modal-box" })(({ theme }) => ({
	minHeight: 0,
	minWidth: 0,
	"&::-webkit-scrollbar": {
		width: r(3 + 4 * 2),
	},
	"&::-webkit-scrollbar-thumb": {
		background: theme.palette.primary.main,
		height: r(3),
		border: "solid transparent",
		borderWidth: r(4),
		borderRadius: "6px",
		backgroundClip: "content-box",
	},
}))

ModalBox.defaultProps = {
	display: "flex",
	flexDirection: "column",
	overflow: "auto",
}

export default ModalBox
