import { Color, Plugin } from "chart.js"
import getUnixTime from "date-fns/getUnixTime"
import { v4 as uuid } from "uuid"
import min from "lodash/min"
import max from "lodash/max"
import { drawPoint, drawLine } from "../utils"

export const makeRateLevelLinePlugin = ({
	start: xStart,
	end: xEnd,
	level = 0,
	color = "#000",
	dashed = false,
}: {
	start?: Date
	end?: Date
	level?: number
	color?: Color
	dashed?: boolean
}): Plugin<"line"> => ({
	id: `rateLevelLine_${uuid()}`,
	afterDatasetDraw(chart) {
		const { ctx, scales, chartArea } = chart

		const pixelRightEdge = chartArea.right
		const pixelLeftEdge = chartArea.left
		const pixelXStart = xStart ? scales.x.getPixelForValue(getUnixTime(xStart) * 1000) : pixelLeftEdge
		const pixelXEnd = xEnd ? scales.x.getPixelForValue(getUnixTime(xEnd) * 1000) : pixelRightEdge
		const pixelY = scales.y.getPixelForValue(level)

		drawLine(
			ctx,
			max([pixelXStart, pixelLeftEdge]) ?? 0,
			pixelY,
			min([pixelXEnd, pixelRightEdge]) ?? 0,
			pixelY,
			color,
			dashed,
		)
	},
})

export const makeRatePointPlugin = (xValue: Date, yValue = 0, color: Color = "#000"): Plugin<"line"> => ({
	id: `rateLevelLine_${uuid()}`,
	beforeDatasetDraw(chart) {
		const { ctx, scales } = chart

		const pixelX = scales.x.getPixelForValue(getUnixTime(xValue) * 1000)
		const pixelY = scales.y.getPixelForValue(yValue)

		drawPoint(ctx, pixelX, pixelY, 3, color)
	},
})
