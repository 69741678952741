/* eslint-disable consistent-return */
import { inject } from "react-ioc"
import { makeAutoObservable, reaction, when } from "mobx"

import { AppError } from "@model/utils/error"
import UserService from "@services/firebase/user.service"
import UtilityService from "@services/firebase/utility.service"
import { parseInternationalPhoneNumber } from "@root/lib/numberUtils"
import { updatedDiff } from "deep-object-diff"
import { FormData as AccountFormData } from "@components/modules/forms/AccountForm"
import UserStore from "./user.store"
import SessionStore from "./session.store"
import { formHasProps, formNeedsVerification } from "./utils"

// FIXME: We do such stupid mapping, for future support of error codes from API;
//				For now they just return string value, but it will be actual error codes later.
const errorMessageToCode = (backendMessage?: string) => {
	if (backendMessage === "The code entered is invalid.") return "INVALID_CODE"
	if (backendMessage === "Please wait 10 minutes before trying to verify your account again")
		return "TOO_MANY_ATTEMPTS_WAIT"
	if (backendMessage === "Max send attempts reached") return "TOO_MANY_ATTEMPTS"
	if (backendMessage === "The email entered is already in use by another user.") return "EMAIL_EXISTS"
	return "UNKNOWN_ERROR"
}

class AccountSettingsStore {
	// injections

	userService = inject(this, UserService)

	userStore = inject(this, UserStore)

	sessionStore = inject(this, SessionStore)

	utilitiesService = inject(this, UtilityService)

	// constructors

	constructor() {
		makeAutoObservable(this)

		reaction(
			() => this.isUpdated,
			(isUpdated) => {
				if (isUpdated && this.newState) this.newState = {}
			},
		)
	}

	// attributes

	newState: Partial<AccountFormData> = {}

	isVerifying = false

	error: AppError | null = null

	isLoading = false

	// computed

	get currentSettings(): AccountFormData {
		return {
			firstName: this.userStore.user?.info?.firstName ?? "",
			lastName: this.userStore.user?.info?.lastName ?? "",
			email: this.userStore.user?.email ?? "",
			phone: parseInternationalPhoneNumber(this.userStore.user?.phone ?? ""),
		}
	}

	get isUpdated(): boolean {
		return !formHasProps(updatedDiff(this.currentSettings, this.newState))
	}

	// actions

	update = async (form: Partial<AccountFormData>) => {
		try {
			this.isLoading = true

			this.error = await this.userStore.inUserScope({
				call: async (userId) => {
					this.newState = form

					await this.userService.updateLog(userId, "update-auth", { ...this.newState, channel: "email" })

					await this.userStore.whenUserUpdated(
						(next) => (next.lastActionSuccess === false && next.lastActionError != null) || !!next.lastActionSuccess,
					)

					const isSuccess = this.userStore.user?.lastActionSuccess
					const error = this.userStore.user?.lastActionError

					if (isSuccess === false && error != null) {
						return errorMessageToCode(error)
					}
				},
			})

			if (this.error == null) {
				this.isVerifying = formNeedsVerification(this.newState)
				if (!this.isVerifying) {
					await when(() => this.isUpdated)
				}
			} else {
				this.newState = {}
			}
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	resendCode = async () => {
		try {
			this.isLoading = true
			if (!this.newState) return this.error

			await this.userStore.inUserScope({
				call: (userId) => {
					this.userService.updateLog(userId, "update-auth", { ...this.newState, channel: "email" })
				},
			})
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	verifyUpdate = async (code: string) => {
		try {
			this.isLoading = true

			this.error = await this.userStore.inUserScope({
				call: async (userId) => {
					if (!this.isVerifying) return "NO_VERIFICATION_IN_PROGRESS"

					await this.userService.updateLog(userId, "update-auth", {
						...this.newState,
						code,
						channel: "email",
					})

					await this.userStore.whenUserUpdated(
						(next) => (next.lastActionSuccess === false && next.lastActionError != null) || !!next.lastActionSuccess,
					)

					const isSuccess = this.userStore.user?.lastActionSuccess
					const error = this.userStore.user?.lastActionError

					if (isSuccess === false && error != null) {
						return errorMessageToCode(error)
					}
				},
			})
			if (this.error == null) {
				this.isVerifying = false
				await when(() => this.isUpdated)
			}
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	cancelVerification = () => {
		this.newState = {}
		this.isVerifying = false
	}
}

export default AccountSettingsStore
