import { collection, addDoc, WithFieldValue, DocumentData, doc, getDoc, updateDoc } from "firebase/firestore"
import { firestore } from "./firebase"

class AbstractService {
	private collectionName: string

	protected collection = () => collection(firestore, this.collectionName)

	protected doc = (id: string) => doc(firestore, this.collectionName, id)

	protected addToCollection = <T extends DocumentData>(requestData: WithFieldValue<T>) =>
		addDoc(this.collection(), requestData)

	protected getById = (id: string) => getDoc(this.doc(id))

	protected addToSubCollection = <T extends DocumentData>(
		id: string,
		subCollection: string,
		requestData: WithFieldValue<T>,
	) => addDoc(collection(this.doc(id), subCollection), requestData)

	protected updateById = <T extends DocumentData>(id: string, requestData: T) => updateDoc(this.doc(id), requestData)

	constructor(collectionName: string) {
		this.collectionName = collectionName
	}
}

export default AbstractService
