import React from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import Alert from "@components/elements/Alert/Alert"
import LinkUtilityStore from "@store/auth/link-utility.store"
import NavLink from "@components/elements/NavLink/NavLink"
import EastOutlinedIcon from "@mui/icons-material/EastOutlined"
import UserStore from "@store/auth/user.store"

export const ChartMessage = observer(() => {
	const [linkStore, userStore] = useInstances(LinkUtilityStore, UserStore)

	const status = linkStore.linkingStatus
	const address = userStore.user?.address

	if (!address)
		return (
			<Alert severity="info">
				<AlertTitle>Please provide your address</AlertTitle>
				Don&lsquo;t worry, we don&lsquo;t like junk mail either
				<br />
				<NavLink to="/enrollment/about/address">
					Add your address
					<EastOutlinedIcon />
				</NavLink>
			</Alert>
		)

	if (status === "unlinked")
		return (
			<Alert severity="info">
				<AlertTitle>Finish linking your utility account</AlertTitle>
				And get more personalized results
				<br />
				<NavLink to="/enrollment/provider/connect">
					Link account
					<EastOutlinedIcon />
				</NavLink>
			</Alert>
		)

	if (status === "error")
		return (
			<Alert severity="error">
				<AlertTitle>{linkStore.error?.title ?? "Account connection failed"}</AlertTitle>
				{linkStore.error?.message ?? "Unknown error"}
				<br />
				<NavLink to="/enrollment/provider/connect">
					Link account
					<EastOutlinedIcon />
				</NavLink>
			</Alert>
		)

	if (status === "in-progress")
		return (
			<Alert severity="warning">
				<AlertTitle>We are processing your utility data</AlertTitle>
				Standby for personalized, apples-to-apples results. In the meantime, here&lsquo;s our top pick.
			</Alert>
		)

	return null
})

export default ChartMessage
