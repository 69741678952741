import { useInstance } from "react-ioc"

import RateOffer from "@store/product/RateOffer"
import { PairRowData } from "@components/elements/Table/SimpleTable"
import { makeRateTable, getSavingsHeader, getPotentialSavings, SavingsHeader } from "./utils"

interface IRateOffer {
	header: SavingsHeader
	rateInfo: PairRowData[]
	savings: number
	offer: RateOffer
}

const useRateOffer = (): IRateOffer => {
	const offer = useInstance(RateOffer)

	return {
		header: getSavingsHeader(offer),
		rateInfo: makeRateTable(offer),
		savings: getPotentialSavings(offer),
		offer,
	}
}

export default useRateOffer
