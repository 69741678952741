import React from "react"
import MUIDivider from "@components/fondation/Divider/Divider"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import CardBox from "../Card/CardBox"

import List from "./List"

export interface MenuGroupProps {
	children: React.ReactNode
}

const Divider = styled(MUIDivider)({
	marginRight: r(16),
	marginLeft: r(52),
})

const MenuGroup: React.FC<MenuGroupProps> = React.memo(({ children }) => {
	const arrayChildren = React.Children.toArray(children)
	return (
		<CardBox p={0}>
			<List disablePadding>
				{arrayChildren.map((child, idx) => (
					<>
						{idx !== 0 && <Divider variant="inset" />}
						{child}
					</>
				))}
			</List>
		</CardBox>
	)
})

export default MenuGroup
