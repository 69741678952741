import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import startOfMonth from "date-fns/startOfMonth"
import getUnixTime from "date-fns/getUnixTime"
import addMonths from "date-fns/addMonths"
import max from "lodash/max"
import min from "lodash/min"
import compact from "lodash/compact"
import { ChartData } from "chart.js"

import CardBox from "@components/elements/Card/CardBox"
import LineChart from "@components/elements/Chart/LineChart"
import Box from "@components/fondation/Box/Box"
import Stack from "@components/fondation/Stack/Stack"
import { makeRateLevelLinePlugin, makeRatePointPlugin } from "@components/elements/Chart/LineChart/plugins"
import Legend from "@components/elements/Chart/Legend"
import { getRateChartOptions } from "@components/elements/Chart/LineChart/config"
import { baseRateToDataset } from "@components/elements/Chart/LineChart/chartDataProvider"
import { BaseRate, UserService } from "@model/types/user"
import RateOffer from "@store/product/RateOffer"
import {
	baseRatesToBaseRateMap,
	getBaseRateToXPoints,
	rateMapToBaseRates,
	updatesToBaseRateMap,
} from "@model/utils/charts"
import { Rate } from "@model/types/utilities"
import ChartMessage from "./ChartMessage"

const AVG_RATE_COLOR = "#D88D48"
const USER_RATE_COLOR = "#D6B943"
const REC_RATE_COLOR = "#0EA875"

const getUserBaseRatePlotData = (service: UserService, rate: Rate, labels: Date[]) => {
	const userRateEndpoint = startOfMonth(Date.now())
	const userLabels = labels.filter((it) => getUnixTime(it) <= getUnixTime(userRateEndpoint))
	const userRate = { rate: service?.userRate, effectiveDate: userRateEndpoint }

	if (service?.updates != null) {
		return rateMapToBaseRates(userLabels, {
			...updatesToBaseRateMap(service?.updates),
			[getUnixTime(userRate.effectiveDate)]: userRate,
		})
	}
	if (service?.userRateType === "base" && rate.baseRates) {
		return rateMapToBaseRates(userLabels, baseRatesToBaseRateMap([...rate.baseRates, userRate]))
	}
	return null
}

const RateChart: React.FC = observer(() => {
	const [offer] = useInstances(RateOffer)
	const { rate, service, guaranteedTerm } = offer

	const recRate: BaseRate<Date> = useMemo(
		() => ({
			effectiveDate: startOfMonth(addMonths(Date.now(), 1)),
			rate: rate?.rateSummary?.supplierRateMin,
		}),
		[rate],
	)

	const userRate: BaseRate<Date> = useMemo(
		() => ({ rate: service?.userRate, effectiveDate: startOfMonth(Date.now()) }),
		[service?.userRate],
	)

	const avgRatePlotData = useMemo(() => rate?.allRates ?? [], [rate?.allRates])

	const allLabels = useMemo(() => getBaseRateToXPoints(avgRatePlotData), [avgRatePlotData])

	const userRatePlotData = useMemo(
		() => (service && rate ? getUserBaseRatePlotData(service, rate, allLabels) : null),
		[allLabels, rate, service],
	)

	const chartData: ChartData<"line"> = useMemo(() => {
		const datasets = []

		if (userRatePlotData) datasets.push(baseRateToDataset(userRatePlotData, USER_RATE_COLOR))

		datasets.push(baseRateToDataset(avgRatePlotData, AVG_RATE_COLOR, recRate.rate))

		return {
			labels: allLabels,
			datasets,
		}
	}, [allLabels, avgRatePlotData, recRate.rate, userRatePlotData])

	// CHART PLUGINS
	const plugins = useMemo(
		() =>
			compact([
				// recommended rate plugins
				makeRateLevelLinePlugin({
					level: recRate.rate,
					start: recRate.effectiveDate,
					end: addMonths(recRate.effectiveDate, guaranteedTerm ?? 0),
					color: REC_RATE_COLOR,
				}),

				makeRateLevelLinePlugin({
					level: recRate.rate,
					start: addMonths(recRate.effectiveDate, guaranteedTerm ?? 0),
					color: REC_RATE_COLOR,
					dashed: true,
				}),

				makeRatePointPlugin(recRate.effectiveDate, recRate.rate, REC_RATE_COLOR),

				// user rate plugins
				userRate.rate ? makeRatePointPlugin(userRate.effectiveDate, userRate.rate, USER_RATE_COLOR) : null,

				userRatePlotData == null && userRate.rate
					? makeRateLevelLinePlugin({
							end: userRate.effectiveDate,
							level: userRate.rate,
							color: USER_RATE_COLOR,
							dashed: true,
					  })
					: null,
			]),
		[recRate, userRate, userRatePlotData, guaranteedTerm],
	)

	// CHART OPTIONS
	const options = useMemo(
		() => getRateChartOptions(min([recRate.rate, userRate.rate]), max([recRate.rate, userRate.rate])),
		[recRate.rate, userRate.rate],
	)

	return (
		<CardBox height="100%">
			<Stack height="100%" direction="column">
				<Box flex="1 1 250px" mb={1}>
					<LineChart key={plugins[0].id} data={chartData} plugins={plugins} options={options} />
				</Box>

				<Stack justifyContent="center" direction="row" gap={5} mb={5}>
					<Legend markerColor={AVG_RATE_COLOR}>Avg. Rate</Legend>
					{userRate.rate != null && <Legend markerColor={USER_RATE_COLOR}>Your Current Rate</Legend>}
					<Legend markerColor={REC_RATE_COLOR}>Recommended Rate</Legend>
				</Stack>

				<ChartMessage />
			</Stack>
		</CardBox>
	)
})

export default RateChart
