import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import Grid from "@components/fondation/Grid/Grid"
import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import { ZipCodeForm, FormData, validationSchema } from "@components/modules/forms/ZipCodeForm"
import SessionStore from "@store/auth/session.store"
import NavLink from "@components/elements/NavLink/NavLink"
import AuthStore from "@store/auth/auth.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"
import { AppError } from "@model/utils/error"
import { ErrorToast } from "@components/elements/Toast/Toast"
import Loader from "@components/elements/Loader/Loader"
import useQueryParams from "@components/prototypes/hooks/useQueryParams"

const TITLE_IMAGE_SRC = getImageUrl("bulb.png")
const TITLE = "Are you overpaying for energy?"
const WELCOME_MESSAGE =
	"Did you know there are hundreds of rates in some areas? Let us compare plans and shop for you to get our best rate - for free."

const errorNotification = (error: AppError) => toast(<ErrorToast title={error.title} description={error.message} />)

const WelcomePage: React.FC = observer(() => {
	const navigate = useNavigate()
	const location = useLocation()
	const [sessionStore, auth] = useInstances(SessionStore, AuthStore)

	const params = useQueryParams()

	const eternalZipCode = sessionStore.session?.preselect?.postalCode
	const eternalEmail = sessionStore.session?.preselect?.email
	const hasEternalZipCode = params.zipcode || params.zip

	const initialValues: Partial<FormData> = useMemo(
		() => ({
			zipCode: sessionStore.area?.postalCode ?? eternalZipCode,
			email: sessionStore.session?.email ?? eternalEmail,
		}),
		[sessionStore.session],
	)

	const handleSubmit = async (form: FormData) => {
		const error = await sessionStore.updateSessionArea(form.zipCode, form.email)
		if (!error) {
			navigate("/utilities")
			return
		}
		if (error.code === "UNSUPPORTED") {
			navigate("/area/unsupported")
		} else {
			errorNotification(error)
			navigate(location.pathname, { replace: true })
		}
	}

	const handleEternalForm = async (form: FormData) => {
		const isValid = await validationSchema.isValid(form)
		if (isValid) handleSubmit(form)
		else navigate(location.pathname, { replace: true })
	}

	useEffect(() => {
		if (hasEternalZipCode && eternalZipCode) handleEternalForm({ zipCode: eternalZipCode, email: eternalEmail })
	}, [])

	if (hasEternalZipCode && eternalZipCode) return <Loader size="large" center />
	return (
		<ModalBox p={4} pt={8}>
			<Grid container spacing={4} alignItems="center">
				<Grid item xs={12} textAlign="center">
					<Image src={TITLE_IMAGE_SRC} height={185} />
				</Grid>

				<Grid item xs={12} marginY={2}>
					<Typography variant="h2" textAlign="center" mb={3}>
						{TITLE}
					</Typography>
					<Typography variant="body1" color="grey.800" textAlign="center" ml={3} mr={3}>
						{WELCOME_MESSAGE}
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<ZipCodeForm loading={sessionStore.isLoading} initialValues={initialValues} onSubmit={handleSubmit} />
				</Grid>

				{!auth.isAuthorized && !sessionStore.isLoading && (
					<Grid item xs={12} textAlign="center">
						<Typography variant="body2">
							Already have an account? <NavLink to="/login">Sign In</NavLink>
						</Typography>
					</Grid>
				)}
			</Grid>
		</ModalBox>
	)
})

export default WelcomePage
