import React from "react"

import SecurityProvider from "@components/modules/security/SecureProvider"
import { provider } from "react-ioc"
import AuthStore from "@store/auth/auth.store"
import AppStore from "@store/app/app.store"
import ThemeProvider from "@theme/ThemeProvider"
import SessionService from "@services/firebase/session.service"
import AppService from "@services/firebase/app.service"
import SessionStore from "@store/auth/session.store"
import AreaService from "@services/firebase/area.service"
import AuthService from "@services/firebase/auth.service"
import UserStore from "@store/auth/user.store"
import UserService from "@services/firebase/user.service"
import UserStorageService from "@services/firebase/user.service/user.storage.service"
import BillsStore from "@store/bills.store"
import UploadableBill from "@store/UploadableBill"
import UtilityService from "@services/firebase/utility.service"
import UtilityStore from "@store/auth/utilities.store"

const appStoreProviders = [AuthStore, AppStore, SessionStore, UserStore, BillsStore, UploadableBill, UtilityStore]
const appServiceProviders = [
	AppService,
	AuthService,
	SessionService,
	AreaService,
	UserService,
	UserStorageService,
	UtilityService,
]

interface AppProviderProps {
	children?: React.ReactNode
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
	<ThemeProvider>
		<SecurityProvider>{children}</SecurityProvider>
	</ThemeProvider>
)

export default provider(...appStoreProviders, ...appServiceProviders)(AppProvider)
