import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import Chip from "@components/elements/Chip/Chip"
import Image from "@components/elements/Image/Image"
import { ChipProps } from "@mui/material/Chip"
import Stack from "@components/fondation/Stack/Stack"

// TODO add story

export interface PerformanceCardProps {
	imageSrc: string
	title: React.ReactNode
	badgeProps: ChipProps
	description: React.ReactNode
}

export const PerformanceCard: React.FC<PerformanceCardProps> = ({ imageSrc, title, badgeProps, description }) => (
	<Stack spacing={2.5} alignItems="flex-start">
		<Image src={imageSrc} height={53} />
		<Typography variant="h4">{title}</Typography>
		<Chip {...badgeProps} />
		<Typography variant="body1" color="grey.800">
			{description}
		</Typography>
	</Stack>
)

export default PerformanceCard
