/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BaseRate } from "@model/types/user"
import { getBaseRatesToYPoints, getBaseRateToXPoints } from "@model/utils/charts"
import { alpha } from "@mui/system/colorManipulator"
import { Chart as ChartJS, ChartData, ChartDataset, Color, Scriptable, ScriptableLineSegmentContext } from "chart.js"
import startOfMonth from "date-fns/startOfMonth"
import getUnixTime from "date-fns/getUnixTime"

export const regenerateRatePlot = (list: (number | null)[]): number[] => {
	let res = [...list]
	res[list.length - 1] = list[list.length - 1] ?? 0

	const firstValuableIdx = res.findIndex((v) => v != null)

	// no valuable points
	if (firstValuableIdx === -1) {
		res = res.map(() => 0)
		return res as number[]
	}

	// regenerate from start
	for (let i = 1; i < firstValuableIdx; i += 1) {
		res[i] = res[firstValuableIdx]
	}

	// latest valuable index to start regenerate gap from
	let latestIdx = 0

	// regenerate rest
	for (let i = firstValuableIdx; i < res.length; i += 1) {
		// skip till next valuable
		/* eslint-disable no-continue */
		if (res[i] == null) continue

		// regenerate gap
		if (res[i] != null && res[i - 1] === null) {
			const regLength = i - latestIdx - 1
			const delta = (res[i]! - res[latestIdx]!) / (regLength + 1)
			for (let j = latestIdx + 1, k = 1; j < i; j += 1, k += 1) {
				res[j] = res[latestIdx]! + delta * k
			}
		}
		latestIdx = i
	}

	return res as number[]
}

export const getBaseRateAreaGradientResolver =
	(recommendedRate: number): Scriptable<Color, ScriptableLineSegmentContext> =>
	(item) => {
		const nowTimestamp = getUnixTime(startOfMonth(Date.now())) * 1000

		const date = item.p0.parsed.x
		if (date < nowTimestamp) return "transparent"

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const chart = (item as any).chart as ChartJS<"line">
		if (!chart) return "transparent"

		const { ctx, chartArea, scales } = chart

		const zeroPixelY = scales.y.getPixelForValue(recommendedRate) / (chartArea.bottom + chartArea.top)

		const level = zeroPixelY < 0 ? 0 : zeroPixelY > 1 ? 1 : zeroPixelY

		const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
		gradient.addColorStop(0, alpha("#7BAE9D", 0.3))
		gradient.addColorStop(level, alpha("#7BAE9D", 0.05))
		gradient.addColorStop(level, alpha("#2CB1C8", 0.05))
		gradient.addColorStop(1, alpha("#2CB1C8", 0.3))

		return gradient
	}

export const getBaseRatePlotDashResolver =
	(data: BaseRate[]): Scriptable<number[] | undefined, ScriptableLineSegmentContext> =>
	(item) => {
		if (!data[item.p0DataIndex]?.forecast || !data[item.p1DataIndex]?.forecast) return undefined
		return [6, 4]
	}

export const baseRateToDataset = (data: BaseRate[], color: Color, recommendedRate?: number): ChartDataset<"line"> => ({
	type: "line" as const,
	data: regenerateRatePlot(getBaseRatesToYPoints(data)),
	borderWidth: 2,
	tension: 0.5,
	fill: {
		target: { value: recommendedRate },
	},
	segment: {
		borderColor: color,
		borderDash: getBaseRatePlotDashResolver(data),
		backgroundColor: recommendedRate != null ? getBaseRateAreaGradientResolver(recommendedRate) : undefined,
	},
})

export const rateChartDataProvider = (data: BaseRate[], recommendedRate?: number): ChartData<"line"> => ({
	labels: getBaseRateToXPoints(data),
	datasets: [baseRateToDataset(data, "#D88D48", recommendedRate)],
})
