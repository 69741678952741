import React from "react"
import { ToastContainer, ToastContainerProps } from "react-toastify"
import styled from "@emotion/styled"

import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import "react-toastify/dist/ReactToastify.css"

const ToastContainerStyled = styled(ToastContainer)<ToastContainerProps>({
	".Toastify__toast": {
		borderRadius: "8px",
		boxShadow: "none",
		padding: 0,
		overflow: "visible",
		minHeight: "auto",
	},
	".Toastify__toast-body": {
		padding: 0,
	},
})

export const ToastManager: React.FC<ToastContainerProps> = (props) => {
	const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))
	const position = matches ? "top-right" : "bottom-center"

	return <ToastContainerStyled hideProgressBar position={position} closeButton={false} {...props} />
}

export default ToastManager
