import styled from "@mui/material/styles/styled"
import Box from "@components/fondation/Box/Box"
import { BoxProps } from "@mui/material/Box"

export interface CardBoxProps extends BoxProps {
	elevation?: 0 | 1
	bg?: "primary" | "secondary"
}

const CardBox = styled(Box, { target: "CardBox-root" })<CardBoxProps>(({ theme, bg, elevation }) => ({
	border: "1px solid",
	borderColor: "transparent",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: bg === "primary" ? theme.palette.common.white : theme.palette.grey[50],
	boxShadow: elevation ? "0px 5px 44px rgba(14, 15, 25, 0.06)" : "none",
}))

CardBox.defaultProps = {
	p: 4,
	bg: "primary",
	elevation: 1,
}

export default CardBox
