import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useInstance } from "react-ioc"

import UtilityStore from "@store/auth/utilities.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import Message, { MessageProps } from "@components/prototypes/Message"
import { getImageUrl } from "@components/elements/Image/collection"

const getMessage = (utilityName = "utility"): MessageProps => ({
	imageSrc: getImageUrl("coffee.png"),
	title: "We're working on it!",
	message: `We have everything we need to link your ${utilityName} account. Check back soon to see your personalized rates.`,
	buttonText: "See what's available now",
})

const VerifyingPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const { utility, utilityShortName } = useInstance(UtilityStore)

	const handleSubmit = () => navigate("/dashboard")

	if (!utility) return null

	const content = getMessage(utilityShortName)

	return (
		<ModalBox flexBasis="100%" p={4}>
			<Message {...content} onSubmit={handleSubmit} />
		</ModalBox>
	)
})

export default VerifyingPage
