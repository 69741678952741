import React, { FC } from "react"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import InputAdornment from "@mui/material/InputAdornment"
import { DatePicker as MUIDatePicker, DatePickerProps as MUIDatePickerProps } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar"
import { WrapperVariantContext } from "@mui/x-date-pickers/internals"

import Stack from "../../fondation/Stack/Stack"
import Button from "../Button/Button"
import TextField from "../TextField/TextField"

type DatePickerProps = Omit<MUIDatePickerProps<string, string>, "renderInput">

const CustomActionBar = (props: PickersActionBarProps) => {
	const { onAccept, onClear, onCancel, onSetToday, actions } = props
	const wrapperVariant = React.useContext(WrapperVariantContext)

	const actionsArray = typeof actions === "function" ? actions(wrapperVariant) : actions

	if (actionsArray == null || actionsArray.length === 0) {
		return null
	}

	const actionButtons = actionsArray?.map((actionType) => {
		switch (actionType) {
			case "clear":
				return (
					<Button fullWidth variant="outlined" data-mui-test="clear-action-button" onClick={onClear} key={actionType}>
						Clear
					</Button>
				)
			case "cancel":
				return (
					<Button fullWidth variant="outlined" onClick={onCancel} key={actionType}>
						Cancel
					</Button>
				)
			case "accept":
				return (
					<Button fullWidth variant="contained" onClick={onAccept} key={actionType}>
						Apply
					</Button>
				)
			case "today":
				return (
					<Button
						fullWidth
						variant="outlined"
						data-mui-test="today-action-button"
						onClick={onSetToday}
						key={actionType}
					>
						Today
					</Button>
				)
			default:
				return null
		}
	})

	return (
		<Stack direction="row" spacing={2} sx={{ p: 4, boxSizing: "border-box" }}>
			{actionButtons}
		</Stack>
	)
}

const DatePicker: FC<DatePickerProps> = ({ value, label, onChange, InputProps, ...rest }) => (
	<LocalizationProvider dateAdapter={AdapterDateFns}>
		<MUIDatePicker
			{...rest}
			label={label}
			value={value}
			onChange={onChange}
			InputProps={{
				...InputProps,
				endAdornment: (
					<InputAdornment position="end">
						<CalendarMonthIcon />
					</InputAdornment>
				),
			}}
			components={{
				ActionBar: CustomActionBar,
				OpenPickerIcon: CalendarMonthIcon,
			}}
			componentsProps={{
				actionBar: {
					actions: ["cancel", "accept"],
				},
			}}
			renderInput={(params) => <TextField {...params} fullWidth />}
		/>
	</LocalizationProvider>
)

export default DatePicker
