import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"

const Elevation = styled("div")(({ theme }) => ({
	position: "relative",
	paddingBottom: r(8),
	zIndex: 0,
	"&:before": {
		content: "''",
		position: "absolute",
		left: r(16),
		right: r(16),
		bottom: r(0),
		top: r(8),
		borderRadius: theme.shape.borderRadius,
		background: theme.palette.grey[150],
		zIndex: -1,
	},
}))

export default Elevation
