import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { useInstance } from "react-ioc"

import UtilityStore from "@store/auth/utilities.store"
import ModalBox from "@components/elements/Modal/ModalBox"
import Message, { MessageProps } from "@components/prototypes/Message"
import { getImageUrl } from "@components/elements/Image/collection"
import { trackEvent } from "@model/utils/tracking"

const getMessage = (utilityName = "utility", variant = "default"): MessageProps => {
	if (variant === "skip")
		return {
			imageSrc: getImageUrl("high_five.png"),
			title: "Great choice!",
			message: ` Next up we'll present you with our top pick based on your preferences and the best rates in ${utilityName}.`,
			buttonText: "See my savings options",
		}

	return {
		imageSrc: getImageUrl("success.png"),
		title: "You're all set!",
		message: `While we are analyzing your rate, check out our top pick based on your preferences and the best rates in ${utilityName}`,
		buttonText: "See what's available now",
	}
}

const SuccessPage: React.FC = observer(() => {
	const { state } = useLocation()

	useEffect(() => {
		if (state?.messageVariant !== "skip") trackEvent("Utility Linked")
	}, [])

	const navigate = useNavigate()
	const { utility, utilityShortName } = useInstance(UtilityStore)

	const handleSubmit = () => navigate("/dashboard")

	if (!utility) return null

	const content = getMessage(utilityShortName, state?.messageVariant)

	return (
		<ModalBox flexBasis="100%" p={4}>
			<Message {...content} onSubmit={handleSubmit} />
		</ModalBox>
	)
})

export default SuccessPage
