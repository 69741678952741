import React from "react"
import Button, { ButtonProps } from "@mui/material/Button"
import styled from "@mui/material/styles/styled"
import EastIcon from "@mui/icons-material/East"
import { r } from "@theme/utils"
import Typography from "@components/fondation/Typography/Typography"
import Stack from "@components/fondation/Stack/Stack"

export interface LinkCardProps extends ButtonProps {
	start?: React.ReactNode
	children?: React.ReactNode
	end?: React.ReactNode
}

const Container = styled(Button)(({ theme }) => ({
	width: "100%",
	textAlign: "start",
	backgroundColor: theme.palette.common.white,
	borderRadius: theme.shape.borderRadius,
	padding: r(17, 16),
	minHeight: r(60),
	boxShadow: "0px 5px 10px rgba(14, 15, 25, 0.06)",
	userSelect: "none",
	...theme.typography.body1,
	textTransform: "none",
	":hover": {
		backgroundColor: theme.palette.common.white,
		boxShadow: "0px 5px 10px rgba(14, 15, 25, 0.16)",
	},
	".MuiTouchRipple-root": {
		color: theme.palette.grey[400],
	},
	"& img": {
		objectFit: "contain",
	},
}))

const LinkCard: React.FC<LinkCardProps> = ({ start, children, end = <EastIcon />, ...rest }) => (
	<Container {...rest}>
		<Stack width="100%" direction="row" alignItems="center" flexWrap="nowrap" gap={3}>
			{start}

			<Typography flex="1 1 100%" variant="body1" color="primary">
				{children}
			</Typography>

			{end}
		</Stack>
	</Container>
)

export default LinkCard
