import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import CardBox from "@components/elements/Card/CardBox"
import Stack from "@components/fondation/Stack/Stack"
import Switch from "@components/elements/Switch/Switch"

export interface AutopilotSwitchCard {
	value?: boolean
	onChange: (value: boolean) => void
}

export const AutopilotCardField: React.FC<AutopilotSwitchCard> = React.memo(({ value, onChange }) => (
	<CardBox p={4}>
		<Stack direction="row" alignItems="center" justifyContent="space-between" mt={-2}>
			<Typography variant="h6">Autopilot</Typography>

			<Switch value={value} onChange={(_, v) => onChange?.(v)} />
		</Stack>
		<Typography variant="caption" mb={2}>
			When this rate expires, we&lsquo;ll automatically sign you up for our best available rate. We will notify you and
			you will have an opportunity to opt out.
		</Typography>
	</CardBox>
))

export default AutopilotCardField
