import React from "react"
import { observer } from "mobx-react-lite"

import CardBox from "@components/elements/Card/CardBox"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import SubPageLayout from "@pages/account/AccountSectionLayout"
import UtilityAccountStore from "@store/auth/utility-account.store"
import AppProvider from "@root/AppProvider"
import StatusControlCard from "@components/prototypes/utilities/StatusControlCard"
import UtilityDetails from "@components/prototypes/utilities/UtilityDetails"
import Stack from "@components/fondation/Stack/Stack"
import UtilityAccountStatus from "@components/prototypes/Header/UtilityAccountStatus"
import ContainerBox from "@components/prototypes/utilities/UtilityMeta/ContainerBox"

AppProvider.register(UtilityAccountStore)

const AccountUtilityPage: React.FC = observer(() => {
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	return (
		<SubPageLayout label="Utility Account">
			<CardBox bg={isMobile ? "secondary" : "primary"} elevation={isMobile ? 0 : 1} p={isMobile ? 0 : 6}>
				<Stack spacing={6}>
					{isMobile && (
						<ContainerBox variant="card" color="primary">
							<UtilityAccountStatus />
						</ContainerBox>
					)}

					<UtilityDetails />

					<StatusControlCard />
				</Stack>
			</CardBox>
		</SubPageLayout>
	)
})

export default AccountUtilityPage
