import React, { ReactElement } from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const UploadError = (props: SvgIconProps): ReactElement => (
	<SvgIcon {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.0545 13.26C11.3416 12.97 11.3416 12.5 11.0545 12.21L9.84653 10.99L11.0545 9.77C11.3416 9.48 11.3416 9.01 11.0545 8.72C10.7673 8.42 10.2921 8.42 10.005 8.72L8.79703 9.94L7.58911 8.72C7.30198 8.42 6.83663 8.42 6.5495 8.72C6.26238 9.01 6.26238 9.48 6.5495 9.77L7.75743 10.99L6.5495 12.21C6.26238 12.5 6.26238 12.97 6.5495 13.26C6.68812 13.41 6.87624 13.48 7.06436 13.48C7.25248 13.48 7.4505 13.41 7.58911 13.26L8.79703 12.04L10.005 13.26C10.1535 13.41 10.3416 13.48 10.5297 13.48C10.7178 13.48 10.9059 13.41 11.0545 13.26ZM16.3354 7.02557C16.5686 7.02289 16.8209 7.02 17.0446 7.02C17.302 7.02 17.5 7.22 17.5 7.47V15.51C17.5 17.99 15.5099 20 13.0446 20H5.17327C2.58911 20 0.5 17.89 0.5 15.29V4.51C0.5 2.03 2.5 0 4.96535 0H10.2525C10.5 0 10.7079 0.21 10.7079 0.46V3.68C10.7079 5.51 12.203 7.01 14.0149 7.02C14.4333 7.02 14.8077 7.02318 15.1346 7.02595C15.3878 7.02809 15.6125 7.03 15.8069 7.03C15.9488 7.03 16.135 7.02786 16.3354 7.02557ZM16.6056 5.5662C15.7918 5.5692 14.8334 5.5662 14.1433 5.5592C13.0482 5.5592 12.1462 4.6482 12.1462 3.5422V0.9062C12.1462 0.4752 12.6641 0.2612 12.9591 0.5722C13.7215 1.37207 14.8885 2.59784 15.8749 3.63398C16.2746 4.05384 16.6447 4.44257 16.9462 4.7592C17.2344 5.0622 17.0235 5.5652 16.6056 5.5662Z"
			fill="#F15C5C"
		/>
	</SvgIcon>
)

export default UploadError
