import React from "react"
import { observer } from "mobx-react-lite"

import Box from "@components/fondation/Box/Box"
import { arborBenefits } from "@components/elements/Card/BenefitsCard/constants"
import Stack from "@components/fondation/Stack/Stack"
import BenefitsCard from "@components/elements/Card/BenefitsCard"

export const BenefitsList = observer(() => (
	<Stack direction="row" spacing={4} overflow="auto" paddingBottom={2}>
		{arborBenefits.map(({ id, title, content, image }) => (
			<Box minWidth={220} key={id}>
				<BenefitsCard title={title} image={image}>
					{content}
				</BenefitsCard>
			</Box>
		))}
	</Stack>
))

export default BenefitsList
