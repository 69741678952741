import React from "react"

import WestIcon from "@mui/icons-material/West"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButtonProps } from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Header, { HeaderProps } from "./Header"
import IconButton from "../Button/IconButton"

export interface ModalHeaderProps extends HeaderProps {
	BackButtonProps?: IconButtonProps
	hideBackButton?: boolean
	EndButtonProps?: IconButtonProps
	hideEndButton?: boolean
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
	BackButtonProps = null,
	hideBackButton = false,
	EndButtonProps = null,
	hideEndButton = false,
	...rest
}) => (
	<Toolbar color="secondary" variant="dense">
		<Header
			{...rest}
			before={
				hideBackButton ? null : (
					<IconButton size="small" color="primary" {...BackButtonProps}>
						{BackButtonProps?.children ?? <WestIcon />}
					</IconButton>
				)
			}
			after={
				hideEndButton ? null : (
					<IconButton size="small" color="primary" {...EndButtonProps}>
						{EndButtonProps?.children ?? <ClearIcon />}
					</IconButton>
				)
			}
		/>
	</Toolbar>
)

export default ModalHeader
