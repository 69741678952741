import React from "react"
import Loader from "@components/elements/Loader/Loader"
import DoneIcon from "@mui/icons-material/Done"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined"
import { Theme } from "@mui/material/styles"
import { StandardCSSProperties } from "@mui/system"
import { UtilityStatus } from "./type"

export const utilityInitials = (name = "Utility"): string => name[0].toUpperCase()

export const getStatusStyles = (status: UtilityStatus, theme: Theme): StandardCSSProperties => {
	if (status === "linked")
		return {
			color: theme.palette.success.contrastText,
			background: theme.palette.success.main,
		}

	if (status === "error")
		return {
			color: theme.palette.error.contrastText,
			background: theme.palette.error.main,
		}

	if (status === "partially-linked")
		return {
			color: theme.palette.warning.contrastText,
			background: theme.palette.warning.main,
		}

	// in-progress, unlinked, other...
	return {
		color: theme.palette.common.white,
		background: theme.palette.grey[700],
	}
}

export const getStatusIcon = (status: UtilityStatus): React.ReactNode => {
	if (status === "linked") return <DoneIcon fontSize="inherit" />

	if (status === "partially-linked") return <InsertLinkOutlinedIcon fontSize="inherit" />

	if (status === "error") return <PriorityHighIcon fontSize="inherit" />

	if (status === "in-progress") return <Loader speed={6} color="inherit" />

	// unlinked, other...
	return <CloseOutlinedIcon fontSize="inherit" />
}

export const getStatusMessage = (status?: UtilityStatus, accountNumber?: string): string | undefined => {
	if (!status) return accountNumber
	if (status === "linked") return accountNumber
	if (status === "partially-linked") return accountNumber
	if (status === "error") return "Utility link issue"
	if (status === "in-progress") return "Processing data"
	// unlinked, other...
	return "Not linked"
}
