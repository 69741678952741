import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import Button from "@components/elements/Button/Button"
import Loader from "@components/elements/Loader/Loader"
import Stack from "@components/fondation/Stack/Stack"
import VerificationCodeInput from "@components/elements/VerificationCodeInput/VerificationCodeInput"

export interface FormData {
	code: string
}

const defaultInitialValues: FormData = { code: "" }

const validationSchema = yup.object().shape({
	code: yup.string().length(4).required(),
})

export interface VerificationCodeFormProps {
	initialValues?: FormData
	loading?: boolean
	onSubmit: (form: FormData) => void
}

const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
	initialValues = defaultInitialValues,
	loading,
	onSubmit,
}) => {
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	})

	const onFieldChange = async (value: string) => {
		await formik.setFieldValue("code", value)

		if (value.length !== 4) {
			return
		}

		await formik.submitForm()
	}

	const fieldValid = !!(formik.touched.code && formik.errors.code)

	return (
		<Stack component="form" onSubmit={formik.handleSubmit} spacing={5} flexGrow={1} width="100%">
			<Stack flexGrow={1}>
				<VerificationCodeInput
					autoFocus
					length={4}
					value={formik.values.code}
					inputProps={{ type: "number", min: "0", name: "code", inputMode: "numeric", pattern: "[0-9]*" }}
					onChange={onFieldChange}
					withError={fieldValid}
				/>
			</Stack>

			<Button
				fullWidth
				variant="contained"
				size="large"
				type="submit"
				disabled={loading || fieldValid}
				endIcon={loading && <Loader />}
			>
				Continue
			</Button>
		</Stack>
	)
}

export default VerificationCodeForm
