import { v4 as uuid } from "uuid"

interface IHowItWorksItem {
	id: string
	title: string
	content: string
}

export const getHowItWorksCopy = (utilityName = "utility"): IHowItWorksItem[] => [
	{
		id: uuid(),
		title: "We find the best rates for you",
		content: "We monitor the energy market daily for the best rates in your area.",
	},
	{
		id: uuid(),
		title: "Request your rate for free",
		content: "Request this plan today in a few clicks. Rates change daily, and can increase anytime, so don't wait.",
	},
	{
		id: uuid(),
		title: "We'll handle the busy work",
		content: `We'll handle all the paperwork and phone calls to request your new rate through ${utilityName}.`,
	},
	{
		id: uuid(),
		title: "Start saving",
		content: `Your new energy plan appear on your ${utilityName} within 1-2 billing cycles. Track your savings in your Arbor account.`,
	},
]

export const colors: string[] = ["#7BAE9D", "#D88D48", "#D6B943", "#2CB1C8"]
