import React from "react"
import { PairRowData } from "@components/elements/Table/SimpleTable"
import { moneyFormatter, percentFormatter, rateFormatter } from "@root/lib/numberUtils"
import { format } from "date-fns"
import { DATE_FORMAT } from "@components/modules/forms/common/validations"
import RateOffer from "@store/product/RateOffer"

export interface SavingsHeader {
	label: string
	title: string
	description: React.ReactNode
}

export const renderDate = (date?: Date | null, alt = " - "): string => {
	if (date == null) return alt
	return format(date, DATE_FORMAT)
}

export const renderRate = (rate?: number | null, alt = " - "): string => {
	if (rate == null) return alt
	return rateFormatter.format(rate)
}

export const getPotentialSavings = (offer: RateOffer): number => {
	// savings
	if (offer.productStore.activePlan === "lowest-price") {
		if (
			(offer.serviceStatus === "user-approving" && (offer.isLockRecommended || offer.isSwitchRecommended)) ||
			offer.serviceStatus === "searching" ||
			offer.serviceStatus === "review"
		) {
			return offer.savingsToCurrent
		}

		return offer.savingsToAvg
	}

	// renewable
	return 0
}

export const makeRateTable = (offer: RateOffer): PairRowData[] => {
	// common
	const recommendedRate: PairRowData = {
		label: "Recommended Rate",
		value: (
			<>
				{renderRate(offer.recommendedRate)} <sub>/kWh</sub>
			</>
		),
	}

	const guaranteedTerm = {
		label: "Guaranteed Term",
		value: (
			<>
				{offer.guaranteedTerm} <sub>months</sub>
			</>
		),
	}

	// savings
	if (offer.productStore.activePlan === "lowest-price") {
		if (
			(offer.serviceStatus === "user-approving" && (offer.isLockRecommended || offer.isSwitchRecommended)) ||
			offer.serviceStatus === "searching" ||
			offer.serviceStatus === "review"
		) {
			return [
				{
					label: "Your Current Rate",
					value: (
						<>
							{renderRate(offer.userRate)} <sub>/kWh</sub>
						</>
					),
				},
				recommendedRate,
				guaranteedTerm,
			]
		}

		return [
			{
				label: "Average Rate",
				value: (
					<>
						<s>{renderRate(offer.avgRate)}</s> <sub>/kWh</sub>
					</>
				),
			},
			recommendedRate,
			guaranteedTerm,
		]
	}

	// renewable
	return [
		recommendedRate,
		guaranteedTerm,
		{
			label: "Renewables",
			value: <>{percentFormatter.format(1)}</>,
		},
	]
}

export const getSavingsHeader = (offer: RateOffer): SavingsHeader => {
	const isRenewable = offer.productStore.activePlan === "best-renewable"
	const defaultLabel = isRenewable ? "Best Renewable Option" : "Best Savings Option"

	if (offer.service?.status === "review") {
		return {
			label: defaultLabel,
			title: "",
			description: <br />,
		}
	}

	// savings
	if (!isRenewable) {
		if (offer.service?.status === "user-approving") {
			if (offer.isSwitchRecommended) {
				return {
					label: defaultLabel,
					title: "Switch to a lower rate",
					description: (
						<>
							Our best rate is <b>{percentFormatter.format(offer.savingsToCurrentPercent)} lower</b> than your current
							rate of {renderRate(offer.userRate)}. It is guaranteed for {offer.guaranteedTerm} months and could
							<b> save you up to {moneyFormatter.format(offer.savingsToCurrent)}</b> over that time.
						</>
					),
				}
			}

			if (offer.isLockRecommended) {
				return {
					label: defaultLabel,
					title: "Protect against rising rates",
					description: (
						<>
							Your current rate has increased {percentFormatter.format(offer.savingsToCurrentPercent)} over the last{" "}
							{offer.guaranteedTerm} months. Our best rate is locked for
							{offer.guaranteedTerm} months and could save{" "}
							<b> save you up to {moneyFormatter.format(offer.savingsToCurrent)}</b> over that time.
						</>
					),
				}
			}
		}

		if (offer.service?.status === "searching") {
			return {
				label: defaultLabel,
				title: "You're on a great rate",
				description: (
					<>
						It looks like your current rate is lower than the best available market rate. We check daily for new rates,
						and will let you know when we have an update. If you still want this rate, follow the link below.
					</>
				),
			}
		}

		return {
			label: defaultLabel,
			title: "Lowest Fixed Rate",
			description: (
				<>
					Our best rate is <b>{percentFormatter.format(offer.savingsToAvgPercent)} lower</b> than the average rate of{" "}
					{renderRate(offer.avgRate)}. It is guaranteed for {offer.guaranteedTerm} months and could
					<b> save you up to {moneyFormatter.format(offer.savingsToAvg)}</b> compared to the average.
				</>
			),
		}
	}

	// renewable
	return {
		label: defaultLabel,
		title: "Switch to 100% renewable energy",
		description: (
			<>
				Our best renewable rate of {renderRate(offer.recommendedRate)} is guaranteed for {offer.guaranteedTerm} months,
				and is <b>{percentFormatter.format(offer.savingsToAvgPercent)} lower</b> than the average renewable rate of{" "}
				{renderRate(offer.avgRate)}.
			</>
		),
	}
}
