import React, { useMemo, useState } from "react"
import { useInstances } from "react-ioc"

import SessionStore from "@store/auth/session.store"
import UserStore from "@store/auth/user.store"
import { AddressFormData } from "@components/modules/forms/auth/AddressForm/types"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import { observer } from "mobx-react-lite"
import { AppMessage } from "@model/utils/error"
import NavLink from "@components/elements/NavLink/NavLink"
import EditAddressForm from "../../modules/forms/auth/AddressForm/EditAddressForm"

export const editAddressErrorCodeToMessage = (code: string): React.ReactNode => {
	if (code === "UNSUPPORTED_AREA") return "Sorry, we're not able to service this area right now"
	if (code === "UNAVAILABLE_ACTION")
		return (
			<>
				It looks like you need to change your utility <br />
				Please <NavLink to="/account/address/move">reach out to support</NavLink> to change your address
			</>
		)
	return "We are having trouble, please try again later"
}

interface EditableAddressProps {
	onSubmit?: () => void
	onCancel?: () => void
	cancelable?: boolean
}

export const EditableAddress: React.FC<EditableAddressProps> = observer(({ cancelable, onSubmit, onCancel }) => {
	const [sessionStore, userStore] = useInstances(SessionStore, UserStore)

	const [error, setError] = useState<AppMessage | null>(null)
	const [loading, setLoading] = useState(false)

	const initialValues: Partial<AddressFormData> = useMemo(() => {
		const place = userStore.user?.place ?? null
		return {
			postalCode: sessionStore.area?.postalCode,
			apartment: userStore.user?.apartment,
			owner: userStore.user?.property?.owner,
			...place,
		}
	}, [sessionStore.area, userStore.user])

	const handleSubmit = async (form: AddressFormData) => {
		setError(null)
		setLoading(true)
		const updateError = await userStore.updateAddress(form)
		setLoading(false)
		if (!updateError) {
			onSubmit?.()
			return
		}
		setError(updateError)
	}

	return (
		<EditAddressForm
			initialValues={initialValues}
			loading={loading}
			onCancel={onCancel}
			cancelable={cancelable}
			onSubmit={handleSubmit}
			submittable={!userStore.isLoading}
		>
			{!!error && (
				<Alert severity="error">
					<AlertTitle>Are you moving?</AlertTitle>
					{editAddressErrorCodeToMessage(error.code)}
				</Alert>
			)}
		</EditAddressForm>
	)
})
