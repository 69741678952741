import { Color } from "chart.js"

export const NULL = null as unknown as undefined

export const drawPoint = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	radius: number,
	color: Color = "black",
): void => {
	ctx.beginPath()
	ctx.fillStyle = color
	ctx.arc(x, y, radius, 0, 2 * Math.PI)
	ctx.fill()
	ctx.closePath()
}

export const drawLine = (
	ctx: CanvasRenderingContext2D,
	x0: number,
	y0: number,
	x1: number,
	y1: number,
	color: Color = "black",
	dashed = false,
): void => {
	const dashSections = dashed ? [6, 4] : []
	ctx.beginPath()
	ctx.setLineDash(dashSections)
	ctx.lineWidth = 2
	ctx.strokeStyle = color
	ctx.moveTo(x0, y0)
	ctx.lineTo(x1, y1)
	ctx.stroke()
	ctx.closePath()
}
