import React from "react"

import { r } from "@theme/utils"
import Image from "@components/elements/Image/Image"
import ListItem from "./ListItem"
import ListItemAvatar from "./ListItemAvatar"
import ListItemText from "./ListItemText"

export interface RichListItemProps {
	imgSrc: string
	title?: string
	description: React.ReactNode
}

const RichListItem: React.FC<RichListItemProps> = React.memo(({ imgSrc, title, description }) => (
	<ListItem disableGutters>
		<ListItemAvatar
			sx={{
				width: r(75),
				alignSelf: "flex-start",
				textAlign: "center",
				marginRight: r(15),
				marginBlock: r(6),
			}}
		>
			<Image src={imgSrc} width={69} height={45} style={{ objectFit: "contain" }} />
		</ListItemAvatar>
		<ListItemText
			primary={title}
			secondary={description}
			primaryTypographyProps={{
				variant: "h5",
				mb: 1,
			}}
			secondaryTypographyProps={{
				variant: "body1",
				sx: (t) => ({
					color: t.palette.grey[800],
				}),
			}}
		/>
	</ListItem>
))

export default RichListItem
