import styled from "@mui/material/styles/styled"
import { NavLink as RouterNavLink } from "react-router-dom"

const NavLink = styled(RouterNavLink)<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
	fontSize: "inherit",
	color: theme.palette.grey[900],
	fontWeight: 700,
	letterSpacing: 0,

	userSelect: disabled ? "none" : undefined,
	opacity: disabled ? 0.7 : 1,

	// icons support
	".MuiSvgIcon-root": {
		fontSize: "1.2em",
		verticalAlign: "middle",
		marginInline: ".3em",
	},
}))

export default NavLink
