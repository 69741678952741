import React from "react"
import Typography from "@components/fondation/Typography/Typography"
import UserAvatar from "@components/prototypes/Header/UserAvatar"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import ElectricalServicesOutlinedIcon from "@mui/icons-material/BoltOutlined"
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined"
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined"
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined"
import { getImageUrl } from "@components/elements/Image/collection"
import { MenuItemProps } from "./MenuItem"

export const getSmarterListMock = [
	{
		description: (
			<>
				Avoiding <b>4,404</b> pounds of coal from being burned
			</>
		),
		imgSrc: getImageUrl("power.png"),
	},
	{
		description: (
			<>
				Driving <b>9,879</b> fewer miles in your car
			</>
		),
		imgSrc: getImageUrl("renewables.png"),
	},
	{
		description: (
			<>
				Growing <b>66</b> tree seedlings for 10 years
			</>
		),
		imgSrc: getImageUrl("wallet.png"),
	},
	{
		description: (
			<>
				Switching <b>151</b> incandescent light bulbs to LED
			</>
		),
		imgSrc: getImageUrl("bulb.png"),
	},
	{
		description: (
			<>
				Recycling for <b>7</b> years
			</>
		),
		imgSrc: getImageUrl("globe_03.png"),
	},
]

export const exampleOptions = [
	{
		title: "About You",
		description: "Tell us about your home and where to send your savings recommendations.",
		imgSrc: getImageUrl("power.png"),
	},
	{
		title: "Preferences",
		description: "Choose how you want Arbor to help you save on energy.",
		imgSrc: getImageUrl("renewables.png"),
	},
	{
		title: "Link Your Utility Account",
		description: "Share your energy bill info so we can help you shop for a better rate.",
		imgSrc: getImageUrl("wallet.png"),
	},
]

export const menuListMock: MenuItemProps[][] = [
	[
		{
			id: "settings",
			primaryText: "Amanda Smith",
			secondaryText: "amanda-smith@gmail.com",
			icon: <UserAvatar>A</UserAvatar>,
		},
	],

	[
		{
			id: "address",
			active: true,
			primaryText: (
				<>
					Address{" "}
					<Typography component="span" variant="subtitle1" color="grey.700">
						Moving? Let us know.
					</Typography>
				</>
			),
			icon: <MapOutlinedIcon />,
		},
		{
			id: "utility",
			primaryText: "Utility Account",
			icon: <ElectricalServicesOutlinedIcon />,
		},
	],

	[
		{
			id: "faq",
			primaryText: "FAQs",
			icon: <LiveHelpOutlinedIcon />,
		},
		{
			id: "support",
			primaryText: "Support",
			icon: <SupportOutlinedIcon />,
		},
		{
			id: "legal",
			primaryText: "Legal",
			icon: <PolicyOutlinedIcon />,
		},
	],
]
