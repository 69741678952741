import React from "react"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import IconButton from "@components/elements/Button/IconButton"
import { AppMessage } from "@model/utils/error"
import Tooltip from "@components/elements/Tooltip/Tooltip"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import Typography from "@components/fondation/Typography/Typography"
import HelperDrawer from "@components/elements/Drawer/HelperDrawer"

export interface InfoTooltipProps {
	message: AppMessage
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ message }) => {
	const isMobile = useMediaQuery((t) => t.breakpoints.down("md"))
	const [open, setOpen] = React.useState(false)

	if (isMobile)
		return (
			<>
				{open && <HelperDrawer open content={message} onClose={() => setOpen(false)} />}
				<IconButton onClick={() => setOpen(true)}>
					<InfoOutlinedIcon
						sx={(theme) => ({
							cursor: "pointer",
							color: theme.palette.grey[300],
						})}
					/>
				</IconButton>
			</>
		)

	return (
		<Tooltip title={<Typography variant="body2">{message?.message}</Typography>} placement="top-end">
			<IconButton>
				<InfoOutlinedIcon
					sx={(theme) => ({
						cursor: "pointer",
						color: theme.palette.grey[300],
					})}
				/>
			</IconButton>
		</Tooltip>
	)
}

export default InfoTooltip
