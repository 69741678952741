import { makeAutoObservable } from "mobx"

class ImageViewerStore {
	constructor() {
		makeAutoObservable(this)
	}

	activeImgSrc = ""

	nextImgSrc = ""

	activeImage = 1

	activeUtility = ""

	get isOpened(): boolean {
		return !!this.activeImgSrc.length
	}

	get showNextButton(): boolean {
		return !!this.nextImgSrc
	}

	get showPrevButton(): boolean {
		return this.activeImage > 1
	}

	open = (utility?: string): void => {
		if (!utility) {
			return
		}

		this.activeUtility = utility

		this.loadImage(this.activeUtility, this.activeImage)
	}

	close = () => {
		this.nextImgSrc = ""
		this.activeImgSrc = ""
		this.activeUtility = ""
	}

	loadImage = (utility: string, index: number) => {
		const activeImageSrc = `https://arbor-static.web.app/utility/${utility}/bill/${index}.png`
		const nextImageSrc = `https://arbor-static.web.app/utility/${utility}/bill/${index + 1}.png`

		const currentImg = new Image()
		const nextImg = new Image()

		currentImg.onload = () => {
			console.log("Loading active image done.")
			this.activeImgSrc = activeImageSrc
		}

		nextImg.onload = () => {
			console.log("Loading next image done.")
			this.nextImgSrc = nextImageSrc
		}

		nextImg.onerror = () => {
			console.log("Loading next image failed. Looks like there are no more images.")
			this.nextImgSrc = ""
		}

		currentImg.src = activeImageSrc
		nextImg.src = nextImageSrc
	}

	next = () => {
		this.activeImage += 1
		this.loadImage(this.activeUtility, this.activeImage)
	}

	prev = () => {
		if (this.activeImage <= 1) {
			return
		}
		this.activeImage -= 1
		this.loadImage(this.activeUtility, this.activeImage)
	}
}

export default ImageViewerStore
