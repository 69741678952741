import React from "react"
import { AppImageViewer } from "@components/prototypes/AppImageViewer"
import ToastManager from "@components/modules/layouts/ToastManager/ToastManager"
import { startSentry } from "@model/utils/error"
import * as Sentry from "@sentry/react"

import AppProvider from "./AppProvider"
import AppRouter from "./AppRouter"
import Tags from "./Tags"

startSentry()

const App: React.FC = () => (
	<AppProvider>
		<Tags />
		<AppRouter />
		<AppImageViewer />
		<ToastManager />
	</AppProvider>
)

export default Sentry.withProfiler(App)
