import React from "react"
import Box from "@components/fondation/Box/Box"
import Typography from "@components/fondation/Typography/Typography"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import Button from "@components/elements/Button/Button"
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined"
import { useLocation, useNavigate } from "react-router-dom"
import { r } from "@theme/utils"
import AppBar from "@components/elements/HeaderBar/AppBar"
import ModalHeader from "@components/elements/HeaderBar/ModalHeader"
import { BoxProps } from "@mui/material/Box"

export interface SubPageLayoutProps extends BoxProps {
	label?: React.ReactNode
	onBack?: React.MouseEventHandler
}

export const SubPageLayout: React.FC<SubPageLayoutProps> = ({ label, children, onBack, ...rest }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const handleBack = onBack ?? (() => navigate(location.state?.backPath ?? (isMobile ? "/account" : "/")))
	const showBackButtonOffset = useMediaQuery("(max-width:1100px)")

	return (
		<Box {...rest}>
			{isMobile ? (
				<AppBar color="primary">
					<ModalHeader BackButtonProps={{ onClick: handleBack }} hideEndButton>
						<Typography variant="h5">{label}</Typography>
					</ModalHeader>
				</AppBar>
			) : (
				<>
					<Box position="fixed" left={r(40)} top={showBackButtonOffset ? r(14) : "auto"} zIndex={9999}>
						<Button variant="text" onClick={handleBack} startIcon={<KeyboardBackspaceOutlinedIcon />}>
							Back
						</Button>
					</Box>
					<Typography variant="h3" mb={4.5}>
						{label}
					</Typography>
				</>
			)}

			{children}
		</Box>
	)
}

export default SubPageLayout
