/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from "@model/types/user"
import { AppError, catchException, createError } from "@model/utils/error"
import UserService from "@services/firebase/user.service"
import { PreferenceRenewableOption } from "@services/firebase/user.service/request.types"
import { Unsubscribe } from "firebase/firestore"
import { autorun, makeAutoObservable, reaction, toJS, when } from "mobx"
import { inject } from "react-ioc"
import { AddressFormData, MoveAddressFormData } from "@components/modules/forms/auth/AddressForm/types"
import { formToPlace } from "@components/modules/forms/auth/AddressForm/utils"
import { DEFAULT_COUNTRY_CODE } from "@model/constants/utilities/app"
import AreaService from "@services/firebase/area.service"
import { DATE_FORMAT } from "@components/modules/forms/common/validations"
import { format } from "date-fns"
import { SupportFormData } from "@components/modules/forms/SupportForm"
import AuthStore from "./auth.store"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

class UserStore {
	// injections

	areaService = inject(this, AreaService)

	userService = inject(this, UserService)

	authStore = inject(this, AuthStore)

	// constructors

	constructor() {
		makeAutoObservable(this)

		reaction(
			() => {
				if (this.authStore.isAuthChecked) return this.userId
				return null
			},
			(userId) => {
				if (!userId) {
					this.setUser(null)
					return
				}
				this.subscribeOnUserUpdate(userId)
			},
		)

		if (IS_DEV_MODE) autorun(() => console.log("USER", toJS(this.user)))
	}

	// attributes

	unsubscribe: Unsubscribe | null = null

	user: User | null = null

	isLoading = false

	error: AppError | null = null

	isUserChecked = false

	// computed

	get currentUtilityId(): string | undefined {
		return this.user?.services?.electric?.utility?.id
	}

	get isInit(): boolean {
		return !!this.user?.createdAt
	}

	get userId(): string | undefined {
		return this.authStore.userId
	}

	// actions

	private setUser = (user: typeof this.user) => {
		this.isUserChecked = true
		this.user = user
	}

	whenUserUpdated = async (predicate: (session: User) => boolean = () => true) => {
		const lastModified = this.user?.modifiedAt?.toString()
		await when(() => {
			if (!this.user) return false
			const newModified = this.user?.modifiedAt?.toString()
			if (lastModified === newModified) return false
			return predicate(this.user)
		})
	}

	subscribeOnUserUpdate = (userId: string) => {
		try {
			if (this.unsubscribe) {
				this.unsubscribe()
			}
			this.unsubscribe = this.userService.subscribe(userId, (user) => {
				this.setUser(user)
				return false
			})
		} catch (error) {
			catchException(error)
			this.unsubscribe = null
		}
	}

	inUserScope = async ({
		call,
		onError,
		timeout = 60_000,
	}: {
		call: (userId: string) => string | void | Promise<string | void>
		onError?: (error: unknown) => AppError | null
		timeout?: number
	}) => {
		try {
			if (!this.userId) {
				this.error = createError("USER_NOT_INITED", "Action is not allowed")
				return this.error
			}

			this.isLoading = true
			this.error = null

			const timer = new Promise((resolve) => {
				setTimeout(() => resolve("REQUEST_TIMEOUT"), timeout)
			})

			const res = await Promise.any([call(this.userId), timer])

			if (typeof res === "string") throw new Error(res)
		} catch (error: any) {
			catchException(error)

			this.error = onError?.(error) ?? createError(error.message)
		} finally {
			this.isLoading = false
		}
		return this.error
	}

	updatePreferences = async (preference: PreferenceRenewableOption) =>
		this.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "preference-renewable", {
					serviceType: "electric", // temp
					selectedOption: preference,
					autopilot: false,
				})

				await this.whenUserUpdated((user) => user.renewable === preference || !user.lastActionSuccess)
			},
			onError: (error: any) => {
				if (error.message === "NOT_FOUND") {
					return createError("NOT_FOUND", "Current area not supported")
				}
				return null
			},
		})

	updateAutopilot = async (autopilot: boolean) =>
		this.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "preference-autopilot", {
					serviceType: "electric", // temp
					autopilot,
				})
			},
			onError: (error: any) => {
				if (error.message === "NOT_FOUND") {
					return createError("NOT_FOUND", "Current area not supported")
				}
				return null
			},
		})

	updateAddress = (form: AddressFormData) =>
		this.inUserScope({
			call: async (userId) => {
				const { apartment, owner, propertyClass } = form
				const place = formToPlace(form)

				if (!place) return "INVALID_PLACE"

				const currentPostalCode = this.user?.area?.postalCode

				if (currentPostalCode != null && place.postalCode !== currentPostalCode) {
					const area = await this.areaService.getArea(`${DEFAULT_COUNTRY_CODE}-${place.postalCode}`)

					if (area == null || !area?.utilities) return "UNSUPPORTED_AREA"

					const currentUtility = area.utilities?.electric?.find((utility) => utility.id === this.currentUtilityId)

					if (currentUtility == null) return "UNAVAILABLE_ACTION"

					await this.userService.updateLog(userId, "select-area", {
						postalCode: place?.postalCode,
						countryCode: DEFAULT_COUNTRY_CODE,
					})

					await this.whenUserUpdated((user) => user.area?.postalCode === place.postalCode || !!user.lastActionError)
				}

				await this.userService.updateLog(userId, "select-address", {
					apartment,
					address: place.fullAddress || place.address,
					city: place.city,
					state: place.state,
					zipcode: place.postalCode,
					place,
					propertyClass,
					owner,
				})

				await this.whenUserUpdated()
			},
		})

	moveAddress = (form: MoveAddressFormData) =>
		this.inUserScope({
			call: async (userId) => {
				const { apartment } = form
				const place = formToPlace(form)

				if (!place) return "INVALID_PLACE"

				await this.userService.updateLog(userId, "create-ticket", {
					form: "customer-issue",
					subject: "Move Address Request",
					name: this.user?.name,
					email: this.user?.email,
					description: `
					Move out: ${format(form.startDate, DATE_FORMAT)}
					Current address: ${this.user?.place?.address}, ${
						this.user?.apartment ? "Apt ".concat(this.user?.apartment).concat(", ") : ""
					}${this.user?.place?.city}, ${this.user?.place?.state} ${this.user?.place?.postalCode}
					Current Utility: ${this.user?.services?.electric?.utility.name}
					Move in: ${format(form.endDate, DATE_FORMAT)}
					New address: ${form.address}, ${form.apartment ? "Apt ".concat(form.apartment).concat(", ") : ""}${form.city}, ${
						form.state
					} ${form.postalCode}
					`,
					apartment,
					address: place.fullAddress || place.address,
					city: place.city,
					state: place.state,
					zipcode: place.postalCode,
					place,
					dateMoveIn: form.endDate,
					dateMoveOut: form.startDate,
				})
			},
		})

	supportRequest = (form: SupportFormData) =>
		this.inUserScope({
			call: async (userId) => {
				await this.userService.updateLog(userId, "create-ticket", {
					form: "customer-issue",
					name: this.user?.name,
					email: this.user?.email,
					subject: form.subject,
					description: form.description,
				})
			},
		})
}

export default UserStore
