import styled from "@mui/material/styles/styled"
import Typography from "@mui/material/Typography/Typography"
import { r } from "@theme/utils"

export type LegendProps = {
	markerColor?: string
}

const Legend = styled(Typography)<LegendProps>(({ markerColor }) => ({
	"&::before": {
		content: "''",
		display: "inline-block",
		background: markerColor,
		borderRadius: "50%",
		marginRight: r(6),
		height: r(8),
		width: r(8),
	},
}))

Legend.defaultProps = {
	variant: "caption",
	color: "grey.700",
}

export default Legend
