import { shortRateFormatter } from "@root/lib/numberUtils"
import { ChartOptions } from "chart.js"
import { enUS } from "date-fns/locale"

export const getRateChartOptions = (minRate?: number, maxRate?: number): ChartOptions<"line"> => ({
	maintainAspectRatio: false,
	layout: {
		padding: {
			left: 5,
			right: 5,
			top: 5,
			bottom: 5,
		},
	},
	// interaction: {
	// 	intersect: false,
	// 	mode: "index",
	// },
	elements: {
		point: {
			radius: 0,
			hitRadius: 0,
		},
	},
	plugins: {},
	scales: {
		x: {
			type: "time",
			time: {
				unit: "year",
			},

			grid: {
				borderColor: "#E7E8EB",
				color: "#E7E8EB",
				borderDash: [6, 4],
			},

			adapters: {
				date: {
					locale: enUS,
				},
			},
		},
		y: {
			suggestedMin: minRate != null ? minRate - 0.001 : undefined,
			suggestedMax: maxRate != null ? maxRate + 0.001 : undefined,

			grid: {
				display: false,
				borderColor: "#E7E8EB",
			},

			ticks: {
				color: "#6E7385",
				callback(tickValue, idx, ticks) {
					if (ticks.length === idx + 1) return "$/kWh"
					return shortRateFormatter.format(Number(tickValue))
				},
			},
		},
	},
})
