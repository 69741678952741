import React from "react"
import { useInstance } from "react-ioc"
import AppStore from "@store/app/app.store"
import { observer } from "mobx-react-lite"

import LoadingLayout from "../layouts/LoadingOutlet"

export interface SecurityProviderProps {
	children: React.ReactNode
}

const SecurityProvider: React.FC<SecurityProviderProps> = observer(({ children }) => {
	const appStore = useInstance(AppStore)
	return <LoadingLayout loading={!appStore.isAppReady}>{children}</LoadingLayout>
})

export default SecurityProvider
