import React from "react"
import Grid from "@components/fondation/Grid/Grid"
import FormControl from "@mui/material/FormControl"
import { FormikProvider, useFormik } from "formik"
import * as yup from "yup"

import Button from "@components/elements/Button/Button"
import Loader from "@components/elements/Loader/Loader"
import Stack from "@components/fondation/Stack/Stack"
import FormTextField from "./common/FormTextField"

export interface SupportFormData {
	subject: string
	description: string
}

export const validationSchema = yup.object().shape({
	subject: yup.string().required("Please add a subject"),
	description: yup.string().required("Please describe your issue"),
})

const defaultInitialValues: SupportFormData = { subject: "", description: "" }

export interface SupportFormProps {
	initialValues?: Partial<SupportFormData>
	loading?: boolean
	onSubmit: (form: SupportFormData) => void
}

const SupportForm: React.FC<SupportFormProps> = ({ initialValues = {}, loading, onSubmit }) => {
	const formik = useFormik({
		initialValues: { ...defaultInitialValues, ...initialValues },
		validationSchema,
		isInitialValid: false,
		validateOnChange: true,
		onSubmit,
	})

	return (
		<FormikProvider value={formik}>
			<FormControl component="form" fullWidth onSubmit={formik.handleSubmit}>
				<Stack>
					<Grid container spacing={4} mb={6}>
						<Grid item xs={12}>
							<FormTextField name="subject" label="Subject" fullWidth />
						</Grid>

						<Grid item xs={12}>
							<FormTextField name="description" label="Message" multiline rows={4} fullWidth />
						</Grid>
					</Grid>

					<Button
						fullWidth
						variant="contained"
						size="large"
						type="submit"
						disabled={loading}
						endIcon={loading && <Loader />}
					>
						Send Message
					</Button>
				</Stack>
			</FormControl>
		</FormikProvider>
	)
}

export default SupportForm
