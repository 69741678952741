import React, { useEffect, useMemo } from "react"
import { useInstances } from "react-ioc"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import SessionStore from "@store/auth/session.store"
import UserStore from "@store/auth/user.store"
import UtilityStore from "@store/auth/utilities.store"
import { AddressFormData } from "@components/modules/forms/auth/AddressForm/types"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import AddressForm from "@components/modules/forms/auth/AddressForm/AddressForm"
import Stack from "@components/fondation/Stack/Stack"
import NavLink from "@components/elements/NavLink/NavLink"
import { SUPPORT_ROUTE } from "@model/constants/utilities/app"
import AboutYouHeader from "./AboutYouHeader"

const TITLE = "Address"
const MESSAGE = `Your address impacts the energy plans we can find. Please contact [support](${SUPPORT_ROUTE}) if you need help.`

export const editAddressErrorCodeToMessage = (code: string, utilityName?: string): React.ReactNode => {
	if (code === "UNSUPPORTED_AREA") return "Sorry, we're not able to service this area right now"
	if (code === "UNAVAILABLE_ACTION")
		return (
			<>
				This address is not serviced by {utilityName ?? "your selected utility"} <br />
				Please <NavLink to="/area">select a new utility</NavLink> or{" "}
				<NavLink to="/account/address/move">reach out to support</NavLink> to change your address
			</>
		)
	return "We are having trouble, please try again later"
}

const AccountAddressPage: React.FC = observer(() => {
	const navigate = useNavigate()
	const location = useLocation()
	const [sessionStore, userStore, utilityStore] = useInstances(SessionStore, UserStore, UtilityStore)
	const utilityName = utilityStore.utilityShortName

	const handleSubmit = async (form: AddressFormData) => {
		const error = await userStore.updateAddress(form)
		if (error) return

		if (form.propertyClass === "commercial") {
			navigate("/contact-us", { state: { backPath: location.pathname } })
			return
		}

		navigate("/enrollment/preferences")
	}

	const initialValues: Partial<AddressFormData> = useMemo(() => {
		const place = userStore.user?.place ?? null
		return {
			postalCode: sessionStore.area?.postalCode,
			apartment: userStore.user?.apartment,
			owner: userStore.user?.property?.owner,
			propertyClass: userStore.user?.property?.class,
			...place,
		}
	}, [sessionStore.area, userStore.user])

	useEffect(() => {
		if (!userStore.isInit && !userStore.isLoading) navigate("../register")
	}, [userStore.isLoading])

	const { error, isLoading } = userStore

	return (
		<Stack>
			<AboutYouHeader title={TITLE} message={MESSAGE} />
			<AddressForm loading={isLoading} onSubmit={handleSubmit} initialValues={initialValues}>
				{!!error && (
					<Alert severity="error">
						<AlertTitle>Wrong address</AlertTitle>
						{editAddressErrorCodeToMessage(error.code, utilityName)}
					</Alert>
				)}
			</AddressForm>
		</Stack>
	)
})

export default AccountAddressPage
