import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify"
import { useInstances } from "react-ioc"

import SubPageLayout from "@pages/account/AccountSectionLayout"
import CardBox from "@components/elements/Card/CardBox"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import AccountForm, { FormData as AccountFormData } from "@components/modules/forms/AccountForm"
import AppProvider from "@root/AppProvider"
import AccountSettingsStore from "@store/auth/account.store"
import VerificationCodeForm, { FormData as CodeFormData } from "@components/modules/forms/auth/VerificationCodeForm"
import { parseFormattedPhoneNumber } from "@root/lib/numberUtils"
import AuthStore from "@store/auth/auth.store"
import { SuccessToast, WarnToast } from "@components/elements/Toast/Toast"
import { useNavigate } from "react-router-dom"
import Typography from "@components/fondation/Typography/Typography"
import { AppError } from "@model/utils/error"
import Box from "@components/fondation/Box/Box"
import Alert from "@components/elements/Alert/Alert"
import AlertTitle from "@components/elements/Alert/AlertTitle"
import Stack from "@components/fondation/Stack/Stack"
import NavLink from "@components/elements/NavLink/NavLink"
import { updatedDiff } from "deep-object-diff"
import { formHasProps } from "@store/auth/utils"
import Button from "@components/elements/Button/Button"

AppProvider.register(AccountSettingsStore)

export const signUpErrorCodeToMessage = (code: string): React.ReactNode => {
	if (code === "INVALID_CODE") return <>Provided verification code is incorrect</>
	if (code === "TOO_MANY_ATTEMPTS_WAIT") return <>Too many attempts. Please wait 10 minutes before trying again</>
	if (code === "TOO_MANY_ATTEMPTS") return <>Too many attempts</>
	if (code === "EMAIL_EXISTS") return <>The email entered is already in use by another user.</>
	return "We are having trouble, please try again later"
}

const AccountSettingsPage: React.FC = observer(() => {
	const [settings, auth] = useInstances(AccountSettingsStore, AuthStore)
	const navigate = useNavigate()

	const [error, setError] = useState<AppError | null>(null)

	const logoutNotification = () =>
		toast(<WarnToast title="Logout" description="Auth data was changed so we had to log you out" />, {
			autoClose: 10_000,
		})

	const successUpdateMessage = () =>
		toast(<SuccessToast title="Alright" description="Account updated successfully" />, {
			autoClose: 5_000,
		})

	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const handleSubmit = async (form: AccountFormData) => {
		setError(null)
		const updateRequest: Partial<AccountFormData> = {
			...updatedDiff(settings.currentSettings, {
				...form,
				phone: parseFormattedPhoneNumber(form.phone),
			}),
			firstName: form.firstName,
			lastName: form.lastName,
		}
		if (!formHasProps(updateRequest)) return
		const submitError = await settings.update(updateRequest)
		setError(submitError)
		if (submitError || settings.isVerifying) return
		successUpdateMessage()
	}

	const handleVerify = async (form: CodeFormData) => {
		setError(null)
		const verificationError = await settings.verifyUpdate(form.code)
		setError(verificationError)
		if (verificationError) return

		const logoutError = await auth.logout()
		if (logoutError) return

		successUpdateMessage()
		logoutNotification()
		navigate("/login")
	}

	const handleResend: React.MouseEventHandler = (e: React.MouseEvent) => {
		e.preventDefault()
		settings.resendCode()
	}

	const errorNode = error && (
		<Box mb={4} width="100%">
			<Alert severity="error">
				<AlertTitle>Oops!</AlertTitle>
				{signUpErrorCodeToMessage(error.code)}
			</Alert>
		</Box>
	)

	return (
		<SubPageLayout label="Account Settings">
			<CardBox
				display="flex"
				flexDirection="column"
				bg={isMobile ? "secondary" : "primary"}
				elevation={isMobile ? 0 : 1}
				p={isMobile ? 0 : 6}
				minHeight={300}
			>
				{settings.isVerifying ? (
					<Stack flexGrow={1}>
						<Typography variant="h2" textAlign="center" mb={2.5}>
							Confirm changes
						</Typography>
						<Typography variant="body2" textAlign="center" color="grey.800" mb={3.5}>
							Verification code was sent to <b>{settings.currentSettings.email}</b>
						</Typography>

						{errorNode}

						<VerificationCodeForm onSubmit={handleVerify} loading={settings.isLoading} />

						<Stack mt={4}>
							<Button variant="outlined" size="small" onClick={settings.cancelVerification}>
								Cancel
							</Button>
						</Stack>

						<Typography variant="body2" align="center" mt={4}>
							Didn&lsquo;t get a code?{" "}
							<NavLink to="" onClick={handleResend}>
								Resend code
							</NavLink>
						</Typography>
					</Stack>
				) : (
					<Stack height="100%">
						{errorNode}

						<AccountForm
							onSubmit={handleSubmit}
							initialValues={settings.currentSettings}
							loading={settings.isLoading || !settings.isUpdated}
						/>
					</Stack>
				)}
			</CardBox>
		</SubPageLayout>
	)
})

export default AccountSettingsPage
