import React from "react"
import { r } from "@theme/utils"
import styled from "@mui/material/styles/styled"
import VerificationInput, { VerificationInputProps } from "react-verification-input"
import { darken } from "@mui/system"

interface VerificationCodeInputProps extends VerificationInputProps {
	withError?: boolean
}

const Container = styled("div", { target: "MUI-CodeInput-root" })<VerificationCodeInputProps>(
	({ theme, withError }) => ({
		"&>.vi__wrapper": {
			width: "100%",
		},
		".MUI-container": {
			width: "100%",
		},
		".MUI-character": {
			fontSize: r(20),
			lineHeight: "160%",
			fontWeight: 400,
			backgroundColor: theme.palette.common.white,
			borderColor: withError ? theme.palette.error.light : theme.palette.grey[200],
			borderRadius: theme.shape.borderRadius,
			paddingBlock: r(9),
		},
		".MUI-characterInactive": {},
		".MUI-characterSelected": {
			outline: "none",
			borderColor: withError ? darken(theme.palette.error.main, 0.5) : theme.palette.grey[900],
			color: theme.palette.grey[900],
			boxShadow: "0px 5px 44px rgba(14, 15, 25, 0.06)",
		},
	}),
)

const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({ withError, placeholder = "", ...restProps }) => (
	<Container withError={withError}>
		<VerificationInput
			{...restProps}
			placeholder={placeholder}
			classNames={{
				container: "MUI-container",
				character: "MUI-character",
				characterInactive: "MUI-characterInactive",
				characterSelected: "MUI-characterSelected",
			}}
		/>
	</Container>
)

export default VerificationCodeInput
