import React from "react"
import Grid from "@components/fondation/Grid/Grid"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import Image from "@components/elements/Image/Image"
import ModalHeader from "@components/elements/HeaderBar/ModalHeader"
import NavLink from "@components/elements/NavLink/NavLink"
import ModalBox from "@components/elements/Modal/ModalBox"
import { getImageUrl } from "@components/elements/Image/collection"
import SessionStore from "@store/auth/session.store"
import EmailForm, { FormData } from "@components/modules/forms/auth/EmailForm"

const IMAGE_SRC = getImageUrl("renewables.png")
const TITLE = "Find out what incentives and savings are available in your area."

const MESSAGE = `Arbor helps anyone with a power bill take advantage of the best energy incentives, savings, and renewables in their area. It’s 100% free.`
const NEED_EMAIL_MESSAGE =
	"Sign up with your email today and we will notify you once the best options are gathered in your area very soon."
const HAVE_EMAIL_MESSAGE =
	"We are currently compiling the best ways to save in your area. We will be in touch very soon!"

const UnsupportedPage: React.FC = observer(() => {
	const { session, isLoading, setCallbackEmail } = useInstance(SessionStore)
	const navigate = useNavigate()

	const withEmailForm = session?.email == null && session?.preselect?.email == null

	const handleSubmit = async ({ email }: FormData) => {
		const emailError = await setCallbackEmail(email)
		if (!emailError) navigate("confirmation")
	}

	const handleBack = () => {
		navigate("/area")
	}

	return (
		<ModalBox flexBasis="100%">
			<ModalHeader BackButtonProps={{ onClick: handleBack }} hideEndButton />

			<ModalBox flexBasis="100%" p={4}>
				<Grid container spacing={6} justifyContent="center" marginY="auto">
					<Grid item xs={12} textAlign="center">
						<Image src={IMAGE_SRC} height={120} />
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h2" textAlign="center" mb={3}>
							{TITLE} <br />
						</Typography>
						<Typography variant="body1" color="grey.800" textAlign="center">
							{MESSAGE}
							<br />
							<br />
							{withEmailForm ? NEED_EMAIL_MESSAGE : HAVE_EMAIL_MESSAGE}
						</Typography>
					</Grid>

					{withEmailForm && (
						<Grid item xs={12}>
							<EmailForm submitLabel="Sign me up!" loading={isLoading} onSubmit={handleSubmit} />
						</Grid>
					)}

					<Grid item>
						<Typography variant="body2" color="black">
							<NavLink to="/area">Start Over</NavLink>
						</Typography>
					</Grid>
				</Grid>
			</ModalBox>
		</ModalBox>
	)
})

export default UnsupportedPage
