import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import Box from "@components/fondation/Box/Box"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import UtilityAccountStore from "@store/auth/utility-account.store"
import NewRatePlan from "./NewRatePlan"
import OldRatePlan from "./OldRatePlan"
import UnavailableRatePlan from "./UnavailableRatePlan"
import ProductFAQList from "../Product/ProductFAQList"
import BenefitsList from "../Product/BenefitsList"
import MobileRateContractContainer from "./MobileRateContractContainer"
import DesktopRateContractContainer from "./DesktopRateContractContainer"

const sectionMargin = { md: 10, xs: 7.5 }

export const UserRate = observer(() => {
	const { service } = useInstance(UtilityAccountStore)

	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))

	const isLoadingFailed = !service?.contract || service.status == null

	const oldPlanNode = isLoadingFailed ? <UnavailableRatePlan /> : <OldRatePlan />
	const newPlanNode = isLoadingFailed ? <UnavailableRatePlan /> : <NewRatePlan />

	return (
		<Box>
			<Typography variant="h2" mb={4}>
				My Rate
			</Typography>

			{isMobile ? (
				<MobileRateContractContainer>
					{newPlanNode}
					{oldPlanNode}
				</MobileRateContractContainer>
			) : (
				<DesktopRateContractContainer firstSocket={oldPlanNode} secondSocket={newPlanNode} />
			)}

			<Box marginY={sectionMargin}>
				<Typography variant="h3" mb={4}>
					Arbor Benefits
				</Typography>
				<BenefitsList />
			</Box>

			<Box marginY={sectionMargin}>
				<Typography variant="h3" mb={4}>
					FAQs
				</Typography>
				<ProductFAQList />
			</Box>
		</Box>
	)
})

export default UserRate
