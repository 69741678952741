import { MagicLinkQueryParams, SignInMethod } from "@model/types/auth"
import { IUrlQueryParams } from "@components/prototypes/hooks/useQueryParams"
import * as yup from "yup"
import { emailValidator } from "@components/modules/forms/common/validations"

export const availableSignInMethods: SignInMethod[] = ["register", "login"]

export const magicLinkValidator = yup.object().shape({
	email: emailValidator.clone().required(),

	signInMethod: yup.string().required().oneOf(availableSignInMethods),

	apiKey: yup.string().required(),
})

export const validateMagicLinkParams = async (
	params: Partial<IUrlQueryParams>,
): Promise<MagicLinkQueryParams | null> => {
	const isValid = await magicLinkValidator.isValid(params)
	if (isValid) return params as MagicLinkQueryParams
	return null
}
