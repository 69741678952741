import { Rate, UtilitySupplierRate } from "@model/types/utilities"
import { PlanOption } from "./types"

export const findRate = (utilityRate: Rate, plan: PlanOption): UtilitySupplierRate | undefined => {
	const selectedSupplierRateID =
		plan === "lowest-price"
			? utilityRate.rateSummary?.supplierRateMinRateId
			: utilityRate.rateSummary?.greenRateMinRateId

	return utilityRate.supplierRates?.find((item) => item.id === selectedSupplierRateID)
}
