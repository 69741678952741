/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useState, FocusEvent, SyntheticEvent, useEffect } from "react"
import parse from "autosuggest-highlight/parse"
import Autocomplete from "@mui/material/Autocomplete"
import useGooglePlaces from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import TextField from "@components/elements/TextField/TextField"
import ListItemText from "@components/elements/List/ListItemText"
import ListItem from "@components/elements/List/ListItem"
import { ListProps } from "@mui/material/List"
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete"
import identity from "lodash/identity"

type AutocompletePrediction = google.maps.places.AutocompletePrediction
type PlaceResult = google.maps.places.PlaceResult
type PredictionSubstring = google.maps.places.PredictionSubstring

interface IAutocompleteProps {
	name: string
	defaultValue: string
	error: boolean
	helperText: string
	inputLabel?: string
	onChange?: (place: PlaceResult | null) => void
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const AddressAutocomplete: React.FC<IAutocompleteProps> = (props) => {
	const { defaultValue, inputLabel, onBlur, onChange } = props

	const [popupOpen, setPopupOpen] = useState(false)
	const [inputValue, setInputValue] = useState("")

	const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGooglePlaces({
		apiKey: GOOGLE_API_KEY,
		language: "en",
		debounce: 300,
		options: {
			input: inputValue,
			types: ["address"],
			componentRestrictions: { country: ["US"] },
		},
	})

	const onOptionSelect = (event: SyntheticEvent, value: AutocompletePrediction | string | null) => {
		if (!value && typeof value !== "string") {
			return
		}

		const { place_id: placeId } = value as AutocompletePrediction

		const params = {
			placeId,
			fields: ["place_id", "address_components"],
			language: "en",
		}

		placesService?.getDetails(params, (placeDetails) => {
			if (!placeDetails) {
				return
			}

			onChange?.(placeDetails)
		})
	}

	const onInputChange = (event: SyntheticEvent, value: string) => {
		setInputValue(value ?? "")
		getPlacePredictions({ input: value })
		if (!value) onChange?.(null)
	}

	const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, optionValue: AutocompletePrediction) => {
		const { structured_formatting, place_id } = optionValue
		const { main_text, secondary_text, main_text_matched_substrings } = structured_formatting

		// Parsing matched text to be able to highlight
		const parts = parse(
			main_text,
			main_text_matched_substrings.map((match: PredictionSubstring) => [match.offset, match.offset + match.length]),
		)

		// Creating highlighted text html
		const mainText = parts.map(({ highlight, text }, index) => (
			<span key={index} style={{ fontWeight: highlight ? 700 : 400 }}>
				{text}
			</span>
		))

		// Rendering actual autocomplete dropdown list
		return (
			<ListItem {...optionProps} key={place_id}>
				<ListItemText primary={mainText} secondary={secondary_text} />
			</ListItem>
		)
	}

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		if (defaultValue != null && !!inputValue) setInputValue(defaultValue)
		onBlur?.(e)
	}

	useEffect(() => {
		setInputValue((prev) => {
			if (defaultValue == null) return prev
			if (prev !== defaultValue) getPlacePredictions({ input: defaultValue })
			return defaultValue
		})
	}, [defaultValue])

	// Open results list popup only when it's not empty
	const isListVisible = popupOpen && !!placePredictions.length
	const listProps: ListProps = { style: { maxHeight: "150px" } }

	// disable filter to show all results coming from google places
	const filterOptions = identity

	const renderInput = (params: AutocompleteRenderInputParams) => <TextField {...params} fullWidth label={inputLabel} />

	const getOptionLabel = (option: AutocompletePrediction | string) =>
		typeof option === "string" ? option : option.structured_formatting.main_text

	return (
		<Autocomplete
			fullWidth
			freeSolo
			disablePortal
			selectOnFocus
			autoComplete
			open={isListVisible}
			onOpen={() => setPopupOpen(true)}
			onClose={() => setPopupOpen(false)}
			id="address-autocomplete"
			loading={isPlacePredictionsLoading}
			options={placePredictions}
			inputValue={inputValue}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel}
			renderOption={renderOption}
			renderInput={renderInput}
			onBlur={handleBlur}
			blurOnSelect
			onInputChange={onInputChange}
			onChange={onOptionSelect}
			ListboxProps={listProps}
		/>
	)
}

export default AddressAutocomplete
