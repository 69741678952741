import React from "react"

import Grid from "@mui/material/Grid"
import Label, { LabelProps } from "./Label"

export interface MetricsGridProps {
	cellData: LabelProps[]
}

const MetricsGrid: React.FC<MetricsGridProps> = ({ cellData = [] }) => (
	<Grid container spacing={4}>
		{cellData.map((props) => (
			<Grid item xs={6} md={4} key={props.label}>
				<Label {...props} />
			</Grid>
		))}
	</Grid>
)

export default MetricsGrid
