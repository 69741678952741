import { computed, observable } from "mobx"
import { inject } from "react-ioc"
import { switchBenefitsList } from "@components/elements/Card/RateDetailsCard/mocks"
import type { UserService, RateQuote, ServiceStatus } from "@model/types/user"
import type { Rate } from "@model/types/utilities"
import ProductStore from "./product.store"

class RateOffer {
	productStore = inject(this, ProductStore)

	@computed get service(): UserService | undefined {
		return this.productStore.service
	}

	@computed get rate(): Rate | undefined {
		return this.productStore.utilityRate
	}

	@computed get quote(): RateQuote | undefined {
		if (this.productStore.activePlan === "lowest-price") {
			return this.service?.quotes?.lowestFixed
		}
		return this.service?.quotes?.renewableFixed
	}

	@observable benefits = switchBenefitsList

	@computed get serviceStatus(): ServiceStatus | undefined {
		return this.productStore.service?.status
	}

	@computed get avgRate(): number {
		if (this.productStore.activePlan === "lowest-price") {
			return this.rate?.rateSummary?.supplierRateAvg ?? 0
		}
		return this.rate?.rateSummary?.greenRateAvg ?? 0
	}

	@computed get userRate(): number {
		return this.service?.userRate ?? 0
	}

	@computed get savingsToAvg(): number {
		if (this.productStore.activePlan === "best-renewable") return 0
		const { avgRate, recommendedRate, guaranteedTerm } = this
		const avgTotalUsage = this.service?.avgTotalUsage || this.service?.userUsage || 1000
		const rateDelta = avgRate - recommendedRate

		return rateDelta * avgTotalUsage * guaranteedTerm
	}

	@computed get savingsToCurrent(): number {
		if (this.productStore.activePlan === "best-renewable") return 0
		const { userRate, recommendedRate, guaranteedTerm } = this
		const avgTotalUsage = this.service?.avgTotalUsage || this.service?.userUsage || 1000
		const rateDelta = userRate - recommendedRate

		return rateDelta * avgTotalUsage * guaranteedTerm
	}

	@computed get recommendedRate(): number {
		return this.quote?.rate ?? 0
	}

	@computed get guaranteedTerm(): number {
		return this.quote?.term ?? 0
	}

	@computed get savingsToAvgPercent(): number {
		if (!this.avgRate) return 0
		return 1 - this.recommendedRate / this.avgRate
	}

	@computed get savingsToCurrentPercent(): number {
		if (!this.userRate) return 0
		return 1 - this.recommendedRate / this.userRate
	}

	@computed get renewablePercent(): number {
		return this.quote?.green ? 1 : 0.2
	}

	@computed get isSwitchRecommended(): boolean {
		return this.quote?.recommendSwitch ?? false
	}

	@computed get isLockRecommended(): boolean {
		return this.quote?.recommendLock ?? false
	}

	@computed get isSupplierAvailable(): boolean {
		return this.quote != null
	}
}

export default RateOffer
