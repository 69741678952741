import React from "react"
import UtilityAccountMeta, {
	UtilityAccountMetaProps,
} from "@components/prototypes/utilities/UtilityMeta/UtilityAccountMeta"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"
import UtilityStore from "@store/auth/utilities.store"
import LinkUtilityStore from "@store/auth/link-utility.store"
import { getStatusMessage } from "../utilities/UtilityMeta/utils"

export const UtilityAccountStatus: React.FC<UtilityAccountMetaProps> = observer(({ ...rest }) => {
	const [linkStore, utilityStore] = useInstances(LinkUtilityStore, UtilityStore)
	const { linkingStatus, service } = linkStore

	const logoSrc = utilityStore.utilityLogo
	const utilityName = utilityStore.utilityShortName
	const message = getStatusMessage(linkingStatus, service?.accountNumber)

	return <UtilityAccountMeta logoSrc={logoSrc} name={utilityName} status={linkingStatus} message={message} {...rest} />
})

export default UtilityAccountStatus
