import React from "react"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"

import BaseModalLayout from "@components/modules/layouts/ModalLayout/BaseModalLayout"
import Modal from "@components/elements/Modal/Modal"
import Stack from "@components/fondation/Stack/Stack"
import Typography from "@components/fondation/Typography/Typography"
import Button from "@components/elements/Button/Button"
import ModalBox from "@components/elements/Modal/ModalBox"
import AuthStore from "@store/auth/auth.store"

const TITLE = "Log out"
const MESSAGE = "Are you sure you want to log out of your Arbor account?"

interface LogoutModalProps {
	open?: boolean
	onCancel?: () => void
}

const LogoutModal: React.FC<LogoutModalProps> = observer(({ open = false, onCancel }) => {
	const authStore = useInstance(AuthStore)

	const handleLogout = async () => {
		const error = await authStore.logout()
		if (!error) {
			window.location.replace("//joinarbor.com/")
		}
	}

	return (
		<Modal open={open} onClose={onCancel}>
			<BaseModalLayout fitContent>
				<ModalBox flexBasis="100%" p={5}>
					<ModalBox flexBasis="100%">
						<Typography variant="h3" mb={2.5}>
							{TITLE}
						</Typography>
						<Typography variant="body1" color="grey.800" mb={10}>
							{MESSAGE}
						</Typography>
					</ModalBox>

					<Stack direction="column" gap={3}>
						<Button onClick={handleLogout} fullWidth variant="contained" color="primary">
							Yes, Log me out
						</Button>
						<Button onClick={onCancel} fullWidth variant="outlined" color="primary">
							No, I want to stay
						</Button>
					</Stack>
				</ModalBox>
			</BaseModalLayout>
		</Modal>
	)
})

export default LogoutModal
