import React from "react"
import styled from "@mui/material/styles/styled"
import { r } from "@theme/utils"
import Typography from "@components/fondation/Typography/Typography"

export interface FooterProps {
	children?: React.ReactNode
}

const Container = styled(Typography, { target: "Footer-root" })({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: r(5, 16),
	minHeight: r(36),
	background: "rgba(243, 158, 57, 0.5)",
})

const Footer: React.FC<FooterProps> = React.memo((props) => {
	const { children } = props

	return <Container variant="caption">{children}</Container>
})

export default Footer
