import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/fondation/Typography/Typography"
import { EditableAddress } from "@components/prototypes/dashboard/EditableAddress"
import { AddressCard } from "@components/prototypes/dashboard/AddressCard"
import CardBox from "@components/elements/Card/CardBox"
import useToggle from "@components/prototypes/hooks/useToggle"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"

const FORM_LABEL = "Service Address"

interface CheckoutAddressProps {
	cancelable?: boolean
}

const CheckoutAddress: React.FC<CheckoutAddressProps> = observer(({ cancelable }) => {
	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))
	const { isActive, handleToggle: toggleEdit, makeActive } = useToggle(false)

	useEffect(() => {
		if (!cancelable !== isActive) makeActive(true)
	}, [cancelable])

	if (isActive)
		return (
			<CardBox p={isMobile ? 4 : [6, 4.5]}>
				<Typography variant="h4" mb={1}>
					{FORM_LABEL}
				</Typography>

				<EditableAddress onSubmit={toggleEdit} onCancel={toggleEdit} cancelable={cancelable} />
			</CardBox>
		)
	return <AddressCard label={FORM_LABEL} onEdit={toggleEdit} p={isMobile ? 4 : [6, 4.5]} />
})

export default CheckoutAddress
