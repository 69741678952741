import React from "react"
import { PairRowData } from "@components/elements/Table/SimpleTable"

export const HEADER_LABEL = "Best Savings Option"
export const HEADER_TITLE = "Lowest Fixed Rate"
export const HEADER_CONTENT = (
	<>
		Our best rate is <b>Y% lower</b> than the average rate of Z. It is guaranteed for X months and could{" "}
		<b> save you up to $A</b> compared to the average.
	</>
)

export const switchBenefitsList = [
	"No commitment, cancel anytime",
	"Same utility bill, better rate",
	"Same reliable service, no interruptions",
]

export const linkBenefitsList = [
	"Personalized savings recommendations.",
	"Price monitoring and alerts.",
	"Billing and usage insights.",
]

export const rateTableData: PairRowData[] = [
	{ label: "Your Current Rate", value: <s>$0.1300 - 0.2000/kWh </s> },
	{
		label: "Recommended Rate",
		value: (
			<>
				$0.1100<sub>/kWh</sub>
			</>
		),
	},
	{
		label: "Guaranteed",
		value: (
			<>
				36 <sub>months</sub>
			</>
		),
	},
	{ label: "Potential Savings", value: <>$320.00</> },
]
