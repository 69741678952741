import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useInstances } from "react-ioc"

import Typography from "@components/fondation/Typography/Typography"
import RateChart from "@components/prototypes/dashboard/RateChart"
import Grid from "@components/fondation/Grid/Grid"
import RateOfferCard from "@components/prototypes/dashboard/RateOfferCard"
import useMediaQuery from "@components/prototypes/hooks/useMediaQuery"
import Box from "@components/fondation/Box/Box"
import RateOffer from "@store/product/RateOffer"
import Stack from "@components/fondation/Stack/Stack"
import ContentHeader from "@components/elements/Card/RatePlanCard/Header/ContentHeader"
import { SUPPORT_ROUTE } from "@model/constants/utilities/app"
import { getImageUrl } from "@components/elements/Image/collection"
import { navLinkRenderer } from "@components/elements/Markdown/utils"
import Markdown from "@components/elements/Markdown/Markdown"
import ClimateAction from "./ClimateAction"
import UnavailableRatePlan from "../UserRate/UnavailableRatePlan"

const savingsHeaderContent = {
	title: "Rate Comparison",
	message:
		"Here is our best available rate compared with your current rate and other rates in your area. Future rates are forecast using historical data.",
}

const renewableHeaderContent = {
	title: "Your Clean Energy Impact",
	message:
		"Transitioning to cleaner sources of energy like wind and solar is critical in meeting the challenges posed by climate change. Arbor makes switching to renewable energy simple and affordable.",
}

export const RateComparison = observer(() => {
	const navigate = useNavigate()
	const [offer] = useInstances(RateOffer)

	const isMobile = useMediaQuery((t) => t.breakpoints.down("lg"))
	const isRenewable = offer.productStore.activePlan === "best-renewable"
	const isReview = offer.serviceStatus === "review"

	const headerContent = isRenewable ? renewableHeaderContent : savingsHeaderContent

	const handleSwitch = isReview
		? undefined
		: () => {
				offer.productStore.initiateRateSwitch()
				navigate("/switch")
		  }

	const titleNode = (
		<Box>
			<Typography variant="h3" mb={3}>
				{headerContent.title}
			</Typography>
			<Typography variant="body1" color="grey.800">
				{headerContent.message}
			</Typography>
		</Box>
	)

	const detailsNode = isRenewable ? (
		<ClimateAction />
	) : offer.rate?.supplierRates != null ? (
		<RateChart />
	) : (
		<UnavailableRatePlan
			title="No suppliers available"
			message="We don't currently have any rates that match your preferences, please check back in a few days."
			image={getImageUrl("flower.png")}
		/>
	)

	const ratePlanNode = offer.isSupplierAvailable ? (
		<RateOfferCard onSwitch={handleSwitch} />
	) : (
		<UnavailableRatePlan
			title="No suppliers available"
			message="We don't currently have any rates that match your preferences, please check back in a few days."
			image={getImageUrl("flower.png")}
		/>
	)

	const newRatePlan = isReview && (
		<ContentHeader status="SELECTED">
			<Markdown renderLink={navLinkRenderer}>
				{offer.quote?.approved
					? `We will notify you once we have processed your reservation, usually within 24 hours. If you have any questions, just reach out to [support](${SUPPORT_ROUTE}).`
					: `Good news! We found you a better rate. We will notify you once we process your reservation, usually within 24 hours. If you have any questions or want to opt out of this rate, please contact [support](${SUPPORT_ROUTE}).`}
			</Markdown>
		</ContentHeader>
	)

	return isMobile ? (
		<Stack spacing={7.5}>
			<Stack spacing={4}>
				{newRatePlan}
				{ratePlanNode}
			</Stack>
			<Box>
				<Stack spacing={4}>
					{titleNode}
					{detailsNode}
				</Stack>
			</Box>
		</Stack>
	) : (
		<Stack spacing={6}>
			{titleNode}
			{newRatePlan}
			<Box>
				<Grid container spacing={6}>
					<Grid item lg={7}>
						{detailsNode}
					</Grid>
					<Grid item lg={5}>
						{ratePlanNode}
					</Grid>
				</Grid>
			</Box>
		</Stack>
	)
})

export default RateComparison
