import React from "react"
import Avatar, { AvatarProps } from "@mui/material/Avatar"
import styled from "@mui/material/styles/styled"
import { alpha } from "@mui/system/colorManipulator"
import { useInstance } from "react-ioc"
import { observer } from "mobx-react-lite"
import UserStore from "@store/auth/user.store"
import initials from "initials"

interface UserAvatarProps extends AvatarProps {
	active?: boolean
}

const StyledAvatar = styled(Avatar)<UserAvatarProps>(({ theme, active, onClick }) => ({
	border: "2px solid white",
	backgroundColor: alpha(theme.palette.green.main, 0.2),
	color: theme.palette.green.main,
	cursor: onClick ? "pointer" : "default",
	boxShadow: active ? `0 0 0 1px ${theme.palette.green.main}` : "none",
}))

const UserAvatar: React.FC<UserAvatarProps> = observer((props) => {
	const userStore = useInstance(UserStore)

	const fullName = userStore.user?.name ?? "unknown"

	return <StyledAvatar {...props}>{props.children ?? initials(fullName)}</StyledAvatar>
})

export default UserAvatar
