/* eslint-disable camelcase */
import { Session } from "@model/types/session"
import { parseQueryParams } from "@root/lib/browserUtils"
import { parsePreselect, PreselectQueryParams } from "@store/app/utils"
import { onSnapshot, serverTimestamp } from "firebase/firestore"
import isBoolean from "lodash/isBoolean"
import AbstractService from "../AbstractService"
import { formatDocument } from "../utils"
import {
	CreateSessionRequest,
	SessionUpdateActions,
	UpdateSessionActionData,
	UpdateSessionLogRequest,
} from "./request.types"

const IS_DEV_MODE = Boolean(process.env.REACT_APP_DEBUG_MODE)

interface SessionQueryParams {
	affiliate: string
	trackId: string
	hash_id: string
	id: string
	campaign: string
	test: boolean | "true" | "false"
}

const parseBoolean = (value?: boolean | "true" | "false") => (isBoolean(value) ? value : value === "true")

class SessionService extends AbstractService {
	constructor() {
		super("sessions")
	}

	createSession = async () => {
		const params = parseQueryParams<PreselectQueryParams & SessionQueryParams>(window.location.search)
		const preselect = parsePreselect(params)
		const { location } = window

		const request: CreateSessionRequest = {
			...formatDocument({
				status: "unregistered",
				href: location.href,
				test: !location.hostname.endsWith("joinarbor.com") || parseBoolean(params.test),
				affiliate: params.affiliate,
				trackId: params.trackId ?? params.hash_id ?? params.id,
				campaign: params.campaign,
				preselect,
				...(location.hostname.endsWith("beta.joinarbor.com") ? { beta: true } : {}),
			}),
			createdAt: serverTimestamp(),
		}
		if (IS_DEV_MODE) console.log("PRESELECT [CREATE SESSION]", request)
		return this.addToCollection(request)
	}

	getSession = async (sessionId: string): Promise<Session> => {
		const snapshot = await this.getById(sessionId)
		if (!snapshot.exists()) throw Error("Session no longer exists")
		return { ...formatDocument(snapshot.data()), id: sessionId }
	}

	updateLog = <A extends SessionUpdateActions, T extends UpdateSessionActionData<A>>(
		sessionId: string,
		action: A,
		actionData: T,
	) => {
		const request: UpdateSessionLogRequest<A, T> = {
			...formatDocument(actionData),
			timestamp: serverTimestamp(),
			action,
		}
		if (IS_DEV_MODE) console.log(`ACTION [SESSION LOG]: ${request.action}`, request)
		return this.addToSubCollection(sessionId, "log", request)
	}

	subscribe = (sessionId: string, onNext: (user: Session) => void, onError?: (err: unknown) => void) =>
		onSnapshot<Session>(this.doc(sessionId), {
			next: async (snapshot) => {
				if (snapshot.exists()) onNext({ ...formatDocument(snapshot.data()), id: sessionId })
			},
			error: onError,
		})
}

export default SessionService
