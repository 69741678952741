import React from "react"
import { observer } from "mobx-react-lite"
import { navLinkRenderer } from "@components/elements/Markdown/utils"
import Markdown from "@components/elements/Markdown/Markdown"
import Typography from "@components/fondation/Typography/Typography"
import Box from "@components/fondation/Box/Box"

const TITLE = "About You"
const WELCOME_MESSAGE = "Let's start with some basic information."

interface IAboutYouHeaderProps {
	title?: string
	message?: string
}

const AboutYouHeader: React.FC<IAboutYouHeaderProps> = observer(({ title, message }) => (
	<Box alignItems="center" mb={2}>
		<Typography variant="h3" mb={2}>
			{title}
		</Typography>
		<Typography
			variant="body1"
			sx={(t) => ({
				color: t.palette.grey[800],
			})}
		>
			<Markdown renderLink={navLinkRenderer}>{message}</Markdown>
		</Typography>
	</Box>
))

AboutYouHeader.defaultProps = {
	title: TITLE,
	message: WELCOME_MESSAGE,
}

export default AboutYouHeader
