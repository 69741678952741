import React, { useEffect, useState } from "react"
import styled from "@mui/material/styles/styled"
import Grid from "@components/fondation/Grid/Grid"
import NavLink from "@components/elements/NavLink/NavLink"
import Collapse from "@components/elements/Collapse/Collapse"
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import CardBox, { CardBoxProps } from "../CardBox"
import TicketStub, { HeaderProps } from "./TicketStub"

export interface CollapsibleCardProps extends CardBoxProps {
	collapsible?: boolean
	headerProps?: Partial<HeaderProps>
	footer?: React.ReactNode
	children?: React.ReactNode
}

const Container = styled(CardBox)({
	display: "flex",
	flexDirection: "column",
	overflow: "hidden",
	padding: 0,
	border: "none",
})

const RateDetailsCard = React.memo((props: CollapsibleCardProps) => {
	const { children, headerProps = {}, collapsible = false, footer, ...rest } = props

	const [open, setOpen] = useState(!collapsible)

	useEffect(() => {
		setOpen(!collapsible)
	}, [collapsible])

	const arrayChildren = React.Children.toArray(children)
	return (
		<Container {...rest}>
			<TicketStub {...headerProps}>
				{headerProps?.children}
				{collapsible ? (
					<>
						<br />
						<br />
						<NavLink
							to=""
							color="inherit"
							sx={{ display: "flex", color: "inherit", alignItems: "center", fontSize: "1rem" }}
							onClick={() => setOpen(!open)}
						>
							{open ? (
								<>
									HIDE DETAILS <KeyboardArrowUpOutlinedIcon />
								</>
							) : (
								<>
									SHOW DETAILS <KeyboardArrowDownOutlinedIcon />
								</>
							)}
						</NavLink>
					</>
				) : null}
			</TicketStub>

			<Collapse in={open}>
				{!!arrayChildren.length && (
					<Grid container spacing={4} p={4}>
						{arrayChildren.map((child) => (
							<Grid item xs={12}>
								{child}
							</Grid>
						))}

						{footer != null && (
							<Grid item xs={12}>
								{footer}
							</Grid>
						)}
					</Grid>
				)}
			</Collapse>
		</Container>
	)
})

export default RateDetailsCard
